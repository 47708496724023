import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {ajaxWrapper} from 'functions';
import {Button, TextInput, Json_Input, CSSInput, Select, Alert} from 'library';


//Example
// var gender =  {'value':'','name':'gender','label':'Gender','options':[{'value':'Pick One','text':'Pick One'}, {'value':'Male', 'text':'Male'}, {'value':'Female','text':'Female'},{'value':'Other','text':"I don't identify as either"}]}

var BOOLEANS = [
    {'text':'True', value:true},
    {'text':'False', value:false},
];

class UserSelectMulti extends Component {
    static component_name = 'Select'
    constructor(props) {
        super(props);
        this.state = {
            options:[],
            current_string: '',
        }

        this.config = {
            form_components: [
                <TextInput label={'name'} name={'name'} default={''} />,
                <TextInput label={'label'} name={'label'} />,
                <CSSInput label={'label css'} name={'label_style'}/>,
                <Select label={'required'} name={'required'} boolean={true} defaultoption={false} />,
                <Select label={'Select'} name={'select'} boolean={true} defaultoption={true} />,
                <TextInput label={'class'} name={'className'} />,
                <CSSInput label={'css'} name={'style'}/>,
            ],
        }

        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.removeSelection = this.removeSelection.bind(this);
        this.submit_user = this.submit_user.bind(this);
        this.submit_user_callback = this.submit_user_callback.bind(this);
    }

    componentDidMount() {
        this.setState({'data': this.props.data});
    }
    componentDidUpdate (prevProps, prevState, snapshot){}

    handleChange(event) {
        var text = event.target.value;
        this.setState({current_string: text});
    }

    handleKeyDown(event){
        if (event.key == 'Enter') {
            this.submit_user(event);
        }
    }

    submit_user(e) {
        var selection = e.target.value;
        ajaxWrapper("POST", '/users/userCheck/', {'email': selection}, this.submit_user_callback.bind(this));
    }

    submit_user_callback(response) {
        if ('error' in response){
            this.setState({error: response['error']});
        }
        else {
            var new_user = response[0]['user'];
            var newState = {};

            var value = this.props.value;
            if (value.indexOf(new_user['id']) == -1) {
                value.push(new_user['id']);
                this.state.data[new_user['id']] = new_user['email'];
            }
            newState[this.props.name] = value;

            console.log("New State",newState);
            this.props.setFormState(newState);
            this.setState({error: '', current_string: '', users: this.state.users});
        }
    }

    removeSelection(e) {
        var value = this.props.value;
        var selection = e.currentTarget.getAttribute('num');
        var index = value.indexOf(selection);
        value.splice(index,1);

        var newState = {}
        newState[this.props.name] = value;
        this.props.setFormState(newState);
    }

    render() {
        var layout = '';
        if (this.props.layout) {
            layout = this.props.layout;
        }

        var names = this.state.names;
        var value = this.props.value;
        if (this.props.value) {
            if (value.length == 0) {
                value = this.props.defaultoption;
            }
        }

        var multipleSelections = [];
        for (var index in value) {
            multipleSelections.push(<Button key={this.props.name + '-' + index} num={value[index]}
                onClick={this.removeSelection} type={'danger'} text={this.state.data[value[index]]} />);
        }

        var label = null;
        if (this.props.label) {
            var label = <label style={this.props.label_style}>{this.props.label}</label>;
        }

        var error = null;
        if (this.state.error){
            error = <Alert type='danger' text={this.state.error} />;
        }

        var input = <TextInput type="text" value={this.state.current_string} dont_submit={true}
            name={this.props.name} handleKeyDown={this.handleKeyDown} handleChange={this.handleChange} />;


        return (
            <div className={this.props.className} style={this.props.style} >
                {label}
                {error}
                <div style={{marginBottom:'15px'}}>{multipleSelections}</div>
                {input}
            </div>
        )
    }

}

export default UserSelectMulti;
