import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';

import {Wrapper, SignUpForm, TextInput, PasswordInput, Header, Div, Container, Span, Link, Image, Paragraph} from 'library';
import Navbar from 'projectLibrary/navbar.js';
import Footer from 'projectLibrary/footer.js';


class SignUpPage extends Component {

    render() {

        return (<div>
    		<Navbar />

    		<Container order={"9"} min_height={true} >
    			<Div style={{'marginTop': '20px', 'paddingTop': '40px', 'paddingLeft': '20px', 'paddingRight': '20px', 'paddingBottom': '300px'}} className={"row"} >
    				<Div className={"col-md-3 col-sm-12"}  />
    				<Div className={"col-md-6 col-sm-12"} >
    					<SignUpForm redirectUrl={"/profile/"} >
    						<Header size={"3"} text={"Sign Up"}  />
    						<TextInput name={"email"} label={"Email"} required={true} form_child_update_key={null}  />
    						<PasswordInput name={"password"} required={true} confirm_password={"true"} form_child_update_key={null}  />
    					</SignUpForm>
    					<Div style={{'margin-top': '20px'}} >
    						<Span text={"Already a user?"} style={{'padding-right': '10px'}}  />
    						<Link href={"/login/"} text={"Log In"}  />
    					</Div>
    				</Div>
    				<Div className={"col-md-3 col-sm-12"}  />
    			</Div>
    		</Container>

    		<Footer />
        </div>);
    }
}
export default SignUpPage;
