import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';

import {Wrapper, Instance, Header, ListWithChildren, FormWithChildren, ImageInput, TextInput, Div, CopyToClipboard} from 'library';

class Viewfilegroup extends Component {

    render() {

        return (<div>
			<Instance order={0} style={{}} delete={""} parent={null} dataUrl={"/api/modelWebsite/FileGroup/{params.1}/"} required={""} objectName={"FileGroup"} >
				<Header size={3} text={resolveVariables({"text":"{props.name}"}, this)["text"]}  />
				<Div className={"col-sm-4"} >
					<Div style={{'margin': '10px', 'padding': '20px'}} className={"card"} >
						<FormWithChildren submitUrl={"/api/modelWebsite/StaticFile/"} objectName={"new_image_form"} submit_text={"Save"}
                            autoSetGlobalState={false} form_child_update_key={null} reset_state_on_submit={true} >
							<Header size={3} text={"Add New Image"}  />
							<TextInput name={"filegroup"} style={{'display': 'none'}} default={resolveVariables({"text":"{props.id}"}, this)["text"]}  />
							<ImageInput name={"url"} preview={true} multiple={false}  />
						</FormWithChildren>
					</Div>
				</Div>
			</Instance>

			<Div order={11} style={{}} delete={""} parent={null} required={""} className={"row"} >
				<ListWithChildren nowrapper={true} dataUrl={"/api/modelWebsite/StaticFile/?filegroup_id={params.1}"} objectName={"StaticFile"} >
					<Div className={"col-sm-4"} >
						<Div style={{'margin': '10px 0px', 'padding': '20px'}} className={"card"} >
							<FormWithChildren defaults={resolveVariables({"text":{'url': '{props.url}', 'filegroup': '{props.filegroup_id}'}}, this)["text"]} deleteUrl={resolveVariables({"text":"/api/modelWebsite/StaticFile/{props.id}/delete/"}, this)["text"]} submitUrl={resolveVariables({"text":"/api/modelWebsite/StaticFile/{props.id}/"}, this)["text"]} submit_text={"Save"} >
								<ImageInput name={"url"} preview={true} multiple={false}  />
								<TextInput name={"filegroup"} style={{'display': 'none'}}  />
							</FormWithChildren>
							<CopyToClipboard text={resolveVariables({"text":"{props.url}"}, this)["text"]}  />
						</Div>
					</Div>
				</ListWithChildren>
			</Div>
        </div>);
    }
}
export default Viewfilegroup;
