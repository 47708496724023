import React, { Component } from 'react';
import {Wrapper, Container, Header, Div, Paragraph, FormWithChildren, DateTimePicker, TextInput, Select, TextArea, UserSearchInput, SignatureInput, ImageInput, Accordion} from 'library';

function get_form_elements(){
    var elements = [
		<Accordion index={0} header={<div className={"question-card"} name={"0_0"} excel_reference={0} style={{fontSize: '24px'}}  >
				<Paragraph text={"QUALITY CONTROL INSPECTION"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"1_0,2_0,3_0,4_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"1_0"} label={"Select Position Below if QC Form is Completed by a Supervisor or Manager"} options={[{"text": "Supervisor", "value": "Supervisor"}, {"text": "Area Supervisor", "value": "Area Supervisor"}, {"text": "Dir/Mgr of Operations", "value": "Dir/Mgr of Operations"}, {"text": "Vice President", "value": "Vice President"}]} radio={true} excel_reference={1}  />
			<TextInput className={"question-card"} name={"2_0"} label={"Area:"} excel_reference={2} required={true}  />
			<TextInput className={"question-card"} name={"3_0"} label={"Completed by:"} excel_reference={3} required={true}  />
			<TextInput className={"question-card"} name={"4_0"} label={"Supervisor:"} excel_reference={4} required={true}  />
		</Accordion>,
		<Accordion index={5} header={<div className={"question-card"} name={"5_0"} excel_reference={5} style={{fontSize: '24px'}}  >
				<Paragraph text={"Hallways"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"6_0,7_0,8_0,9_0,10_0,11_0,12_0,13_0"} sub_total={"17_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"6_0"} label={"Catwalks"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={6}  />
			<Select className={"question-card"} name={"7_0"} label={"Ledges"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={7}  />
			<Select className={"question-card"} name={"8_0"} label={"Fire alarms, door jambs, fire extinguishers, ashtrays"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={8}  />
			<Select className={"question-card"} name={"9_0"} label={"Elevator doors and tracks"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={9}  />
			<Select className={"question-card"} name={"10_0"} label={"Trash chute door (exterior)"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={10}  />
			<Select className={"question-card"} name={"11_0"} label={"Storage room floors"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={11}  />
			<Select className={"question-card"} name={"12_0"} label={"Windowsills"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={12}  />
			<Select className={"question-card"} name={"13_0"} label={"Light bulbs"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={13}  />
			<TextInput className={"question-card"} name={"14_0"} label={"COMMENTS"} excel_reference={14} fail_comment={true}  />
			<div className={"question-card"} name={"15_0"} excel_reference={15}  >
				<Paragraph text={"Take a Photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"16_0"} excel_reference={16}  />
			<TextInput className={"question-card"} name={"17_0"} label={"Rating Total"} excel_reference={17} excel={'Q6+Q7+Q8+Q9+Q10+Q11+Q12+Q13'}  />
		</Accordion>,
		<Accordion index={18} header={<div className={"question-card"} name={"18_0"} excel_reference={18} style={{fontSize: '24px'}}  >
				<Paragraph text={"Lobbies, Recreation Areas, Entrances, and Clubhouse"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"19_0,20_0,21_0,22_0,23_0,24_0,25_0,26_0,27_0,28_0,29_0,30_0,31_0"} sub_total={"35_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"19_0"} label={"Trash receptacles"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={19}  />
			<Select className={"question-card"} name={"20_0"} label={"Trash containers exterior"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={20}  />
			<Select className={"question-card"} name={"21_0"} label={"Carpets"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={21}  />
			<Select className={"question-card"} name={"22_0"} label={"Tile flooring"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={22}  />
			<Select className={"question-card"} name={"23_0"} label={"Door glass"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={23}  />
			<Select className={"question-card"} name={"24_0"} label={"Mirrors"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={24}  />
			<Select className={"question-card"} name={"25_0"} label={"Mailboxes"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={25}  />
			<Select className={"question-card"} name={"26_0"} label={"Walls"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={26}  />
			<Select className={"question-card"} name={"27_0"} label={"Cigarette urns - interior & exterior"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={27}  />
			<Select className={"question-card"} name={"28_0"} label={"Pictures, door jambs, vents, thermostats"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={28}  />
			<Select className={"question-card"} name={"29_0"} label={"Lighting fixtures"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={29}  />
			<Select className={"question-card"} name={"30_0"} label={"Ledges"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={30}  />
			<Select className={"question-card"} name={"31_0"} label={"Furniture"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={31}  />
			<TextInput className={"question-card"} name={"32_0"} label={"COMMENTS"} excel_reference={32} fail_comment={true}  />
			<div className={"question-card"} name={"33_0"} excel_reference={33}  >
				<Paragraph text={"Take a Photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"34_0"} excel_reference={34}  />
			<TextInput className={"question-card"} name={"35_0"} label={"Rating Total"} excel_reference={35} excel={'Q19+Q20+Q21+Q22+Q23+Q24+Q25+Q26+Q27+Q28+Q29+Q30+Q31'}  />
		</Accordion>,
		<Accordion index={36} header={<div className={"question-card"} name={"36_0"} excel_reference={36} style={{fontSize: '24px'}}  >
				<Paragraph text={"Elevators"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"37_0,38_0,39_0,40_0,41_0"} sub_total={"45_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"37_0"} label={"Cab walls, doors, frames"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={37}  />
			<Select className={"question-card"} name={"38_0"} label={"Stainless steel surfaces"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={38}  />
			<Select className={"question-card"} name={"39_0"} label={"Grill work in elevator top"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={39}  />
			<Select className={"question-card"} name={"40_0"} label={"Floors"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={40}  />
			<Select className={"question-card"} name={"41_0"} label={"Tracks"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={41}  />
			<TextInput className={"question-card"} name={"42_0"} label={"COMMENTS"} excel_reference={42} fail_comment={true}  />
			<div className={"question-card"} name={"43_0"} excel_reference={43}  >
				<Paragraph text={"Take a Photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"44_0"} excel_reference={44}  />
			<TextInput className={"question-card"} name={"45_0"} label={"Rating Total"} excel_reference={45} excel={'Q37+Q38+Q39+Q40+Q41'}  />
		</Accordion>,
		<Accordion index={46} header={<div className={"question-card"} name={"46_0"} excel_reference={46} style={{fontSize: '24px'}}  >
				<Paragraph text={"Stairwells & Landings"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"47_0,48_0,49_0,50_0,51_0"} sub_total={"55_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"47_0"} label={"Free of debris"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={47}  />
			<Select className={"question-card"} name={"48_0"} label={"Floors"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={48}  />
			<Select className={"question-card"} name={"49_0"} label={"Handrails"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={49}  />
			<Select className={"question-card"} name={"50_0"} label={"Ledges"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={50}  />
			<Select className={"question-card"} name={"51_0"} label={"Lights"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={51}  />
			<TextInput className={"question-card"} name={"52_0"} label={"COMMENTS"} excel_reference={52} fail_comment={true}  />
			<div className={"question-card"} name={"53_0"} excel_reference={53}  >
				<Paragraph text={"Take a Photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"54_0"} excel_reference={54}  />
			<TextInput className={"question-card"} name={"55_0"} label={"Rating Total"} excel_reference={55} excel={'Q47+Q48+Q49+Q50+Q51'}  />
		</Accordion>,
		<Accordion index={56} header={<div className={"question-card"} name={"56_0"} excel_reference={56} style={{fontSize: '24px'}}  >
				<Paragraph text={"Laundry Rooms"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"57_0,58_0,59_0,60_0,61_0,62_0"} sub_total={"66_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"57_0"} label={"Trash receptacles"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={57}  />
			<Select className={"question-card"} name={"58_0"} label={"Trash containers exterior "} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={58}  />
			<Select className={"question-card"} name={"59_0"} label={"Floors"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={59}  />
			<Select className={"question-card"} name={"60_0"} label={"Laundry equipment"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={60}  />
			<Select className={"question-card"} name={"61_0"} label={"Lint trap filters in dryers "} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={61}  />
			<Select className={"question-card"} name={"62_0"} label={"Area behind laundry equipment"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={62}  />
			<TextInput className={"question-card"} name={"63_0"} label={"COMMENTS"} excel_reference={63} fail_comment={true}  />
			<div className={"question-card"} name={"64_0"} excel_reference={64}  >
				<Paragraph text={"Take a Photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"65_0"} excel_reference={65}  />
			<TextInput className={"question-card"} name={"66_0"} label={"Rating Total"} excel_reference={66} excel={'Q57+Q58+Q59+Q60+Q61+Q62'}  />
		</Accordion>,
		<Accordion index={67} header={<div className={"question-card"} name={"67_0"} excel_reference={67} style={{fontSize: '24px'}}  >
				<Paragraph text={"Washrooms"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"68_0,69_0,70_0,71_0,72_0,73_0,74_0,75_0,76_0,77_0"} sub_total={"81_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"68_0"} label={"Trash receptacles"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={68}  />
			<Select className={"question-card"} name={"69_0"} label={"Trash containers exterior"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={69}  />
			<Select className={"question-card"} name={"70_0"} label={"Toilets, urinals, basins"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={70}  />
			<Select className={"question-card"} name={"71_0"} label={"Showers"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={71}  />
			<Select className={"question-card"} name={"72_0"} label={"Bright work"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={72}  />
			<Select className={"question-card"} name={"73_0"} label={"Piping"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={73}  />
			<Select className={"question-card"} name={"74_0"} label={"Partitions"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={74}  />
			<Select className={"question-card"} name={"75_0"} label={"Dispensers - clean"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={75}  />
			<Select className={"question-card"} name={"76_0"} label={"Dispensers - stocked"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={76}  />
			<Select className={"question-card"} name={"77_0"} label={"Floors"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={77}  />
			<TextInput className={"question-card"} name={"78_0"} label={"COMMENTS"} excel_reference={78} fail_comment={true}  />
			<div className={"question-card"} name={"79_0"} excel_reference={79}  >
				<Paragraph text={"Take a Photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"80_0"} excel_reference={80}  />
			<TextInput className={"question-card"} name={"81_0"} label={"Rating Total"} excel_reference={81} excel={'Q68+Q69+Q70+Q71+Q72+Q73+Q74+Q75+Q76+Q77'}  />
		</Accordion>,
		<Accordion index={82} header={<div className={"question-card"} name={"82_0"} excel_reference={82} style={{fontSize: '24px'}}  >
				<Paragraph text={"Trash Rooms"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"83_0,84_0,85_0"} sub_total={"89_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"83_0"} label={"Free of debris"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={83}  />
			<Select className={"question-card"} name={"84_0"} label={"Dumpster"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={84}  />
			<Select className={"question-card"} name={"85_0"} label={"Floors"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={85}  />
			<TextInput className={"question-card"} name={"86_0"} label={"COMMENTS"} excel_reference={86} fail_comment={true}  />
			<div className={"question-card"} name={"87_0"} excel_reference={87}  >
				<Paragraph text={"Take a Photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"88_0"} excel_reference={88}  />
			<TextInput className={"question-card"} name={"89_0"} label={"Rating Total"} excel_reference={89} excel={'Q83+Q84+Q85'}  />
		</Accordion>,
		<TextInput className={"question-card"} name={"90_0"} label={"Grand Total"} excel_reference={90} excel={'Q17+Q35+Q45+Q55+Q66+Q81+Q89'}  />,
		<TextInput className={"question-card"} name={"91_0"} label={"Percentage"} excel_reference={91} excel={'(Q90/50)*100'}  />,
    ];

    return elements;
}

export default get_form_elements;
