import React, { Component } from 'react';
import {Wrapper, Container, Header, Div, Paragraph, FormWithChildren, DateTimePicker, TextInput, Select, TextArea, UserSearchInput, SignatureInput, ImageInput, Accordion} from 'library';

function get_form_elements(){
    var elements = [
		<Accordion index={3230} header={<div className={"question-card"} name={"3230_0"} excel_reference={1} style={{fontSize: '24px'}}  >
				<Paragraph text={"Kleen-Tech  PLUGGING-IN SCORECARD- RECRUITER"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"3231_0,3232_0,3233_0,3234_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<TextInput className={"question-card"} name={"3231_0"} label={"NAME "} excel_reference={2}  />
			<TextInput className={"question-card"} name={"3232_0"} label={"REGION"} excel_reference={3}  />
			<TextInput className={"question-card"} name={"3233_0"} label={"SCORING MONTH"} excel_reference={4}  />
			<UserSearchInput className={"question-card"} name={"3234_0"} label={"Type and then CLICK on your SUPERVISOR's e-mail address here."} excel_reference={5}  />
		</Accordion>,
		<Accordion index={3235} header={<div className={"question-card"} name={"3235_0"} excel_reference={6} style={{fontSize: '24px'}}  >
				<Paragraph text={"PLUGGING IN"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"3236_0,3295_0,3298_0,3238_0,3296_0,3299_0,3240_0,3297_0,3300_0,3243_0,3274_0,3301_0,3242_0,3275_0,3302_0,3246_0,3276_0,3303_0,3247_0,3277_0,3304_0,3248_0,3278_0,3305_0,3249_0,3279_0,3306_0,3250_0,3280_0,3307_0,3251_0,3252_0,3281_0,3308_0,3253_0,3282_0,3309_0,3254_0,3283_0,3310_0,3255_0,3284_0,3311_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"3236_0"} label={"Employee - Proactively Identifies & Implements Effective Recruiting Strategies (flyers, ads, etc.)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={7}  />
			<Select className={"question-card"} name={"3295_0"} label={"Manager - Proactively Identifies & Implements Effective Recruiting Strategies (flyers, ads, etc.)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={8}  />
			<TextInput className={"question-card"} name={"3298_0"} label={"Comment - Proactively Identifies & Implements Effective Recruiting Strategies (flyers, ads, etc.)"} excel_reference={9}  />
			<Select className={"question-card"} name={"3238_0"} label={"Employee - Coordinates Job Posting Efforts within Budget(approval from HR Director when necessary)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={10}  />
			<Select className={"question-card"} name={"3296_0"} label={"Manager - Coordinates Job Posting Efforts within Budget(approval from HR Director when necessary)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={11}  />
			<TextInput className={"question-card"} name={"3299_0"} label={"Comment - Coordinates Job Posting Efforts within Budget(approval from HR Director when necessary)"} excel_reference={12}  />
			<Select className={"question-card"} name={"3240_0"} label={"Employee - Sets Up Job Fairs as needed(job fair location, date, time and post ads)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={13}  />
			<Select className={"question-card"} name={"3297_0"} label={"Manager - Sets Up Job Fairs as needed(job fair location, date, time and post ads)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={14}  />
			<TextInput className={"question-card"} name={"3300_0"} label={"Comment - Sets Up Job Fairs as needed(job fair location, date, time and post ads)"} excel_reference={15}  />
			<Select className={"question-card"} name={"3243_0"} label={"Employee  - Conducts Screening on all Candidates(candidates for specific roles)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={16}  />
			<Select className={"question-card"} name={"3274_0"} label={"Manager - Conducts Screening on all Candidates(candidates for specific roles)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={17}  />
			<TextInput className={"question-card"} name={"3301_0"} label={"Comment - Conducts Screening on all Candidates(candidates for specific roles)"} excel_reference={18}  />
			<Select className={"question-card"} name={"3242_0"} label={"Employee - Completes Pre-Employment Screening(background checks, badging, drug screening & any other site specific requirements)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={19}  />
			<Select className={"question-card"} name={"3275_0"} label={"Manager - Completes Pre-Employment Screening(background checks, badging, drug screening & any other site specific requirements)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={20}  />
			<TextInput className={"question-card"} name={"3302_0"} label={"Comment - Completes Pre-Employment Screening(background checks, badging, drug screening &amp; any other site specific requirements)"} excel_reference={21}  />
			<Select className={"question-card"} name={"3246_0"} label={"Employee - Ensures Applicants are Fully Informed(job requirements and scope)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={22}  />
			<Select className={"question-card"} name={"3276_0"} label={"Manager - Ensures Applicants are Fully Informed(job requirements and scope)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={23}  />
			<TextInput className={"question-card"} name={"3303_0"} label={"Comment - Ensures Applicants are Fully Informed(job requirements and scope)"} excel_reference={24}  />
			<Select className={"question-card"} name={"3247_0"} label={"Employee - Manages Full-Cycle Recruiting Process  and fills KT positions within Time to Fill standard (within 7 calendar days or less)(timely find the candidates &amp; complete hiring process)                                       "} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={25}  />
			<Select className={"question-card"} name={"3277_0"} label={"Manager - Manages Full-Cycle Recruiting Process  and fills KT positions within Time to Fill standard (within 7 calendar days or less)(timely find the candidates &amp; complete hiring process)                                       "} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={26}  />
			<TextInput className={"question-card"} name={"3304_0"} label={"Comment - Manages Full-Cycle Recruiting Process  and fills KT-TX positions within Time to Fill standard (within 7 calendar days or less)(timely find the candidates & complete hiring process)                                       "} excel_reference={27}  />
			<Select className={"question-card"} name={"3248_0"} label={"Employee - Accurately Completes and Reviews all New Hire Packets"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={28}  />
			<Select className={"question-card"} name={"3278_0"} label={"Manager - Accurately Completes and Reviews all New Hire Packets"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={29}  />
			<TextInput className={"question-card"} name={"3305_0"} label={"Comment - Accurately Completes and Reviews all New Hire Packets"} excel_reference={30}  />
			<Select className={"question-card"} name={"3249_0"} label={"Employee - Submits New Hire Packets on Time(Scan to MC withing one business day / send all originals to Corporate by Fed Ex)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={31}  />
			<Select className={"question-card"} name={"3279_0"} label={"Manager - Submits New Hire Packets on Time(Scan to MC withing one business day / send all originals to Corporate by Fed Ex)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={32}  />
			<TextInput className={"question-card"} name={"3306_0"} label={"Comment - Submits New Hire Packets on Time(Scan to MC withing one business day / send all originals to Corporate by Fed Ex)"} excel_reference={33}  />
			<Select className={"question-card"} name={"3250_0"} label={"Employee - Coordinates Start Dates for New Hires with Supervisors/Managers(date, time, etc.)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={34}  />
			<Select className={"question-card"} name={"3280_0"} label={"Manager - Coordinates Start Dates for New Hires with Supervisors/Managers(date, time, etc.)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={35}  />
			<TextInput className={"question-card"} name={"3307_0"} label={"Comment - Coordinates Start Dates for New Hires with Supervisors/Managers(date, time, etc.)"} excel_reference={36}  />
			<Select className={"question-card"} name={"3252_0"} label={"Employee - Assists with Recruitment Strategies(to increase applicant pool)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={38}  />
			<Select className={"question-card"} name={"3281_0"} label={"Manager - Assists with Recruitment Strategies(to increase applicant pool)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={39}  />
			<TextInput className={"question-card"} name={"3308_0"} label={"Comment - Assists with Recruitment Strategies(to increase applicant pool)"} excel_reference={40}  />
			<Select className={"question-card"} name={"3253_0"} label={"Employee - Generates and Manages a Strong Candidate Pipeline(updates on a weekly basis)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={41}  />
			<Select className={"question-card"} name={"3282_0"} label={"Manager - Generates and Manages a Strong Candidate Pipeline(updates on a weekly basis)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={42}  />
			<TextInput className={"question-card"} name={"3309_0"} label={"Comment - Generates and Manages a Strong Candidate Pipeline(updates on a weekly basis)"} excel_reference={43}  />
			<Select className={"question-card"} name={"3254_0"} label={"Employee - Meets with each Supervisor a minimum of 1 time per week (review recruitment needs and status)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={44}  />
			<Select className={"question-card"} name={"3283_0"} label={"Manager -  Meets with each Supervisor a minimum of 1 time per week (review recruitment needs and status)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={45}  />
			<TextInput className={"question-card"} name={"3310_0"} label={"Comment -  Meets with each Supervisor a minimum of 1 time per week (review recruitment needs and status)"} excel_reference={46}  />
			<Select className={"question-card"} name={"3255_0"} label={"Employee - Attends Daily Staffing Meetings(recruitment needs and updates)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={47}  />
			<Select className={"question-card"} name={"3284_0"} label={"Manager - Attends Daily Staffing Meetings(recruitment needs and updates)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={48}  />
			<TextInput className={"question-card"} name={"3311_0"} label={"Comment - Attends Daily Staffing Meetings(recruitment needs and updates)"} excel_reference={49}  />
		</Accordion>,
		<Accordion index={3256} header={<div className={"question-card"} name={"3256_0"} excel_reference={50} style={{fontSize: '24px'}}  >
				<Paragraph text={"MANAGEMENT"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"3257_0,3285_0,3312_0,3258_0,3286_0,3313_0,3259_0,3287_0,3314_0,3260_0,3288_0,3315_0,3261_0,3289_0,3316_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"3257_0"} label={"Employee - Manages Candidates by Giving Timely Feedback(updates candidates throughout process)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={51}  />
			<Select className={"question-card"} name={"3285_0"} label={"Manager - Manages Candidates by Giving Timely Feedback(updates candidates throughout process)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={52}  />
			<TextInput className={"question-card"} name={"3312_0"} label={"Comment - Manages Candidates by Giving Timely Feedback(updates candidates throughout process)"} excel_reference={53}  />
			<Select className={"question-card"} name={"3258_0"} label={"Employee - Creates and Maintains Metrics(time to fill through tracking)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={54}  />
			<Select className={"question-card"} name={"3286_0"} label={"Manager - Creates and Maintains Metrics(time to fill through tracking)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={55}  />
			<TextInput className={"question-card"} name={"3313_0"} label={"Comment - Creates and Maintains Metrics(time to fill through tracking)"} excel_reference={56}  />
			<Select className={"question-card"} name={"3259_0"} label={"Employee - Updates Recruitment Trackers on a weekly basis(recruitment and candidate pipelines)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={57}  />
			<Select className={"question-card"} name={"3287_0"} label={"Manager - Updates Recruitment Trackers on a weekly basis(recruitment and candidate pipelines)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={58}  />
			<TextInput className={"question-card"} name={"3314_0"} label={"Comment - Updates Recruitment Trackers on a weekly basis(recruitment and candidate pipelines)"} excel_reference={59}  />
			<Select className={"question-card"} name={"3260_0"} label={"Employee - Updates employee rosters and/or provides information to Operations in a timely manner."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={60}  />
			<Select className={"question-card"} name={"3288_0"} label={"Manager - Updates employee rosters and/or provides information to Operations in a timely manner."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={61}  />
			<TextInput className={"question-card"} name={"3315_0"} label={"Comment - Updates Staffing Rosters on a weekly basis"} excel_reference={62}  />
			<Select className={"question-card"} name={"3261_0"} label={"Employee - Ensures Recruitment &amp; Hiring Process complies with Employment Laws & Regulations and Organizational Policies & Procedures "} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={63}  />
			<Select className={"question-card"} name={"3289_0"} label={"Manager - Ensures Recruitment & Hiring Process complies with Employment Laws & Regulations and Organizational Policies & Procedures "} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={64}  />
			<TextInput className={"question-card"} name={"3316_0"} label={"Comment - Ensures Recruitment & Hiring Process complies with Employment Laws & Regulations and Organizational Policies & Procedures "} excel_reference={65}  />
		</Accordion>,
		<Accordion index={3262} header={<div className={"question-card"} name={"3262_0"} excel_reference={66} style={{fontSize: '24px'}}  >
				<Paragraph text={"COLLABORATION"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"3263_0,3290_0,3317_0,3264_0,3291_0,3318_0,3265_0,3292_0,3319_0,3266_0,3293_0,3320_0,3267_0,3294_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"3263_0"} label={"Employee - Consults with Ops on Current & Future Openings"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={67}  />
			<Select className={"question-card"} name={"3290_0"} label={"Manager - Consults with Ops on Current & Future Openings"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={68}  />
			<TextInput className={"question-card"} name={"3317_0"} label={"Comment - Consults with Ops on Current & Future Openings"} excel_reference={69}  />
			<Select className={"question-card"} name={"3264_0"} label={"Employee - Collaborates with HR when necessary(recruitment, selection, hiring process)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={70}  />
			<Select className={"question-card"} name={"3291_0"} label={"Manager - Collaborates with HR when necessary(recruitment, selection, hiring process)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={71}  />
			<TextInput className={"question-card"} name={"3318_0"} label={"Comment - Collaborates with HR when necessary(recruitment, selection, hiring process)"} excel_reference={72}  />
			<Select className={"question-card"} name={"3265_0"} label={"Employee - Maintains Effective Working Relationships w/Ops & HR(consults with both teams to identify best candidate)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={73}  />
			<Select className={"question-card"} name={"3292_0"} label={"Manager - Maintains Effective Working Replationships w/Ops & HR(consults with both teams to identify best candidate)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={74}  />
			<TextInput className={"question-card"} name={"3319_0"} label={"Comment - Maintains Effective Working Replationships w/Ops &amp; HR(consults with both teams to identify best candidate)"} excel_reference={75}  />
			<Select className={"question-card"} name={"3266_0"} label={"Employee - Immediately Escalate for Recruitment Support from Director of Ops(when identifying multiple recruitment priorities)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={76}  />
			<Select className={"question-card"} name={"3293_0"} label={"Manager - Immediately Escalate for Recruitment Support from Director of Ops(when identifying multiple recruitment priorities)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={77}  />
			<TextInput className={"question-card"} name={"3320_0"} label={"Comment - Immediately Escalate for Recruitment Support from Director of Ops(when identifying multiple recruitment priorities)"} excel_reference={78}  />
			<TextInput className={"question-card"} name={"3267_0"} label={"Employee - AVERAGE TOTAL SCORE  "} excel_reference={79} excel={'(Q7+Q10+Q13+Q16+Q19+Q22+Q25+Q28+Q31+Q34+Q38+Q41+Q44+Q47+Q51+Q54+Q57+Q60+Q63+Q67+Q70+Q73+Q76)/23'}  />
			<TextInput className={"question-card"} name={"3294_0"} label={"Manager - AVERAGE TOTAL SCORE"} excel_reference={80} excel={'(Q8+Q11+Q14+Q17+Q20+Q23+Q26+Q29+Q32+Q35+Q39+Q42+Q45+Q48+Q52+Q55+Q58+Q61+Q64+Q68+Q71+Q74+Q77)/23'}  />
		</Accordion>,
    ];

    return elements;
}

export default get_form_elements;
