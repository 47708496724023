import React, { Component } from 'react';
import { ajaxWrapper, resolveVariables } from 'functions';
import {
    Wrapper,
    Navbar,
    NavLink,
    If,
    Alert,
    Button,
    Header,
    EmptyModal,
    Container,
} from 'library';

import submit_pending_submissions from 'pages/custom_functions/submit_pending_submissions.js';

class SimpleNavbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
        };

        this.submit_pending_submissions =
            this.submit_pending_submissions.bind(this);
        this.complete_pending_submissions =
            this.complete_pending_submissions.bind(this);
    }

    componentDidMount() {
        window.cmState.subscribe_by_name(this, 'online');
    }

    submit_pending_submissions() {
        this.setState({
            modal_content: (
                <div>
                    <Alert
                        type="info"
                        style={{ padding: '20px', margin: '20px' }}
                    >
                        <Header
                            size={2}
                            text={'Submitting Answers. Please Wait...'}
                            style={{ textAlign: 'center' }}
                        />
                    </Alert>
                </div>
            ),
        });
        submit_pending_submissions(null, this.complete_pending_submissions);
    }

    complete_pending_submissions() {
        console.log('Submission Complete!!');
        this.setState({
            modal_content: (
                <div>
                    <Alert
                        type="info"
                        style={{ padding: '20px', margin: '20px' }}
                    >
                        <Header
                            size={2}
                            text={'Offline Submissions Submitted Successfully'}
                            style={{ textAlign: 'center' }}
                        />
                    </Alert>
                </div>
            ),
        });
        setTimeout(function () {
            window.location.reload();
        }, 2000);
    }

    render() {
        var global_state = window.cmState.getGlobalState();

        var navbar_style = {
            backgroundImage: 'linear-gradient(to bottom,#fff 0,#f8f8f8 100%)',
            boxShadow:
                'inset 0 1px 0 rgba(255,255,255,.15), 0 1px 5px rgba(0,0,0,.075)',
        };

        var alert_style = {
            textAlign: 'center',
            margin: '0px',
            position: 'fixed',
            bottom: '0px',
            zIndex: 10,
            width: '100%',
        };

        navbar_style = Object.assign(navbar_style, this.props.style);

        var links = [
            <NavLink href={'/'} name={'HOME'} />,
            <NavLink href={'/login/'} name={'MEMBER LOGIN'} />,
        ];
        if (global_state.user) {
            links = [
                <NavLink name={'Hello, ' + global_state.user.email} />,
                <NavLink href={'/profile/'} name={'PROFILE'} />,
                <NavLink href={'/logout/'} name={'LOGOUT'} />,
            ];
        }
        if (
            global_state.user &&
            (global_state.user.is_staff || global_state.user.can_edit_users)
        ) {
            links = [
                <NavLink name={'Hello, ' + global_state.user.email} />,
                <NavLink href={'/profile/'} name={'PROFILE'} />,
                <NavLink
                    href={'/manage_users/'}
                    name={'MANAGE USER DETAILS'}
                />,
                <NavLink href={'/logout/'} name={'LOGOUT'} />,
            ];
        }

        var pending_submissions = localStorage.getItem('pending_submissions');
        if (pending_submissions) {
            pending_submissions = JSON.parse(pending_submissions);
        }
        if (pending_submissions && pending_submissions.length) {
            pending_submissions = {};
        }

        var online_status = null;
        var submission_alert = null;
        if (!global_state.online) {
            online_status = (
                <Alert type="danger" style={alert_style}>
                    <Header size="4" text="You are offline!" />
                </Alert>
            );
        } else if (
            pending_submissions &&
            Object.keys(pending_submissions).length > 0
        ) {
            submission_alert = (
                <Alert type="danger" style={alert_style}>
                    <Header
                        size="4"
                        text={
                            'You have ' +
                            Object.keys(pending_submissions).length +
                            ' pending submissions'
                        }
                    />
                    <Button
                        type="primary"
                        text="Submit Results"
                        onClick={this.submit_pending_submissions}
                    />
                </Alert>
            );
        }
        /*
        else if (this.state.message){
            submission_alert = <Alert type='info' style={alert_style}>
                <Header size='4' text={this.state.message} />
            </Alert>;
        }
        */

        var modal = null;
        if (this.state.modal_content) {
            modal = (
                <EmptyModal show={true} onHide={this.hide_modal}>
                    <Container>
                        <div>{this.state.modal_content}</div>
                    </Container>
                </EmptyModal>
            );
        }

        return (
            <div>
                <Navbar
                    style={navbar_style}
                    logo="/static/images/logo.png"
                    name_link={'/'}
                >
                    {links}
                </Navbar>

                {online_status}
                {submission_alert}
                {modal}
            </div>
        );
    }
}

export default SimpleNavbar;
