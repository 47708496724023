import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';

import {Wrapper, Container, Header, Button, ListWithChildren, CardWithChildren, Paragraph, Link} from 'library';

class CSSClasses extends Component {

    render() {

        return (<div>
			<Container >
				<Header size={3} text={"CSS Classes"} form_child_update_key={null}  />
				<Button href={"/css_class/ /"} text={"Add New CSS Class"} type={"success"} form_child_update_key={null}  />
				<ListWithChildren dataUrl={"/api/modelWebsite/cssclass/"} objectName={"cssclass"} form_child_update_key={null} >
					<CardWithChildren name={resolveVariables({"text":"{props.name}"}, this)["text"]} form_child_update_key={null} >
						<Paragraph text={resolveVariables({"text":"{props.attributes}"}, this)["text"]} form_child_update_key={null}  />
						<Link href={resolveVariables({"text":"/css_class/{props.id}/"}, this)["text"]} text={"Edit"} className={"btn btn-primary"} form_child_update_key={null}  />
					</CardWithChildren>
				</ListWithChildren>
			</Container>
        </div>);
    }
}
export default CSSClasses;
