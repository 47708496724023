import React, { Component } from 'react';
import {
    resolveVariables,
    ajaxWrapper,
    run_functions,
    run_excel_function,
    resolve_all_children,
    get_children,
    get_all_children,
} from 'functions';
import {
    Alert,
    Button,
    TextInput,
    NumberInput,
    CSSInput,
    Json_Input,
    Select,
    Function_Input,
} from 'library';

class FormWithChildren extends Component {
    static component_name = 'FormWithChildren';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'Class'} name={'className'} />,
                <TextInput label={'Submit Class'} name={'submitClassName'} />,
                <TextInput
                    label={'Submit Button Text'}
                    default="Save"
                    name={'submit_text'}
                />,
                <TextInput label={'submitUrl'} name={'submitUrl'} />,
                <TextInput label={'redirectUrl'} name={'redirectUrl'} />,
                <TextInput label={'deleteUrl'} name={'deleteUrl'} />,
                <TextInput
                    label={'deleteRedirectUrl'}
                    name={'deleteRedirectUrl'}
                />,
                <Json_Input label={'defaults'} name={'defaults'} />,
                <TextInput label={'objectName'} name={'objectName'} />,
                <Select
                    label={'autoSetGlobalState'}
                    name={'autoSetGlobalState'}
                    boolean={true}
                    defaultoption={false}
                />,
                <Select
                    label={'Auto Submit'}
                    name={'auto_submit'}
                    boolean={true}
                    defaultoption={false}
                />,
                <Select
                    label={'row'}
                    name={'row'}
                    boolean={true}
                    defaultoption={false}
                />,
                <Select
                    label={'Submit on Enter'}
                    name={'submit_on_enter'}
                    boolean={true}
                    defaultoption={false}
                />,
                <Select
                    label={'Reset on Submit'}
                    name={'reset_state_on_submit'}
                    boolean={true}
                    defaultoption={false}
                />,
                <Select
                    label={'Show submit button'}
                    name={'show_submit'}
                    boolean={true}
                    defaultoption={true}
                />,
                <TextInput
                    label={'globalStateName'}
                    name={'globalStateName'}
                />,
                <Function_Input
                    label={'Add Function On Submit'}
                    default={''}
                    name={'functions'}
                />,
                <Function_Input
                    label={'Add Function On Failure'}
                    default={''}
                    name={'failure_functions'}
                />,
                <CSSInput label={'css'} name={'style'} default={{}} />,
            ],
            can_have_children: true,
        };

        this.state = {
            form_child_update_key: null,
            required: [],
            _form_excel: {},
        };

        this.update = this.update.bind(this);
        this.get_form_defaults = this.get_form_defaults.bind(this);
        this.get_child_defaults = this.get_child_defaults.bind(this);

        this.get_form_excel = this.get_form_excel.bind(this);
        this.get_child_excel = this.get_child_excel.bind(this);
        this.check_excel_functions = this.check_excel_functions.bind(this);

        this.set_global_state = this.set_global_state.bind(this);
        this.handle_change = this.handle_change.bind(this);
        this.set_form_state = this.set_form_state.bind(this);
        this.reset_state_on_submit = this.reset_state_on_submit.bind(this);
        this.form_submit = this.form_submit.bind(this);
        this.form_submit_callback = this.form_submit_callback.bind(this);
        this.form_submit_completed = this.form_submit_completed.bind(this);
        this.form_submit_failure = this.form_submit_failure.bind(this);
        this.reload = this.reload.bind(this);
        this.check_required_children = this.check_required_children.bind(this);
        this.form_delete = this.form_delete.bind(this);
        this.handle_key_press = this.handle_key_press.bind(this);
    }

    componentDidMount() {
        this.update();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        var new_json = JSON.stringify(this.props.defaults);
        var old_json = JSON.stringify(prevProps.defaults);

        if (new_json != old_json) {
            this.update();
        }
    }

    update() {
        var defaults = this.get_form_defaults();

        if (this.props.auto_submit) {
            this.setState(defaults, function () {
                this.form_submit();
            });
        } else {
            var excel = this.get_form_excel();
            defaults['_form_excel'] = excel;
            this.setState(defaults);
            this.set_global_state(defaults);
        }

        if (this.props.reset_state_on_submit) {
            window.cmState.subscribe_by_name(this, this.props.globalStateName);
        }
    }

    get_form_defaults(clean) {
        var children = get_children(this.props);

        var defaults = {};
        if (
            this.props &&
            this.props.defaults &&
            !this.props.dont_resolve_anything
        ) {
            defaults = resolveVariables(
                this.props.defaults,
                window.cmState.getGlobalState(this),
            );
        }

        defaults = this.get_child_defaults(defaults, children, clean);

        if (!this.props.dont_resolve_anything) {
            defaults = resolveVariables(
                defaults,
                window.cmState.getGlobalState(),
            );
        }

        if (!('required' in defaults)) {
            defaults['required'] = [];
        }

        return defaults;
    }

    get_child_defaults(defaults, children, clean) {
        for (var index in children) {
            var child = children[index];
            if (
                child.props &&
                'default' in child.props &&
                typeof child.props.default != 'undefined'
            ) {
                var value = child.props.default;
                if (
                    value &&
                    (value == true ||
                        (value.indexOf('{') == -1 && value.indexOf('}') == -1))
                ) {
                    defaults[child.props.name] = value;
                }
            } else if (
                child.props &&
                'defaultoption' in child.props &&
                typeof child.props.defaultoption != 'undefined'
            ) {
                var value = child.props.defaultoption;
                if (
                    value &&
                    (value == true ||
                        (value.indexOf('{') == -1 && value.indexOf('}') == -1))
                ) {
                    defaults[child.props.name] = value;
                }
            } else if (clean) {
                defaults[child.props.name] = undefined;
            }

            var grand_children = get_children(child.props);
            if (grand_children.length > 0) {
                defaults = this.get_child_defaults(
                    defaults,
                    grand_children,
                    clean,
                );
            }
        }

        return defaults;
    }

    get_form_excel(clean) {
        var children = get_children(this.props);

        var excel = {};
        excel = this.get_child_excel(
            { functions: {}, lookups: {}, reverse_lookups: {} },
            children,
        );

        return excel;
    }

    get_child_excel(excel, children) {
        for (var index in children) {
            var child = children[index];
            if (
                child.props &&
                'excel' in child.props &&
                typeof child.props.excel != 'undefined'
            ) {
                excel['functions'][child.props.name] = child.props.excel;
            }
            if (
                child.props &&
                'excel_reference' in child.props &&
                typeof child.props.excel_reference != 'undefined'
            ) {
                excel['lookups'][child.props.excel_reference] =
                    child.props.name;
                excel['reverse_lookups'][child.props.name] =
                    child.props.excel_reference;
            }

            var grand_children = get_children(child.props);
            if (grand_children.length > 0) {
                excel = this.get_child_excel(excel, grand_children);
            }
        }

        return excel;
    }

    set_global_state(state) {
        if (
            this.props.autoSetGlobalState == true ||
            this.props.autoSetGlobalState == 'true'
        ) {
            if (this.props.setGlobalState) {
                this.props.setGlobalState(this.props.globalStateName, state);
            }

            window.cmState.setGlobalState(this.props.globalStateName, state);
        }
    }

    handle_change(e) {
        var newState = {};

        var name = e.target.getAttribute('name');
        newState[name] = e.target.value;

        var moreState = this.check_excel_functions(name, newState);
        newState = Object.assign(newState, moreState);

        if (this.props.full_state) {
            var newCompletedState = this.state;
            newCompletedState[name] = e.target.value;
        } else {
            var newCompletedState = newState;
        }

        this.setState(newState, this.set_global_state(newCompletedState));
    }

    check_excel_functions(name, updated_state) {
        var state = Object.assign({}, updated_state);
        var excel_state = {};
        var form_excel = this.state._form_excel;

        while (Object.keys(state).length > 0) {
            var new_state = {};
            for (var updating_name in state) {
                for (var key in form_excel['functions']) {
                    var excel_function = form_excel['functions'][key];
                    var element_number =
                        form_excel['reverse_lookups'][updating_name];
                    var element_excel_reference = 'Q' + String(element_number);
                    var element_excel_index = excel_function.indexOf(
                        element_excel_reference,
                    );

                    if (
                        element_number &&
                        element_excel_index > -1 &&
                        isNaN(
                            excel_function[
                                element_excel_index +
                                    element_excel_reference.length
                            ],
                        )
                    ) {
                        var value = run_excel_function(
                            excel_function,
                            state,
                            this.state,
                            this.state._form_excel.lookups,
                        );
                        new_state[key] = value;
                        excel_state[key] = value;
                    }
                }
            }

            state = new_state;
        }

        return excel_state;
    }

    set_form_state(state, set_global_state_trigger) {
        if (this.props.full_state || set_global_state_trigger) {
            var newState = this.state;
        } else {
            var newState = {};
        }

        for (var index in state) {
            newState[index] = state[index];

            var moreState = this.check_excel_functions(index, newState);
            newState = Object.assign(newState, moreState);
            state = Object.assign(state, moreState);
        }

        this.setState(state);

        if (!set_global_state_trigger) {
            this.set_global_state(newState);
        } else {
            this.forceUpdate();
        }
    }

    reset_state_on_submit() {
        var defaults = this.get_form_defaults(true);
        defaults['form_is_saving_right_now'] = false;

        // Reset key values for all children in order to fully clear states and rerender
        var date = Date.now();
        defaults['form_child_update_key'] = date;

        this.setState(defaults);
    }

    form_submit() {
        var data = Object.assign({}, this.state);
        delete data['children'];
        delete data['form_state'];

        var required = [];
        if (!this.props.external_required) {
            required = this.check_required_children([], this.props.children);
        }
        this.setState({ required: required });
        if (required.length == 0) {
            for (var item in data) {
                if (item.endsWith('[]')) {
                    data[item] = JSON.stringify(data[item]);
                }
            }

            if (this.props.submit) {
                this.setState({ form_is_saving_right_now: true });
                this.props.submit(data, this.form_submit_completed);
            } else if (this.props.submitUrl) {
                var submitUrl = resolveVariables(
                    { submitUrl: this.props.submitUrl },
                    window.cmState.getGlobalState(this),
                )['submitUrl'];

                ajaxWrapper(
                    'POST',
                    submitUrl,
                    data,
                    this.form_submit_callback,
                    this.form_submit_failure,
                );
                this.setState({ form_is_saving_right_now: true });
            } else if (
                this.props.functions &&
                this.props.functions.length > 0
            ) {
                data['success'] = true;
                this.form_submit_callback(data);
            }
        }
    }

    form_submit_callback(value) {
        if (typeof value[0] != 'undefined') {
            if (this.props.setGlobalState) {
                if (this.props.globalStateName) {
                    var returnObj = value[0][this.props.objectName];
                    this.setState(value[0][this.props.objectName], () =>
                        this.props.setGlobalState(
                            this.props.globalStateName,
                            value[0][this.props.objectName],
                        ),
                    );
                } else {
                    this.setState(
                        value[0][this.props.objectName],
                        this.props.setGlobalState('Form', this.state),
                    );
                }
            } else if (value['success'] == true) {
                //do nothing
            } else {
                if (value[0]) {
                    this.setState(value[0][this.props.objectName]);
                }
            }
        }

        if (this.props.deleteRedirectUrl && value['success'] == true) {
            window.location.href = this.props.deleteRedirectUrl;
        } else if (this.props.redirectUrl) {
            if (this.props.objectName) {
                var redirectUrl = resolveVariables(
                    { redirectUrl: this.props.redirectUrl },
                    value[0][this.props.objectName],
                );
            } else {
                var redirectUrl = resolveVariables(
                    { redirectUrl: this.props.redirectUrl },
                    value,
                );
            }

            window.location.href = redirectUrl['redirectUrl'];
        }

        if (this.props.redirect) {
            value['form_state'] = this.state;
            this.props.redirect(value);
        } else if (this.props.functions) {
            var functions = this.props.functions;
            if (this.props.objectName) {
                functions = resolveVariables(
                    functions,
                    value[0][this.props.objectName],
                );
            } else {
                functions = resolveVariables(functions, value);
                functions = resolveVariables(functions, this.state);
            }

            run_functions(
                functions,
                this.setState.bind(this),
                this.props.setGlobalState,
            );
        } else if (this.props.refreshData) {
            this.props.refreshData();
        }

        this.form_submit_completed();
    }

    form_submit_completed() {
        if (this.props.reset_state_on_submit) {
            this.reset_state_on_submit();
        } else {
            this.setState({ form_is_saving_right_now: false });
        }
    }

    form_submit_failure(value) {
        try {
            value = JSON.parse(value);
        } catch (e) {
            console.log(e, value);
        }

        var required = this.state.required;

        if (this.props.submit_failure) {
            this.props.submit_failure(value);
        } else if ('error' in value) {
            required.push(value['error']);
        }

        this.setState(
            {
                form_is_saving_right_now: false,
                required: required,
            },
            function () {
                this.set_global_state({ required: required });
            },
        );

        if (this.props.failure_functions) {
            var functions = this.props.failure_functions;
            if (this.props.objectName) {
                functions = resolveVariables(
                    functions,
                    value[0][this.props.objectName],
                );
            } else {
                functions = resolveVariables(functions, value);
                functions = resolveVariables(functions, this.state);
            }

            run_functions(functions, this.setState, this.props.setGlobalState);
        }
    }

    reload(value) {
        window.location.reload();
    }

    check_required_children(required, context) {
        for (var index in context) {
            var child = context[index];
            if (!window.cmState.is_valid_react_child(child)) {
                continue;
            }

            var props = child.props;

            if (props.required == true) {
                if (
                    !(props.name in this.state) ||
                    this.state[props.name] == undefined ||
                    this.state[props.name] === ''
                ) {
                    var field_name = props.label;
                    // Fallback behavior in case no label was applied to the input
                    if (!field_name || field_name == '') {
                        field_name = props.name;
                    }

                    required.push(
                        'The field ' +
                            field_name +
                            ' must be filled out to submit the form. ',
                    );
                }
            }

            var children = child.props.children;
            if (typeof children != 'undefined') {
                if (typeof children.length == 'undefined') {
                    children = [child.props.children];
                }
                required = this.check_required_children(required, children);
            }
        }

        return required;
    }

    form_delete() {
        ajaxWrapper(
            'POST',
            this.props.deleteUrl,
            {},
            this.form_submit_callback,
            this.form_submit_failure,
        );
    }

    handle_key_press(event) {
        var target_num = event.target.getAttribute('num');
        if (
            this.props.submit_on_enter &&
            !this.state.form_is_saving_right_now &&
            target_num != 'dont_submit'
        ) {
            console.log('Event.key', event.key);
            if (event.key == 'Enter') {
                this.form_submit();
            }
        }
    }

    render() {
        var layout = '';
        if (typeof this.props.className != 'undefined') {
            layout = this.props.className;
        }
        if (this.props.row == true || this.props.row == 'true') {
            layout += ' form-row row';
        } else {
            layout += ' form';
        }

        var newProps = {
            setFormState: this.set_form_state,
            handleChange: this.handle_change,
            handleKeyPress: this.handle_key_press,
            dont_resolve_anything: this.props.dont_resolve_anything,
            status: this.state.status,
        };

        var components = [];
        if (this.props.dont_resolve_anything) {
            components = get_all_children(this, newProps, this.state, true);
        } else {
            components = resolve_all_children(this, newProps, this.state, true);
        }

        if (this.state.form_child_update_key) {
            var new_components = [];
            for (var i in components) {
                var component = components[i];
                component = React.cloneElement(component, {
                    key: this.state.form_child_update_key + '_' + i,
                });
                new_components.push(component);
            }

            components = new_components;
        }

        var buttons = [];
        if (
            this.props.submitUrl ||
            this.props.submit ||
            (this.props.functions &&
                this.props.functions.length > 0 &&
                this.props.show_submit)
        ) {
            var classes = 'btn btn-primary';
            if (this.props.submitButtonType) {
                classes = 'btn btn-' + this.props.submitButtonType;
            }
            var float = { float: 'left' };
            classes += ' ' + this.props.submitClassName;

            var submitButton = (
                <button
                    css={float}
                    className={classes}
                    onClick={this.form_submit}
                >
                    {this.props.submit_text || 'Save'}
                </button>
            );
            // Anti-mash behavior for form.  This will force users to wait until callback functions have completed
            // and ensure the form is submitted properly
            if (
                this.state.form_is_saving_right_now ||
                this.state.uploading_from_file_input
            ) {
                submitButton = (
                    <button
                        css={float}
                        className={classes + ' disabled'}
                        disabled={'disabled'}
                    >
                        {this.props.submit_text || 'Save'}
                    </button>
                );
            }
            buttons.push(submitButton);
        }

        if (this.props.deleteUrl) {
            var float = { float: 'right' };
            var deleteButton = (
                <Button
                    css={float}
                    type={'danger'}
                    onClick={this.form_delete}
                    deleteType={true}
                    text={this.props.deleteText || 'Delete'}
                />
            );
            buttons.push(deleteButton);
        }

        var failed = [];
        if (this.state.required != []) {
            for (var i in this.state.required) {
                failed.push(
                    <Alert type={'danger'} text={this.state.required[i]} />,
                );
            }
        }

        var show_required = null;
        if (this.props.show_required) {
            if (this.props.required_count) {
                show_required = (
                    <Alert
                        type="error"
                        text={
                            'Please check your form. ' +
                            this.props.required_count +
                            ' required fields are missing.'
                        }
                    />
                );
            } else {
                show_required = (
                    <Alert
                        type="error"
                        text="Please check your form. Required fields are missing."
                    />
                );
            }
        }

        //need to add in form_submit, delete, and handle change functions to components.
        return (
            <div
                className={layout}
                style={this.props.style}
                onKeyPress={this.handle_key_press}
            >
                {components}
                {failed}
                {show_required}
                {buttons}
                <div style={{ clear: 'both' }}></div>
            </div>
        );
    }
}

export default FormWithChildren;
