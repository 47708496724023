import React, { Component } from 'react';
import {Wrapper, Container, Header, Div, Paragraph, FormWithChildren, DateTimePicker, TextInput, Select, TextArea, UserSearchInput, SignatureInput, ImageInput, Accordion} from 'library';

function get_form_elements(){
    var elements = [
		<Accordion index={10260} header={<div className={"question-card"} name={"10260_0"} excel_reference={1} style={{fontSize: '24px'}}  >
				<Paragraph text={"QUALITY CONTROL INSPECTION"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"10261_0,10264_0,10265_0,10266_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"10261_0"} label={"Select Position Below if QC Form is Completed by a Supervisor or Manager"} options={[{"text": "Supervisor", "value": "Supervisor"}, {"text": "Area Supervisor", "value": "Area Supervisor"}, {"text": "Dir/Mgr of Operations", "value": "Dir/Mgr of Operations"}, {"text": "Vice President", "value": "Vice President"}]} radio={true} excel_reference={2}  />
			<TextInput className={"question-card"} name={"10264_0"} label={"Area:"} excel_reference={5} required={true}  />
			<TextInput className={"question-card"} name={"10265_0"} label={"Completed by:"} excel_reference={6} required={true}  />
			<TextInput className={"question-card"} name={"10266_0"} label={"Supervisor"} excel_reference={7} required={true}  />
		</Accordion>,
		<Accordion index={10267} header={<div className={"question-card"} name={"10267_0"} excel_reference={8} anchor={true} anchor_text={"MAIN ENTRANCE / LOBBY / ELEVAT"} style={{fontSize: '24px'}}  >
				<Paragraph text={"MAIN ENTRANCE / LOBBY / ELEVATORS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"10268_0,10269_0,10270_0,10271_0,10272_0,10273_0,10274_0,10275_0,10276_0,10277_0,10278_0"} sub_total={"10282_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"10268_0"} label={"Door Glass / Side Glass - Main entrance"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={9}  />
			<Select className={"question-card"} name={"10269_0"} label={"Walk Off Mats / Corners & Edges"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={10}  />
			<Select className={"question-card"} name={"10270_0"} label={"Carpets Vacuumed  / Spot Clean Stains"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={11}  />
			<Select className={"question-card"} name={"10271_0"} label={"Floors - Sweep, Mop, Buff & Vacuum"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={12}  />
			<Select className={"question-card"} name={"10272_0"} label={"Main Elevator Cabs - Interior & Exterior Directional Plates, Tracks"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={13}  />
			<Select className={"question-card"} name={"10273_0"} label={"Escalators & Landings"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={14}  />
			<Select className={"question-card"} name={"10274_0"} label={"Ice Machine"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={15}  />
			<Select className={"question-card"} name={"10275_0"} label={"High Low Dusting / A/C Vents"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={16}  />
			<Select className={"question-card"} name={"10276_0"} label={"Trash Containers / Ash Urns"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={17}  />
			<Select className={"question-card"} name={"10277_0"} label={"Lobby Furniture & Fixtures"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={18}  />
			<Select className={"question-card"} name={"10278_0"} label={"Building Exterior (police area)"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={19}  />
			<TextInput className={"question-card"} name={"10279_0"} label={"COMMENTS "} excel_reference={20} fail_comment={true}  />
			<div className={"question-card"} name={"10280_0"} excel_reference={21}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"10281_0"} excel_reference={22}  />
			<TextInput className={"question-card"} name={"10282_0"} label={"Total Rating"} excel_reference={23} excel={'+Q9+Q10+Q11+Q12+Q13+Q14+Q15+Q16+Q17+Q18+Q19'}  />
		</Accordion>,
		<Accordion index={10283} header={<div className={"question-card"} name={"10283_0"} excel_reference={24} anchor={true} anchor_text={"COMMON AREAS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"COMMON AREAS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"10284_0,10285_0,10286_0,10287_0,10288_0,10289_0,10290_0,10291_0,10292_0"} sub_total={"10296_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"10284_0"} label={"Drinking Fountains         "} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={25}  />
			<Select className={"question-card"} name={"10285_0"} label={"Stairway"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={26}  />
			<Select className={"question-card"} name={"10286_0"} label={"Elevators, Cabs, Tracks, Panels"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={27}  />
			<Select className={"question-card"} name={"10287_0"} label={"Telephones"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={28}  />
			<Select className={"question-card"} name={"10288_0"} label={"Interior Glass"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={29}  />
			<Select className={"question-card"} name={"10289_0"} label={"High Low Dusting / A/C Vents"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={30}  />
			<Select className={"question-card"} name={"10290_0"} label={"Floors - Sweep, Mop, Buff & Vacuum"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={31}  />
			<Select className={"question-card"} name={"10291_0"} label={"Carpets Vacuumed  / Spot Clean Stains"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={32}  />
			<Select className={"question-card"} name={"10292_0"} label={"Wall Spotting / Corners & Edges"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={33}  />
			<TextInput className={"question-card"} name={"10293_0"} label={"COMMENTS"} excel_reference={34} fail_comment={true}  />
			<div className={"question-card"} name={"10294_0"} excel_reference={35}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"10295_0"} excel_reference={36}  />
			<TextInput className={"question-card"} name={"10296_0"} label={"Total Rating"} excel_reference={37} excel={'Q25+Q26+Q27+Q28+Q29+Q30+Q31+Q32+Q33'}  />
		</Accordion>,
		<Accordion index={10297} header={<div className={"question-card"} name={"10297_0"} excel_reference={38} anchor={true} anchor_text={"OFFICES / CONFERENCE ROOMS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"OFFICES / CONFERENCE ROOMS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"10298_0,10299_0,10300_0,10301_0,10302_0,10303_0,10304_0,10305_0,10306_0,10307_0,10308_0"} sub_total={"10312_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"10298_0"} label={"Trash Removed & Liner Replaced"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={39}  />
			<Select className={"question-card"} name={"10299_0"} label={"Carpets Vacuumed  / Spot Clean Stains"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={40}  />
			<Select className={"question-card"} name={"10300_0"} label={"Floors - Sweep, Mop, Buff & Vacuum"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={41}  />
			<Select className={"question-card"} name={"10301_0"} label={"Vacuum / Sweep Corners, Edges, & Under Furniture"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={42}  />
			<Select className={"question-card"} name={"10302_0"} label={"Interior Glass"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={43}  />
			<Select className={"question-card"} name={"10303_0"} label={"Telephones"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={44}  />
			<Select className={"question-card"} name={"10304_0"} label={"High Low Dusting"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={45}  />
			<Select className={"question-card"} name={"10305_0"} label={"Blinds / Window Sills Dusted"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={46}  />
			<Select className={"question-card"} name={"10306_0"} label={"Furniture, File Cabinets, Counter Tops, Etc."} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={47}  />
			<Select className={"question-card"} name={"10307_0"} label={"Wall Spotting (Finger Prints, Smudges, Etc.)"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={48}  />
			<Select className={"question-card"} name={"10308_0"} label={"A/C Vents & Return Air Vents"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={49}  />
			<TextInput className={"question-card"} name={"10309_0"} label={"COMMENTS"} excel_reference={50} fail_comment={true}  />
			<div className={"question-card"} name={"10310_0"} excel_reference={51}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"10311_0"} excel_reference={52}  />
			<TextInput className={"question-card"} name={"10312_0"} label={"Total Rating"} excel_reference={53} excel={'Q39+Q40+Q41+Q42+Q43+Q44+Q45+Q46+Q47+Q48+Q49'}  />
		</Accordion>,
		<Accordion index={10313} header={<div className={"question-card"} name={"10313_0"} excel_reference={54} anchor={true} anchor_text={"REST ROOMS / LOCKER ROOMS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"REST ROOMS / LOCKER ROOMS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"10314_0,10315_0,10316_0,10317_0,10318_0,10319_0,10320_0,10321_0,10322_0,10323_0"} sub_total={"10327_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"10314_0"} label={"Trash Removed & Liner Replaced"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={55}  />
			<Select className={"question-card"} name={"10315_0"} label={"Clean & Polish Stainless Steel Fixtures"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={56}  />
			<Select className={"question-card"} name={"10316_0"} label={"Replenish Tissue, Towels, & Soap Dispensers"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={57}  />
			<Select className={"question-card"} name={"10317_0"} label={"Mirrors & Frames"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={58}  />
			<Select className={"question-card"} name={"10318_0"} label={"High & Low Dusting"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={59}  />
			<Select className={"question-card"} name={"10319_0"} label={"Sinks, Faucets, & Counter Tops"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={60}  />
			<Select className={"question-card"} name={"10320_0"} label={"Floors Swept, Mopped"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={61}  />
			<Select className={"question-card"} name={"10321_0"} label={"Toilets / Urinals - Bowls, Seats, Rim, Plumbing Fixtures"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={62}  />
			<Select className={"question-card"} name={"10322_0"} label={"Partition Walls, Doors, Etc."} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={63}  />
			<Select className={"question-card"} name={"10323_0"} label={"A/C Vents & Return Air Vents"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={64}  />
			<TextInput className={"question-card"} name={"10324_0"} label={"COMMENTS"} excel_reference={65} fail_comment={true}  />
			<div className={"question-card"} name={"10325_0"} excel_reference={66}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"10326_0"} excel_reference={67}  />
			<TextInput className={"question-card"} name={"10327_0"} label={"Total Rating "} excel_reference={68} excel={'Q55+Q56+Q57+Q58+Q59+Q60+Q61+Q62+Q63+Q64'}  />
		</Accordion>,
		<Accordion index={10328} header={<div className={"question-card"} name={"10328_0"} excel_reference={69} anchor={true} anchor_text={" BREAK ROOMS / CAFETERIA"} style={{fontSize: '24px'}}  >
				<Paragraph text={" BREAK ROOMS / CAFETERIA"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"10329_0,10330_0,10331_0,10332_0,10333_0,10334_0,10335_0"} sub_total={"10339_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"10329_0"} label={"Floors Swept, Mopped, Vacuum & Buff"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={70}  />
			<Select className={"question-card"} name={"10330_0"} label={"High & Low Dusting"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={71}  />
			<Select className={"question-card"} name={"10331_0"} label={"Tables / Chairs"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={72}  />
			<Select className={"question-card"} name={"10332_0"} label={"A/C Vents & Return Air Vents"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={73}  />
			<Select className={"question-card"} name={"10333_0"} label={"Trash  removed & liners replaced"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={74}  />
			<Select className={"question-card"} name={"10334_0"} label={"Counter Tops, Sinks, & Back Splash Areas"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={75}  />
			<Select className={"question-card"} name={"10335_0"} label={"Wall Spotting (Finger Prints, Smudges)"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={76}  />
			<TextInput className={"question-card"} name={"10336_0"} label={"COMMENTS"} excel_reference={77} fail_comment={true}  />
			<div className={"question-card"} name={"10337_0"} excel_reference={78}  >
				<Paragraph text={"Take a Photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"10338_0"} excel_reference={79}  />
			<TextInput className={"question-card"} name={"10339_0"} label={"Total Rating"} excel_reference={80} excel={'Q70+Q71+Q72+Q73+Q74+Q75+Q76'}  />
		</Accordion>,
		<Accordion index={10340} header={<div className={"question-card"} name={"10340_0"} excel_reference={81} anchor={true} anchor_text={"JANITOR CLOSET / STORAGE AREA"} style={{fontSize: '24px'}}  >
				<Paragraph text={"JANITOR CLOSET / STORAGE AREA"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"10341_0,10342_0,10343_0,10750_0,11536_0,11537_0"} sub_total={"10347_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"10341_0"} label={"Mop Sink & Floors"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={82}  />
			<Select className={"question-card"} name={"10342_0"} label={"Supplies/Equipment (Stored neat & orderly)"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={83}  />
			<Select className={"question-card"} name={"10343_0"} label={"SDS binders in closet and chemicals labeled"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={84}  />
			<Select className={"question-card"} name={"10750_0"} label={"All chemicals in the closet are approved"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={85}  />
			<Select className={"question-card"} name={"11536_0"} label={"Have all checklists been completed? "} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={86}  />
			<Select className={"question-card"} name={"11537_0"} label={"Have all employees completed the sign in/out sheet for their shift?  "} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={87}  />
			<TextInput className={"question-card"} name={"10344_0"} label={"COMMENTS"} excel_reference={88} fail_comment={true}  />
			<div className={"question-card"} name={"10345_0"} excel_reference={89}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"10346_0"} excel_reference={90}  />
			<TextInput className={"question-card"} name={"10347_0"} label={"Rating Total"} excel_reference={91} excel={'Q82+Q83+Q84+Q85+Q86+Q87'}  />
		</Accordion>,
		<Accordion index={10350} header={<div className={"question-card"} name={"10350_0"} excel_reference={94} anchor={true} anchor_text={"CAN WE PROVIDE ADDITIONAL SERV"} style={{fontSize: '24px'}}  >
				<Paragraph text={"CAN WE PROVIDE ADDITIONAL SERVICES (TAGS)?  What extra services can we suggest to make the buildings look better?"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"10351_0,10352_0,10353_0,10354_0,10355_0,10356_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"10351_0"} label={"Do any areas need pressure washing? "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={95}  />
			<Select className={"question-card"} name={"10352_0"} label={"Do we need to shampoo carpet before periodics are due?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={96}  />
			<Select className={"question-card"} name={"10353_0"} label={"Do we need to strip & wax floor before periodics are due?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={97}  />
			<Select className={"question-card"} name={"10354_0"} label={"Do we need to provide any exterior window cleaning?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={98}  />
			<Select className={"question-card"} name={"10355_0"} label={"Can we provide a maintenance technician?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={99}  />
			<TextInput className={"question-card"} name={"10356_0"} label={"If the answer to a question above is yes please comment below"} excel_reference={100}  />
		</Accordion>,
		<TextInput className={"question-card"} name={"10348_0"} label={"Grand Total"} excel_reference={92} anchor={true} anchor_text={"Grand Total"} excel={'Q23+Q37+Q53+Q68+Q80+Q91'}  />,
		<TextInput className={"question-card"} name={"10349_0"} label={"Percentage"} excel_reference={93} excel={'(Q92/54)*100'}  />,
    ];

    return elements;
}

export default get_form_elements;
