import React, { Component } from "react";
import {
    Wrapper,
    Container,
    Header,
    Div,
    Paragraph,
    FormWithChildren,
    DateTimePicker,
    TextInput,
    Select,
    TextArea,
    UserSearchInput,
    SignatureInput,
    ImageInput,
    Accordion,
} from "library";

function get_form_elements() {
    var elements = [
        <Accordion
            index={9872}
            header={
                <div className={"question-card"} name={"9872_0"} excel_reference={1} style={{ fontSize: "24px" }}>
                    <Paragraph
                        text={"QUALITY CONTROL INSPECTION"}
                        style={{ margin: "0px", fontSize: "inherit", marginRight: "200px" }}
                    />
                </div>
            }
            names={"9873_0,9876_0,9877_0,9878_0"}
            sub_total={""}
            className={"col-12"}
            style={{ padding: "0px" }}
        >
            <Select
                className={"question-card"}
                name={"9873_0"}
                label={"Select Position Below if QC Form is Completed by a Supervisor or Manager"}
                options={[
                    { text: "Supervisor", value: "Supervisor" },
                    { text: "Area Supervisor", value: "Area Supervisor" },
                    { text: "Dir/Mgr of Operations", value: "Dir/Mgr of Operations" },
                    { text: "Vice President", value: "Vice President" },
                ]}
                radio={true}
                excel_reference={2}
            />
            <TextInput
                className={"question-card"}
                name={"9876_0"}
                label={"Area:"}
                excel_reference={5}
                required={true}
            />
            <TextInput
                className={"question-card"}
                name={"9877_0"}
                label={"Completed by:"}
                excel_reference={6}
                required={true}
            />
            <TextInput
                className={"question-card"}
                name={"9878_0"}
                label={"Supervisor"}
                excel_reference={7}
                required={true}
            />
        </Accordion>,
        <Accordion
            index={9879}
            header={
                <div
                    className={"question-card"}
                    name={"9879_0"}
                    excel_reference={8}
                    anchor={true}
                    anchor_text={"MAIN ENTRANCE / LOBBY / ELEVAT"}
                    style={{ fontSize: "24px" }}
                >
                    <Paragraph
                        text={"MAIN ENTRANCE / LOBBY / ELEVATORS"}
                        style={{ margin: "0px", fontSize: "inherit", marginRight: "200px" }}
                    />
                </div>
            }
            names={"9880_0,9881_0,9882_0,9883_0,9884_0,9885_0,9886_0,9887_0,9888_0,9889_0,9890_0"}
            sub_total={"9894_0"}
            className={"col-12"}
            style={{ padding: "0px" }}
        >
            <Select
                className={"question-card"}
                name={"9880_0"}
                label={"Door Glass / Side Glass - Main entrance"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={9}
            />
            <Select
                className={"question-card"}
                name={"9881_0"}
                label={"Walk Off Mats / Corners & Edges"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={10}
            />
            <Select
                className={"question-card"}
                name={"9882_0"}
                label={"Carpets Vacuumed  / Spot Clean Stains"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={11}
            />
            <Select
                className={"question-card"}
                name={"9883_0"}
                label={"Floors - Sweep, Mop, Buff & Vacuum"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={12}
            />
            <Select
                className={"question-card"}
                name={"9884_0"}
                label={"Main Elevator Cabs - Interior & Exterior Directional Plates, Tracks"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={13}
            />
            <Select
                className={"question-card"}
                name={"9885_0"}
                label={"Escalators & Landings"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={14}
            />
            <Select
                className={"question-card"}
                name={"9886_0"}
                label={"Ice Machine"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={15}
            />
            <Select
                className={"question-card"}
                name={"9887_0"}
                label={"High Low Dusting / A/C Vents"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={16}
            />
            <Select
                className={"question-card"}
                name={"9888_0"}
                label={"Trash Containers / Ash Urns"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={17}
            />
            <Select
                className={"question-card"}
                name={"9889_0"}
                label={"Lobby Furniture & Fixtures"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={18}
            />
            <Select
                className={"question-card"}
                name={"9890_0"}
                label={"Building Exterior (police area)"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={19}
            />
            <TextInput
                className={"question-card"}
                name={"9891_0"}
                label={"COMMENTS "}
                excel_reference={20}
                fail_comment={true}
            />
            <div className={"question-card"} name={"9892_0"} excel_reference={21}>
                <Paragraph text={"Take a photo"} style={{ margin: "0px", fontSize: "inherit" }} />
            </div>
            <ImageInput className={"question-card"} name={"9893_0"} excel_reference={22} />
            <TextInput
                className={"question-card"}
                name={"9894_0"}
                label={"Total Rating"}
                excel_reference={23}
                excel={"+Q9+Q10+Q11+Q12+Q13+Q14+Q15+Q16+Q17+Q18+Q19"}
            />
        </Accordion>,
        <Accordion
            index={9895}
            header={
                <div
                    className={"question-card"}
                    name={"9895_0"}
                    excel_reference={24}
                    anchor={true}
                    anchor_text={"COMMON AREAS"}
                    style={{ fontSize: "24px" }}
                >
                    <Paragraph
                        text={"COMMON AREAS"}
                        style={{ margin: "0px", fontSize: "inherit", marginRight: "200px" }}
                    />
                </div>
            }
            names={"9896_0,9897_0,9898_0,9899_0,9900_0,9901_0,9902_0,9903_0,9904_0"}
            sub_total={"9908_0"}
            className={"col-12"}
            style={{ padding: "0px" }}
        >
            <Select
                className={"question-card"}
                name={"9896_0"}
                label={"Drinking Fountains         "}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={25}
            />
            <Select
                className={"question-card"}
                name={"9897_0"}
                label={"Stairway"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={26}
            />
            <Select
                className={"question-card"}
                name={"9898_0"}
                label={"Elevators, Cabs, Tracks, Panels"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={27}
            />
            <Select
                className={"question-card"}
                name={"9899_0"}
                label={"Telephones"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={28}
            />
            <Select
                className={"question-card"}
                name={"9900_0"}
                label={"Interior Glass"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={29}
            />
            <Select
                className={"question-card"}
                name={"9901_0"}
                label={"High Low Dusting / A/C Vents"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={30}
            />
            <Select
                className={"question-card"}
                name={"9902_0"}
                label={"Floors - Sweep, Mop, Buff & Vacuum"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={31}
            />
            <Select
                className={"question-card"}
                name={"9903_0"}
                label={"Carpets Vacuumed  / Spot Clean Stains"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={32}
            />
            <Select
                className={"question-card"}
                name={"9904_0"}
                label={"Wall Spotting / Corners & Edges"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={33}
            />
            <TextInput
                className={"question-card"}
                name={"9905_0"}
                label={"COMMENTS"}
                excel_reference={34}
                fail_comment={true}
            />
            <div className={"question-card"} name={"9906_0"} excel_reference={35}>
                <Paragraph text={"Take a photo"} style={{ margin: "0px", fontSize: "inherit" }} />
            </div>
            <ImageInput className={"question-card"} name={"9907_0"} excel_reference={36} />
            <TextInput
                className={"question-card"}
                name={"9908_0"}
                label={"Total Rating"}
                excel_reference={37}
                excel={"Q25+Q26+Q27+Q28+Q29+Q30+Q31+Q32+Q33"}
            />
        </Accordion>,
        <Accordion
            index={9909}
            header={
                <div
                    className={"question-card"}
                    name={"9909_0"}
                    excel_reference={38}
                    anchor={true}
                    anchor_text={"OFFICES / CONFERENCE ROOMS"}
                    style={{ fontSize: "24px" }}
                >
                    <Paragraph
                        text={"OFFICES / CONFERENCE ROOMS"}
                        style={{ margin: "0px", fontSize: "inherit", marginRight: "200px" }}
                    />
                </div>
            }
            names={"9910_0,9911_0,9912_0,9913_0,9914_0,9915_0,9916_0,9917_0,9918_0,9919_0,9920_0"}
            sub_total={"9924_0"}
            className={"col-12"}
            style={{ padding: "0px" }}
        >
            <Select
                className={"question-card"}
                name={"9910_0"}
                label={"Trash Removed & Liner Replaced"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={39}
            />
            <Select
                className={"question-card"}
                name={"9911_0"}
                label={"Carpets Vacuumed  / Spot Clean Stains"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={40}
            />
            <Select
                className={"question-card"}
                name={"9912_0"}
                label={"Floors - Sweep, Mop, Buff & Vacuum"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={41}
            />
            <Select
                className={"question-card"}
                name={"9913_0"}
                label={"Vacuum / Sweep Corners, Edges, & Under Furniture"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={42}
            />
            <Select
                className={"question-card"}
                name={"9914_0"}
                label={"Interior Glass"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={43}
            />
            <Select
                className={"question-card"}
                name={"9915_0"}
                label={"Telephones"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={44}
            />
            <Select
                className={"question-card"}
                name={"9916_0"}
                label={"High Low Dusting"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={45}
            />
            <Select
                className={"question-card"}
                name={"9917_0"}
                label={"Blinds / Window Sills Dusted"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={46}
            />
            <Select
                className={"question-card"}
                name={"9918_0"}
                label={"Furniture, File Cabinets, Counter Tops, Etc."}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={47}
            />
            <Select
                className={"question-card"}
                name={"9919_0"}
                label={"Wall Spotting (Finger Prints, Smudges, Etc.)"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={48}
            />
            <Select
                className={"question-card"}
                name={"9920_0"}
                label={"A/C Vents & Return Air Vents"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={49}
            />
            <TextInput
                className={"question-card"}
                name={"9921_0"}
                label={"COMMENTS"}
                excel_reference={50}
                fail_comment={true}
            />
            <div className={"question-card"} name={"9922_0"} excel_reference={51}>
                <Paragraph text={"Take a photo"} style={{ margin: "0px", fontSize: "inherit" }} />
            </div>
            <ImageInput className={"question-card"} name={"9923_0"} excel_reference={52} />
            <TextInput
                className={"question-card"}
                name={"9924_0"}
                label={"Total Rating"}
                excel_reference={53}
                excel={"Q39+Q40+Q41+Q42+Q43+Q44+Q45+Q46+Q47+Q48+Q49"}
            />
        </Accordion>,
        <Accordion
            index={9925}
            header={
                <div
                    className={"question-card"}
                    name={"9925_0"}
                    excel_reference={54}
                    anchor={true}
                    anchor_text={"REST ROOMS / LOCKER ROOMS"}
                    style={{ fontSize: "24px" }}
                >
                    <Paragraph
                        text={"REST ROOMS / LOCKER ROOMS"}
                        style={{ margin: "0px", fontSize: "inherit", marginRight: "200px" }}
                    />
                </div>
            }
            names={"9926_0,9927_0,9928_0,9929_0,9930_0,9931_0,9932_0,9933_0,9934_0,9935_0"}
            sub_total={"9939_0"}
            className={"col-12"}
            style={{ padding: "0px" }}
        >
            <Select
                className={"question-card"}
                name={"9926_0"}
                label={"Trash Removed & Liner Replaced"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={55}
            />
            <Select
                className={"question-card"}
                name={"9927_0"}
                label={"Clean & Polish Stainless Steel Fixtures"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={56}
            />
            <Select
                className={"question-card"}
                name={"9928_0"}
                label={"Replenish Tissue, Towels, & Soap Dispensers"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={57}
            />
            <Select
                className={"question-card"}
                name={"9929_0"}
                label={"Mirrors & Frames"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={58}
            />
            <Select
                className={"question-card"}
                name={"9930_0"}
                label={"High & Low Dusting"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={59}
            />
            <Select
                className={"question-card"}
                name={"9931_0"}
                label={"Sinks, Faucets, & Counter Tops"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={60}
            />
            <Select
                className={"question-card"}
                name={"9932_0"}
                label={"Floors Swept, Mopped"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={61}
            />
            <Select
                className={"question-card"}
                name={"9933_0"}
                label={"Toilets / Urinals - Bowls, Seats, Rim, Plumbing Fixtures"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={62}
            />
            <Select
                className={"question-card"}
                name={"9934_0"}
                label={"Partition Walls, Doors, Etc."}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={63}
            />
            <Select
                className={"question-card"}
                name={"9935_0"}
                label={"A/C Vents & Return Air Vents"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={64}
            />
            <TextInput
                className={"question-card"}
                name={"9936_0"}
                label={"COMMENTS"}
                excel_reference={65}
                fail_comment={true}
            />
            <div className={"question-card"} name={"9937_0"} excel_reference={66}>
                <Paragraph text={"Take a photo"} style={{ margin: "0px", fontSize: "inherit" }} />
            </div>
            <ImageInput className={"question-card"} name={"9938_0"} excel_reference={67} />
            <TextInput
                className={"question-card"}
                name={"9939_0"}
                label={"Total Rating "}
                excel_reference={68}
                excel={"Q55+Q56+Q57+Q58+Q59+Q60+Q61+Q62+Q63+Q64"}
            />
        </Accordion>,
        <Accordion
            index={9940}
            header={
                <div
                    className={"question-card"}
                    name={"9940_0"}
                    excel_reference={69}
                    anchor={true}
                    anchor_text={" BREAK ROOMS / CAFETERIA"}
                    style={{ fontSize: "24px" }}
                >
                    <Paragraph
                        text={" BREAK ROOMS / CAFETERIA"}
                        style={{ margin: "0px", fontSize: "inherit", marginRight: "200px" }}
                    />
                </div>
            }
            names={"9941_0,9942_0,9943_0,9944_0,9945_0,9946_0,9947_0"}
            sub_total={"9951_0"}
            className={"col-12"}
            style={{ padding: "0px" }}
        >
            <Select
                className={"question-card"}
                name={"9941_0"}
                label={"Floors Swept, Mopped, Vacuum & Buff"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={70}
            />
            <Select
                className={"question-card"}
                name={"9942_0"}
                label={"High & Low Dusting"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={71}
            />
            <Select
                className={"question-card"}
                name={"9943_0"}
                label={"Tables / Chairs"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={72}
            />
            <Select
                className={"question-card"}
                name={"9944_0"}
                label={"A/C Vents & Return Air Vents"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={73}
            />
            <Select
                className={"question-card"}
                name={"9945_0"}
                label={"Trash  removed & liners replaced"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={74}
            />
            <Select
                className={"question-card"}
                name={"9946_0"}
                label={"Counter Tops, Sinks, & Back Splash Areas"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={75}
            />
            <Select
                className={"question-card"}
                name={"9947_0"}
                label={"Wall Spotting (Finger Prints, Smudges)"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={76}
            />
            <TextInput
                className={"question-card"}
                name={"9948_0"}
                label={"COMMENTS"}
                excel_reference={77}
                fail_comment={true}
            />
            <div className={"question-card"} name={"9949_0"} excel_reference={78}>
                <Paragraph text={"Take a Photo"} style={{ margin: "0px", fontSize: "inherit" }} />
            </div>
            <ImageInput className={"question-card"} name={"9950_0"} excel_reference={79} />
            <TextInput
                className={"question-card"}
                name={"9951_0"}
                label={"Total Rating"}
                excel_reference={80}
                excel={"Q70+Q71+Q72+Q73+Q74+Q75+Q76"}
            />
        </Accordion>,
        <Accordion
            index={9952}
            header={
                <div
                    className={"question-card"}
                    name={"9952_0"}
                    excel_reference={81}
                    anchor={true}
                    anchor_text={"JANITOR CLOSET / STORAGE AREA"}
                    style={{ fontSize: "24px" }}
                >
                    <Paragraph
                        text={"JANITOR CLOSET / STORAGE AREA"}
                        style={{ margin: "0px", fontSize: "inherit", marginRight: "200px" }}
                    />
                </div>
            }
            names={"9953_0,9954_0,9955_0,10753_0,11542_0,11543_0"}
            sub_total={"9959_0"}
            className={"col-12"}
            style={{ padding: "0px" }}
        >
            <Select
                className={"question-card"}
                name={"9953_0"}
                label={"Mop Sink & Floors"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={82}
            />
            <Select
                className={"question-card"}
                name={"9954_0"}
                label={"Supplies/Equipment (Stored neat & orderly)"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={83}
            />
            <Select
                className={"question-card"}
                name={"9955_0"}
                label={"SDS binders in closet and chemicals labeled"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={84}
            />
            <Select
                className={"question-card"}
                name={"10753_0"}
                label={"All chemicals in the closet are approved"}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={85}
            />
            <Select
                className={"question-card"}
                name={"11542_0"}
                label={"Have all checklists been completed? "}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={86}
            />
            <Select
                className={"question-card"}
                name={"11543_0"}
                label={"Have all employees completed the sign in/out sheet for their shift?  "}
                options={[
                    { text: "Pass", value: 1 },
                    { text: "Fail", value: 0 },
                ]}
                radio={true}
                excel_reference={87}
            />
            <TextInput
                className={"question-card"}
                name={"9956_0"}
                label={"COMMENTS"}
                excel_reference={88}
                fail_comment={true}
            />
            <div className={"question-card"} name={"9957_0"} excel_reference={89}>
                <Paragraph text={"Take a photo"} style={{ margin: "0px", fontSize: "inherit" }} />
            </div>
            <ImageInput className={"question-card"} name={"9958_0"} excel_reference={90} />
            <TextInput
                className={"question-card"}
                name={"9959_0"}
                label={"Rating Total"}
                excel_reference={91}
                excel={"Q82+Q83+Q84+Q85+Q86+Q87"}
            />
        </Accordion>,
        <Accordion
            index={9962}
            header={
                <div
                    className={"question-card"}
                    name={"9962_0"}
                    excel_reference={94}
                    anchor={true}
                    anchor_text={"CAN WE PROVIDE ADDITIONAL SERV"}
                    style={{ fontSize: "24px" }}
                >
                    <Paragraph
                        text={
                            "CAN WE PROVIDE ADDITIONAL SERVICES (TAGS)?  What extra services can we suggest to make the buildings look better?"
                        }
                        style={{ margin: "0px", fontSize: "inherit", marginRight: "200px" }}
                    />
                </div>
            }
            names={"9963_0,9964_0,9965_0,9966_0,9967_0,9968_0"}
            sub_total={""}
            className={"col-12"}
            style={{ padding: "0px" }}
        >
            <Select
                className={"question-card"}
                name={"9963_0"}
                label={"Do any areas need pressure washing? "}
                options={[
                    { text: "Yes", value: "Yes" },
                    { text: "No", value: "No" },
                ]}
                radio={true}
                excel_reference={95}
            />
            <Select
                className={"question-card"}
                name={"9964_0"}
                label={"Do we need to shampoo carpet before periodics are due?"}
                options={[
                    { text: "Yes", value: "Yes" },
                    { text: "No", value: "No" },
                ]}
                radio={true}
                excel_reference={96}
            />
            <Select
                className={"question-card"}
                name={"9965_0"}
                label={"Do we need to strip & wax floor before periodics are due?"}
                options={[
                    { text: "Yes", value: "Yes" },
                    { text: "No", value: "No" },
                ]}
                radio={true}
                excel_reference={97}
            />
            <Select
                className={"question-card"}
                name={"9966_0"}
                label={"Do we need to provide any exterior window cleaning?"}
                options={[
                    { text: "Yes", value: "Yes" },
                    { text: "No", value: "No" },
                ]}
                radio={true}
                excel_reference={98}
            />
            <Select
                className={"question-card"}
                name={"9967_0"}
                label={"Can we provide a maintenance technician?"}
                options={[
                    { text: "Yes", value: "Yes" },
                    { text: "No", value: "No" },
                ]}
                radio={true}
                excel_reference={99}
            />
            <TextInput
                className={"question-card"}
                name={"9968_0"}
                label={"If the answer to a question above is yes please comment below"}
                excel_reference={100}
            />
        </Accordion>,
        <TextInput
            className={"question-card"}
            name={"9960_0"}
            label={"Grand Total"}
            excel_reference={92}
            anchor={true}
            anchor_text={"Grand Total"}
            excel={"Q23+Q37+Q53+Q68+Q80+Q91"}
        />,
        <TextInput
            className={"question-card"}
            name={"9961_0"}
            label={"Percentage"}
            excel_reference={93}
            excel={"(Q92/54)*100"}
        />,
    ];

    return elements;
}

export default get_form_elements;
