import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {ajaxWrapper} from 'functions';
import {Button, TextInput, Json_Input, CSSInput, Select} from 'library';


class SelectAutocomplete extends Component {
    static component_name = 'SelectAutocomplete';
    constructor(props) {
        super(props);
        this.state = {
            text_value: '',
            options:[],
            show_choices:false,
            show_input:false,
            focused: false,
            key_position: -1
        }

        this.config = {
            form_components: [
                <TextInput label={'name'} name={'name'} default={'Default Text'} />,
                <TextInput label={'defaultoption'} name={'defaultoption'} />,
                <TextInput label={'label'} name={'label'} />,
                <TextInput label={'class'} name={'className'} />,
                <Select label={'required'} name={'required'} options={[{'text':'True', value:true}, {'text':'False', value:false}]} defaultoption={false} />,
                <Select label={'Select'} name={'select'} boolean={true} defaultoption={true} />,

                <Json_Input label={'options'} name={'options'} />,
                <TextInput label={'Options URL'} name={'optionsUrl'} />,
                <Json_Input label={'Options URL Map'} name={'optionsUrlMap'} />,

                <CSSInput label={'css'} name={'style'} default={{}} />,
            ],
        }

        this.refreshData = this.refreshData.bind(this);
        this.optionsCallback = this.optionsCallback.bind(this);
        this.full_input_container = React.createRef();
        this.text_input = React.createRef();
        this.check_for_tab = this.check_for_tab.bind(this);
        this.check_for_blur = this.check_for_blur.bind(this);
        this.handle_text_change = this.handle_text_change.bind(this);
    }

    componentDidMount() {
        this.refreshData();

        document.addEventListener('mousedown', this.check_for_blur, false);
        document.addEventListener('keydown', this.check_for_tab, false);
    }
    componentWillUnmount(){
        document.removeEventListener('mousedown', this.check_for_blur, false);
        document.removeEventListener('keydown', this.check_for_tab, false);
    }
    componentDidUpdate(oldProps){
        if (!this.props.select && this.state.text_value != this.props.value){
            this.setState({text_value: this.props.value});
        }
    }


    refreshData() {
        if (this.props.optionsUrl && this.props.optionsUrl != '') {
            //Subscribe to all variables in optionsUrl
            window.cmState['subscribe'](this, this.props.optionsUrl);

            var options_url = resolveVariables({'options_url':this.props.optionsUrl}, window.cmState.getGlobalState())['options_url']
            console.log("Options URL Select", options_url, this.props.optionsUrl);
            ajaxWrapper("GET", options_url, {}, this.optionsCallback);
        }
    }

    optionsCallback(value) {
        console.log("Options Callback", value);
        var options = [];
        for (var index in value) {
            var textValue = value[index];
            var valueValue = value[index];

            if (this.props.optionsUrlMap) {
                var resolvedValue = resolveVariables(this.props.optionsUrlMap, textValue);
                textValue = resolvedValue['text']
                valueValue = resolvedValue['value']
            }
            options.push({'text':textValue,'value':valueValue});

        }
        console.log("Options",options);
        this.setState({options:options});
    }


    check_for_blur(e) {
        if (this.full_input_container.current.contains(e.target)){
            return;
        }

        if (this.state.show_choices || this.state.show_input){
            this.setState({show_choices:false, show_input: false});
        }

        if (this.props.blur){
            this.props.blur(e);
        }
    }

    check_for_tab(e) {
        if (e.key === "Tab" && this.full_input_container.current.contains(document.activeElement)) {
            if (this.state.show_choices || this.state.show_input){
                this.setState({show_choices:false, show_input: false});
            }

            if (this.props.tab){
                this.props.tab(e);
            }
        }
    }

    handle_text_change(e) {
        var value = e.target.value.toLowerCase();

        var starts_with_filtered_options = [];
        var filtered_options = [];
        var options = null;
        if ('options' in this.props){
            options = this.props.options;
        }
        else if (this.state.options.length > 0){
            options = this.state.options;
        }

        if (options && value != ""){
            for (var i in options){
                var option = options[i].text.toLowerCase();
                if (option.startsWith(value)){
                    starts_with_filtered_options.push(options[i]);
                }
                else if (option.indexOf(value) > -1){
                    filtered_options.push(options[i]);
                }
            }
        }

        var options = starts_with_filtered_options.concat(filtered_options);

        var newState = {};
        newState[this.props.name] = e.target.value;
        if (this.props.every_key){
            this.props.setFormState(newState);
        }

        this.setState({
            filtered_options: options,
            text_value: e.target.value,
            show_choices: true,
            key_position: -1
        });
    }

    handle_keydown = (e) => {
        if (this.state.show_choices) {
            var key_position = this.state.key_position;
            // arrow up/down button should select next/previous list element
            if (e.keyCode === 38 && key_position > -1) {
                this.setState({key_position: key_position - 1});
            }
            else if (e.keyCode === 40) {
                this.setState({key_position: key_position + 1});
            }
            else if ((e.keyCode === 13 || e.keyCode === 9) && key_position > -1) {
                var new_state = {}
                var value = this.state.filtered_options[key_position].value;
                this.submit_select_change(value);
            }
        }
    }

    click_handler = (e) => {
        var selection = e.target.getAttribute('num');
        this.submit_select_change(selection);
    }

    select_click_handler = (e) => {
        e.preventDefault();
        this.text_input.current.focus();

        this.setState({show_choices:true, show_input:true});
    }

    select_blur = (e) => {
        this.setState({show_input:false});
    }

    clear_input = () => {
        this.setState({
            show_choices:false,
            show_input:false,
            text_value:'',
            key_position: -1
        });
    }

    submit_select_change = (selection) => {
        var newState = {};
        newState[this.props.name] = selection;
        this.props.setFormState(newState);

        if (this.props.select){
            this.text_input.current.blur();
            this.clear_input();
        }
    }

    render() {
        var options = null;
        if ('options' in this.props){
            options = this.props.options;
        }
        else if (this.state.options.length > 0){
            options = this.state.options;
        }

        var autocomplete_style = {
            border: "thin solid #bbb",
            position: "absolute",
            top: "60px",
            background: "white",
            left: "4px",
            borderRadius: "4px",
            boxShadow: "2px 2px 5px rgba(0,0,0,.2)",
            zIndex: '100',
            maxHeight: '500px',
            overflowY: 'auto',
            overflowX: 'hidden',
        }
        var button_style = {
            width: '100%',
            borderRadius: '0px',
            textAlign: 'left',
            padding: '2px 8px',
            fontSize: '14px',
        }

        var layout = '';
        if (this.props.layout) {
            layout = this.props.layout;
        }

        var select_value = this.props.value;
        if (select_value == '') {
          select_value = this.props.defaultoption;
        }

        var select_options = [];
        var autocomplete_options = [];

        for (var i in this.state.filtered_options){
            var option = this.state.filtered_options[i];

            var type="light";
            if (i == this.state.key_position){
                type += ' active';
            }

            autocomplete_options.push(
                <Button onClick={this.click_handler} type={type} text={option.text} num={option.value} style={button_style} />
            );
        }


        select_options.push(<option key={-1} value={undefined}></option>);
        for (var index in options) {
            if (options[index]['value'] != this.props.defaultoption) {
                select_options.push(
                    <option key={index} value={options[index]['value']}>
                        {options[index]['text']}
                    </option>
                );
            }
        }

        var select_style = {display: 'none', width:'100%'};
        var text_style = {width:'100%'};
        if (!this.state.show_choices){
            autocomplete_style['display'] = 'none';
        }
        if (!this.state.show_input && this.props.select){
            select_style['display']='block';
            text_style['height'] = '0px';
            text_style['width'] = '0px';
            text_style['padding'] = '0px';
            text_style['border'] = 'none';
        }

        var select = null;
        if (this.props.select){
            select = <select className="form-control" name={this.props.name} value={select_value}
                onClick={this.select_click_handler} onFocus={this.select_click_handler}
                style={select_style}>
                {select_options}
            </select>
        }

        return (
            <div className={"form-group " + this.props.className + ' ' + this.props.layout}
                ref={this.full_input_container} style={{position: 'relative'}}>

                <label style={{display:'block'}}>{this.props.label}</label>

                {select}

                <input type="text" className="form-control" name={this.props.name} style={text_style}
                    onChange={this.handle_text_change} onBlur={this.select_blur} value={this.state.text_value}
                    placeholder={this.props.placeholder} autoComplete="off" ref={this.text_input}
                    onKeyDown={this.handle_keydown}
                />
                <div style={autocomplete_style}>
                    {autocomplete_options}
                </div>

            </div>
        )
    }

}

export default SelectAutocomplete;
