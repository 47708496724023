import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';

import {Wrapper, LogInForm, Header, Div, Container, Span, Link, Image, Paragraph} from 'library';
import Navbar from 'projectLibrary/navbar.js';
import Footer from 'projectLibrary/footer.js';


class LandingPage extends Component {

    render() {

        return (<div>
				<Navbar />

                <div style={{backgroundImage:"url('/static/images/landing/man-coffee-cup-pen.jpg')", backgroundSize:'cover'}} >
                    <div style={{background: 'rgba(112, 125, 141, .9)'}} >
                    <Container>
                        <div className="row white_highlight" style={{padding: '80px 0px'}} >
                            <div className="col-md-6 col-sm-12">
                                <img style={{width:'100%'}} src="/static/images/landing/device.png" />
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <h1 style={{fontSize: '54px', fontWeight: '900'}} >Why we exist</h1>
                                <h2>We help companies effectively<br/> execute their workflows</h2>

                                <br/><br/>
                                <a href="/demo" className="btn btn-primary">START DEMO</a>
                            </div>
                        </div>
                    </Container>
                    </div>
                </div>

                <div className="big-padding-top key_points" style={{background:'#f8f8fa', textAlign:'center'}} >
                    <Container><div className='row' >
                        <div className="col-12">
                            <h3 style={{color: '#666', fontWeight: 'bold', paddingBottom:'30px'}} >The 4 Keys to Effectively Executing Workflows</h3>
                        </div>

                        <div className="col-md-3 col-sm-6">
                            <img style={{width:'100%'}} src="/static/images/landing/keys_1.png" />
                            <h4>Provide a Clear Roadmap</h4>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <img style={{width:'100%'}} src="/static/images/landing/keys_2.png" />
                            <h4>Timely Notification to Complete Next Step</h4>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <img style={{width:'100%'}} src="/static/images/landing/keys_3.png" />
                            <h4>Make Everyone Accountable</h4>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <img style={{width:'100%'}} src="/static/images/landing/keys_4.png" />
                            <h4>Keep Information Current</h4>
                        </div>
                    </div></Container>
                </div>

                <div className="big-padding-top" >
                    <Container><div className='row' >
                        <div className="col-md-6 col-sm-12">
                            <h3 style={{fontWeight: '700'}} >Dimension 5 Combines these 4 Keys into One Simple to Use Solution</h3>
                            <p style={{fontSize: '.9em'}} >We convert paper forms, checklists, training materials and standard operating procedures
                            commonly used to execute workflows into an interactive digital application allowing companies to
                            easily implement each of the four keys into their existing workflows.</p>
                            <p style={{fontSize: '.9em'}} >Your workflows will be converted within 24 hours at a fraction of your current costs.
                                Changes to any existing workflow is also made within 24 hours to help you keep up with
                                current conditions and updates.</p>

                            <img className="stretch-img" src="/static/images/landing/all_keys.png" />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <img style={{width:'100%', paddingTop:'30px'}} src="/static/images/landing/device2.png" />
                        </div>
                    </div></Container>
                </div>

                <div className="big-padding-top">
                    <Container><div className='row' >
                        <div className="col-sm-12"><h3 style={{fontWeight:'bold'}} >What You Get</h3></div>
                        <div className="row padding-bot">
                            <div className="col-md-8 col-sm-12">
                                <h4>Knowledge</h4>
                                <p>Knowledge is power. But in order to create knowledge, data must be captured, aggregated,
                                normalized and presented. Dimension 5 comes standard with a comprehensive reporting set allowing
                                for customized reports to gain invaluable insights into each of your workflows.</p>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <img style={{width:'100%'}} src="/static/images/landing/get1.png" />
                            </div>
                        </div>
                        <div className="row padding-bot">
                            <div className="col-md-8 col-sm-12">
                                <h4>Easy to Complete Digital Forms</h4>
                                <p>Dimension 5 reconfigures your paper forms, checklists, training material, ect., so they work in a
                                digital world. You receive an interactive, intuitive and easy to follow roadmap that is your process
                                workflow. Many customers have used our application to simply, improve and even define thier workflows.</p>
                                <p>Dimension 5 can be used on any digital device including smart phones, tablets, desktop computers, etc.
                                    All you need is an internet connection.</p>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <img style={{width:'100%'}} src="/static/images/landing/get2.png" />
                            </div>
                        </div>
                        <div className="row padding-bot">
                            <div className="col-md-8 col-sm-12">
                                <h4>Two-Way Communication Platform</h4>
                                <p>Allows management to quickly see what has not been completed, review the information captured,
                                    and know how long it took to complete each specific task. The process executor has the ability
                                to provide meaningful comments back.</p>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <img style={{width:'100%'}} src="/static/images/landing/get3.png" />
                            </div>
                        </div>
                        <div className="row padding-bot">
                            <div className="col-md-8 col-sm-12">
                                <h4>Integration</h4>
                                <p>Easily integrates into your current system using our simple to implement API that can send and
                                receive data calls directly.</p>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <img style={{width:'100%'}} src="/static/images/landing/get4.png" />
                            </div>
                        </div>
                    </div></Container>
                </div>

                <div style={{backgroundImage:"url('/static/images/landing/man-coffee-cup-pen.jpg')", backgroundSize:'cover', textAlign:'center', marginTop:'60px'}} >
                    <div style={{background:'rgba(219, 36, 64, .9)'}} >
                    <Container><div className='row' >
                        <div className="col-12 white_highlight" style={{padding: '80px 0px'}} >
                            <h1 style={{fontSize: '44px', fontWeight: '900'}} >Create Your Accout Now!</h1>
                            <h2>Get a 30 day free trial. No credit card required.</h2>

                            <br/><br/>
                            <a href="/demo" className="btn btn-default">START DEMO</a>
                        </div>
                    </div></Container>
                    </div>
                </div>

				<Footer />
        </div>);
    }
}

export default LandingPage;
