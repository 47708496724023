import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';

import {Wrapper, Container, Header, FormWithChildren, PasswordInput, If, Div, Image, Span, Link, Paragraph} from 'library';
import Navbar from 'projectLibrary/navbar.js';
import Footer from 'projectLibrary/footer.js';


class PasswordReset extends Component {

    render() {

        return (<div>
			<Navbar />

			<Container order={"1"} style={{'padding-top': '20px'}} min_height={true} >
				<Header text={"Reset Your Password"}  />
				<FormWithChildren submitUrl={resolveVariables({"text":"/api/user/user/{params.1}/"}, window.cmState.getGlobalState(this))["text"]} redirectUrl={"/login/"} >
					<PasswordInput name={"password"} required={true} confirm_password={true}  />
				</FormWithChildren>
			</Container>

			<Footer />
        </div>);
    }
}
export default PasswordReset;
