import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {TextInput, CSSInput} from 'library';
import Moment from 'react-moment';

class Date extends React.Component {
    static component_name = 'Date';

    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'text'} name={'text'} default='' />,
                <CSSInput label={'css'} name={'style'} default={{}} />,
                <TextInput label={'class'} name={'className'} />,
            ],
        }
    }

    render() {
        var type = 'primary';
        if (this.props.type) {
            type = this.props.type;
        }

        var style = {maxHeight: '400px', overflowY:'auto', overflowX:'hidden'};
        if (this.props.style){
            style = Object.assign({}, style, this.props.style);
        }

        return (
            <div style={style} className={this.props.className} ><Moment format="LLLL">
                {this.props.text}
            </Moment></div>
        );
    }
}

export default Date;
