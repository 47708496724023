import React, { Component } from 'react';
import {Image} from 'library';

class Wrapper extends Component {
    static component_name = 'Wrapper';
    render() {
        var loading = <div style={{'textAlign':'center'}}> <Image style={{'width':'100px'}} src={'/static/images/loading.gif'} /></div>;

        var saving = <div style={{
            textAlign:'center', position:'absolute', zIndex:'1000', top:'0px',
            width:'100%', height:'100%', background: 'rgba(0,0,0,.4)',
            padding: '10%'
        }}>
            <h2 style={{color:'white'}}>Saving... Please do not turn off console</h2>
            <Image style={{'width':'100px'}} src={'/static/images/loading.gif'} />
            <br/><br/>
            {this.props.saving_content}
        </div>;

        var css = {'paddingLeft':'0px','paddingRight':'0px'};

        return (
                <div className={"container-fluid " + this.props.className} style={css}>
                        {(this.props.loaded) ? this.props.content : loading}
                        {(this.props.saving) ? saving: null}
                </div>
        );
    }
}

export default Wrapper;
