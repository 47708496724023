import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {If, Alert, TextInput, Button, EmptyModal, FormWithChildren, Header, Select, CSSInput} from 'library';

class Json_Input extends Component {
    static component_name = 'Json_Input';
    constructor(props) {
      super(props);
      this.state = {'error': null, current_val:'', rows: []}

      this.config = {
          form_components: [
              <TextInput label={'name'} name={'name'} default={'Default Text'} />,
              <TextInput label={'default'} name={'default'} />,
              <TextInput label={'placeholder'} name={'placeholder'} />,
              <TextInput label={'label'} name={'label'} />,
              <Select label={'required'} name={'required'} boolean={true} />,
              <TextInput label={'class'} name={'className'} />,
              <CSSInput label={'css'} name={'style'} default={{}} />,
              <CSSInput label={'label css'} name={'label_style'} default={{}} />,
          ],
      }

      this.handle_change = this.handle_change.bind(this);
      this.show_modal = this.show_modal.bind(this);
      this.hide_modal = this.hide_modal.bind(this);
      this.add_row = this.add_row.bind(this);
      this.delete_row = this.delete_row.bind(this);
      this.build_json = this.build_json.bind(this);
      this.set_json_state = this.set_json_state.bind(this);
    }

    handle_change = (e) => {
       var name = e.target.getAttribute("name");
       var newState = {};
       var value = e.target.value;
       console.log("Value", value);

       if (value == ''){
           newState[name] = value;
           this.setState({error: null}, this.props.setFormState(newState));
       }
       else {
           try {
             newState[name] = JSON.parse(value);
             this.setState({error: null}, this.props.setFormState(newState));
           }
           catch(err) {
             this.setState({error: err.toString(), current_val: value});
           }
       }
    }

    show_modal() {
        var value = this.props.value;
        if (!(value) || typeof(value) == 'undefined'){
            value = this.props.default;
        }

        if (typeof(value) == 'string') {
            try {
                value = JSON.parse(value);
            }
            catch {
                value = {}
            }
        }

        var rows = [];
        for (var index in value) {
            var temp_dict = {key: index, value: value[index]}
            rows.push(temp_dict);
        }

        if (rows.length == 0) {
            rows = [{key:'', value:''}]
        }
        this.setState({modal: true, rows:rows})
    }

    hide_modal() {
        this.setState({modal: false})
    }

    add_row() {
        var rows = this.state.rows;
        rows.push({key:'', value:''})
        this.setState({rows: rows})
    }

    delete_row() {
        var rows = this.state.rows;
        rows.pop();
        this.setState({rows:rows})
    }

    build_json() {
        var new_json = {};
        for (var index in this.state.rows) {
            var row = this.state.rows[index];
            if (row['key'] != '') {
                new_json[row['key']] = row['value']
            }
        }
        console.log("New Json", new_json)

        var newState = {};
        newState[this.props.name] = new_json

        this.setState({error: null, modal: false}, this.props.setFormState(newState));
    }

    set_json_state(name, state) {
        console.log("Json State", state)
        var rows = this.state.rows;
        for (var key in state) {
            if (key.startsWith('key_')) {
                var row = parseInt(key.substring(4, key.length))
                rows[row]['key'] = state[key]
            }
            else if (key.startsWith('value_')) {
                var row = parseInt(key.substring(6, key.length))
                rows[row]['value'] = state[key]
            }
        }
        console.log("Set Json", rows)
        this.setState({rows:rows})
    }


    render() {
        var value = this.props.value;
        if (!(value) || typeof(value) == 'undefined'){
            value = this.props.default;
        }

        var rows = [<Header size={3} className="col-6" text="Key" />,<Header size={3} className="col-6" text="Value" />];
        for (var index in this.state.rows) {
            var row = this.state.rows[index];
            rows.push(<TextInput className="col-6" name={"key_" + index} default={row['key']} />)
            rows.push(<TextInput className="col-6" name={"value_" + index} default={row['value']} />)
        }

        var layout = '';
        if (this.props.className) {
            layout = this.props.className;
        }

        var label = null;
        if (this.props.label && this.props.label != ''){
            label = <label>{this.props.label}</label>;
        }

        if (typeof(value) == 'string') {
            var json = value;
        }
        else {
            var json = JSON.stringify(value);
        }

        var remove_row = null;
        if (this.state.rows.length > 0) {
            remove_row = <Button type={'danger'} text={'Delete Row'} className="col-4"  onClick={this.delete_row} />
        }

        return (
              <div className={"form-group " + layout}>
                {label}
                <textarea className="form-control" name={this.props.name}
                    rows={this.props.rows}
                    onChange={this.handle_change}
                    onBlur={this.props.onBlur}
                    value={json}
                    placeholder={this.props.placeholder} >
                </textarea>
                <Button text='Json Builder' onClick={this.show_modal} type='primary' />
                <If logic={this.state.error}>
                  <Alert text={this.state.error} type={'danger'} />
                </If>

                <EmptyModal onHide={this.hide_modal} show={this.state.modal}>
                    <div className="container" style={{padding:'10px'}}>
                        <Header text='Build Your Json' />
                        <FormWithChildren row={true} className="row"
                            autoSetGlobalState={true} setGlobalState={this.set_json_state} globalStateName={'json'}
                            dont_resolve_anything={this.props.dont_resolve_anything} >
                            {rows}
                        </FormWithChildren>
                        <div className="row">
                            <Button type={'success'} text={'Add Row'} className="col-4" onClick={this.add_row} />
                            {remove_row}
                            <Button type={'primary'} text={'Generate JSON'} className="col-4" onClick={this.build_json} />
                        </div>
                    </div>
                </EmptyModal>
              </div>
        )


    }
}

export default Json_Input;
