import React, { Component } from 'react';
import {Wrapper, Container, Header, Div, Paragraph, FormWithChildren, DateTimePicker, TextInput, Select, TextArea, UserSearchInput, SignatureInput, ImageInput, Accordion} from 'library';

function get_form_elements(){
    var elements = [
		<Accordion index={9436} header={<div className={"question-card"} name={"9436_0"} excel_reference={1} style={{fontSize: '24px'}}  >
				<Paragraph text={"QUALITY CONTROL INSPECTION"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"9437_0,9440_0,9441_0,9442_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"9437_0"} label={"Select Position Below if QC Form is Completed by a Supervisor or Manager"} options={[{"text": "Supervisor", "value": "Supervisor"}, {"text": "Area Supervisor", "value": "Area Supervisor"}, {"text": "Dir/Mgr of Operations", "value": "Dir/Mgr of Operations"}, {"text": "Vice President", "value": "Vice President"}]} radio={true} excel_reference={2}  />
			<TextInput className={"question-card"} name={"9440_0"} label={"Area:"} excel_reference={5} required={true}  />
			<TextInput className={"question-card"} name={"9441_0"} label={"Completed by:"} excel_reference={6} required={true}  />
			<TextInput className={"question-card"} name={"9442_0"} label={"Supervisor"} excel_reference={7} required={true}  />
		</Accordion>,
		<Accordion index={9443} header={<div className={"question-card"} name={"9443_0"} excel_reference={8} anchor={true} anchor_text={"MAIN ENTRANCE / LOBBY / ELEVAT"} style={{fontSize: '24px'}}  >
				<Paragraph text={"MAIN ENTRANCE / LOBBY / ELEVATORS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"9444_0,9445_0,9448_0,9447_0,9449_0,9450_0,9451_0,9452_0,9453_0,9454_0,9455_0"} sub_total={"9459_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"9444_0"} label={"Door Glass / Side Glass - Main entrance"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={9}  />
			<Select className={"question-card"} name={"9445_0"} label={"Walk Off Mats / Corners & Edges"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={10}  />
			<Select className={"question-card"} name={"9448_0"} label={"Carpets Vacuumed  / Spot Clean Stains"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={11}  />
			<Select className={"question-card"} name={"9447_0"} label={"Floors - Sweep, Mop, Buff & Vacuum"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={12}  />
			<Select className={"question-card"} name={"9449_0"} label={"Main Elevator Cabs - Interior & Exterior Directional Plates, Tracks"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={13}  />
			<Select className={"question-card"} name={"9450_0"} label={"Escalators & Landings"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={14}  />
			<Select className={"question-card"} name={"9451_0"} label={"Ice Machine"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={15}  />
			<Select className={"question-card"} name={"9452_0"} label={"High Low Dusting / A/C Vents"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={16}  />
			<Select className={"question-card"} name={"9453_0"} label={"Trash Containers / Ash Urns"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={17}  />
			<Select className={"question-card"} name={"9454_0"} label={"Lobby Furniture & Fixtures"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={18}  />
			<Select className={"question-card"} name={"9455_0"} label={"Building Exterior (police area)"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={19}  />
			<TextInput className={"question-card"} name={"9456_0"} label={"COMMENTS "} excel_reference={20} fail_comment={true}  />
			<div className={"question-card"} name={"9457_0"} excel_reference={21}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"9458_0"} excel_reference={22}  />
			<TextInput className={"question-card"} name={"9459_0"} label={"Total Rating"} excel_reference={23} excel={'+Q9+Q10+Q11+Q12+Q13+Q14+Q15+Q16+Q17+Q18+Q19'}  />
		</Accordion>,
		<Accordion index={9460} header={<div className={"question-card"} name={"9460_0"} excel_reference={24} anchor={true} anchor_text={"COMMON AREAS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"COMMON AREAS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"9461_0,9462_0,9463_0,9464_0,9465_0,9466_0,9467_0,9468_0,9469_0"} sub_total={"9473_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"9461_0"} label={"Drinking Fountains         "} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={25}  />
			<Select className={"question-card"} name={"9462_0"} label={"Stairway"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={26}  />
			<Select className={"question-card"} name={"9463_0"} label={"Elevators, Cabs, Tracks, Panels"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={27}  />
			<Select className={"question-card"} name={"9464_0"} label={"Telephones"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={28}  />
			<Select className={"question-card"} name={"9465_0"} label={"Interior Glass"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={29}  />
			<Select className={"question-card"} name={"9466_0"} label={"High Low Dusting / A/C Vents"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={30}  />
			<Select className={"question-card"} name={"9467_0"} label={"Floors - Sweep, Mop, Buff & Vacuum"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={31}  />
			<Select className={"question-card"} name={"9468_0"} label={"Carpets Vacuumed  / Spot Clean Stains"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={32}  />
			<Select className={"question-card"} name={"9469_0"} label={"Wall Spotting / Corners & Edges"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={33}  />
			<TextInput className={"question-card"} name={"9470_0"} label={"COMMENTS"} excel_reference={34} fail_comment={true}  />
			<div className={"question-card"} name={"9471_0"} excel_reference={35}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"9472_0"} excel_reference={36}  />
			<TextInput className={"question-card"} name={"9473_0"} label={"Total Rating"} excel_reference={37} excel={'Q25+Q26+Q27+Q28+Q29+Q30+Q31+Q32+Q33'}  />
		</Accordion>,
		<Accordion index={9474} header={<div className={"question-card"} name={"9474_0"} excel_reference={38} anchor={true} anchor_text={"OFFICES / CONFERENCE ROOMS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"OFFICES / CONFERENCE ROOMS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"9475_0,9476_0,9477_0,9478_0,9479_0,9480_0,9481_0,9482_0,9483_0,9484_0,9485_0"} sub_total={"9489_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"9475_0"} label={"Trash Removed & Liner Replaced"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={39}  />
			<Select className={"question-card"} name={"9476_0"} label={"Carpets Vacuumed  / Spot Clean Stains"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={40}  />
			<Select className={"question-card"} name={"9477_0"} label={"Floors - Sweep, Mop, Buff & Vacuum"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={41}  />
			<Select className={"question-card"} name={"9478_0"} label={"Vacuum / Sweep Corners, Edges, & Under Furniture"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={42}  />
			<Select className={"question-card"} name={"9479_0"} label={"Interior Glass"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={43}  />
			<Select className={"question-card"} name={"9480_0"} label={"Telephones"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={44}  />
			<Select className={"question-card"} name={"9481_0"} label={"High Low Dusting"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={45}  />
			<Select className={"question-card"} name={"9482_0"} label={"Blinds / Window Sills Dusted"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={46}  />
			<Select className={"question-card"} name={"9483_0"} label={"Furniture, File Cabinets, Counter Tops, Etc."} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={47}  />
			<Select className={"question-card"} name={"9484_0"} label={"Wall Spotting (Finger Prints, Smudges, Etc.)"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={48}  />
			<Select className={"question-card"} name={"9485_0"} label={"A/C Vents & Return Air Vents"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={49}  />
			<TextInput className={"question-card"} name={"9486_0"} label={"COMMENTS"} excel_reference={50} fail_comment={true}  />
			<div className={"question-card"} name={"9487_0"} excel_reference={51}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"9488_0"} excel_reference={52}  />
			<TextInput className={"question-card"} name={"9489_0"} label={"Total Rating"} excel_reference={53} excel={'Q39+Q40+Q41+Q42+Q43+Q44+Q45+Q46+Q47+Q48+Q49'}  />
		</Accordion>,
		<Accordion index={9490} header={<div className={"question-card"} name={"9490_0"} excel_reference={54} anchor={true} anchor_text={"REST ROOMS / LOCKER ROOMS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"REST ROOMS / LOCKER ROOMS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"9491_0,9492_0,9493_0,9494_0,9495_0,9496_0,9497_0,9498_0,9499_0,9500_0"} sub_total={"9504_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"9491_0"} label={"Trash Removed & Liner Replaced"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={55}  />
			<Select className={"question-card"} name={"9492_0"} label={"Clean & Polish Stainless Steel Fixtures"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={56}  />
			<Select className={"question-card"} name={"9493_0"} label={"Replenish Tissue, Towels, & Soap Dispensers"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={57}  />
			<Select className={"question-card"} name={"9494_0"} label={"Mirrors & Frames"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={58}  />
			<Select className={"question-card"} name={"9495_0"} label={"High & Low Dusting"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={59}  />
			<Select className={"question-card"} name={"9496_0"} label={"Sinks, Faucets, & Counter Tops"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={60}  />
			<Select className={"question-card"} name={"9497_0"} label={"Floors Swept, Mopped"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={61}  />
			<Select className={"question-card"} name={"9498_0"} label={"Toilets / Urinals - Bowls, Seats, Rim, Plumbing Fixtures"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={62}  />
			<Select className={"question-card"} name={"9499_0"} label={"Partition Walls, Doors, Etc."} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={63}  />
			<Select className={"question-card"} name={"9500_0"} label={"A/C Vents & Return Air Vents"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={64}  />
			<TextInput className={"question-card"} name={"9501_0"} label={"COMMENTS"} excel_reference={65} fail_comment={true}  />
			<div className={"question-card"} name={"9502_0"} excel_reference={66}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"9503_0"} excel_reference={67}  />
			<TextInput className={"question-card"} name={"9504_0"} label={"Total Rating "} excel_reference={68} excel={'Q55+Q56+Q57+Q58+Q59+Q60+Q61+Q62+Q63+Q64'}  />
		</Accordion>,
		<Accordion index={9505} header={<div className={"question-card"} name={"9505_0"} excel_reference={69} anchor={true} anchor_text={" BREAK ROOMS / CAFETERIA"} style={{fontSize: '24px'}}  >
				<Paragraph text={" BREAK ROOMS / CAFETERIA"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"9506_0,9507_0,9508_0,9509_0,9510_0,9511_0,9512_0"} sub_total={"9516_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"9506_0"} label={"Floors Swept, Mopped, Vacuum & Buff"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={70}  />
			<Select className={"question-card"} name={"9507_0"} label={"High & Low Dusting"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={71}  />
			<Select className={"question-card"} name={"9508_0"} label={"Tables / Chairs"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={72}  />
			<Select className={"question-card"} name={"9509_0"} label={"A/C Vents & Return Air Vents"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={73}  />
			<Select className={"question-card"} name={"9510_0"} label={"Trash  removed & liners replaced"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={74}  />
			<Select className={"question-card"} name={"9511_0"} label={"Counter Tops, Sinks, & Back Splash Areas"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={75}  />
			<Select className={"question-card"} name={"9512_0"} label={"Wall Spotting (Finger Prints, Smudges)"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={76}  />
			<TextInput className={"question-card"} name={"9513_0"} label={"COMMENTS"} excel_reference={77} fail_comment={true}  />
			<div className={"question-card"} name={"9514_0"} excel_reference={78}  >
				<Paragraph text={"Take a Photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"9515_0"} excel_reference={79}  />
			<TextInput className={"question-card"} name={"9516_0"} label={"Total Rating"} excel_reference={80} excel={'Q70+Q71+Q72+Q73+Q74+Q75+Q76'}  />
		</Accordion>,
		<Accordion index={9517} header={<div className={"question-card"} name={"9517_0"} excel_reference={81} anchor={true} anchor_text={"JANITOR CLOSET / STORAGE AREA"} style={{fontSize: '24px'}}  >
				<Paragraph text={"JANITOR CLOSET / STORAGE AREA"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"9518_0,9519_0,9534_0,10745_0,11518_0,11519_0"} sub_total={"9523_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"9518_0"} label={"Mop Sink & Floors"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={82}  />
			<Select className={"question-card"} name={"9519_0"} label={"Supplies/Equipment (Stored neat & orderly)"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={83}  />
			<Select className={"question-card"} name={"9534_0"} label={"SDS binders in closet and chemicals labeled"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={84}  />
			<Select className={"question-card"} name={"10745_0"} label={"All chemicals in the closet are approved"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={85}  />
			<Select className={"question-card"} name={"11518_0"} label={"Have all checklists been completed? "} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={86}  />
			<Select className={"question-card"} name={"11519_0"} label={"Have all employees completed the sign in/out sheet for their shift?  "} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={87}  />
			<TextInput className={"question-card"} name={"9520_0"} label={"COMMENTS"} excel_reference={88} fail_comment={true}  />
			<div className={"question-card"} name={"9521_0"} excel_reference={89}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"9522_0"} excel_reference={90}  />
			<TextInput className={"question-card"} name={"9523_0"} label={"Rating Total"} excel_reference={91} excel={'Q82+Q83+Q84+Q85+Q86+Q87'}  />
		</Accordion>,
		<Accordion index={9527} header={<div className={"question-card"} name={"9527_0"} excel_reference={94} anchor={true} anchor_text={"CAN WE PROVIDE ADDITIONAL SERV"} style={{fontSize: '24px'}}  >
				<Paragraph text={"CAN WE PROVIDE ADDITIONAL SERVICES (TAGS)?  What extra services can we suggest to make the buildings look better?"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"9528_0,9529_0,9530_0,9531_0,9532_0,9533_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"9528_0"} label={"Do any areas need pressure washing? "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={95}  />
			<Select className={"question-card"} name={"9529_0"} label={"Do we need to shampoo carpet before periodics are due?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={96}  />
			<Select className={"question-card"} name={"9530_0"} label={"Do we need to strip & wax floor before periodics are due?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={97}  />
			<Select className={"question-card"} name={"9531_0"} label={"Do we need to provide any exterior window cleaning?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={98}  />
			<Select className={"question-card"} name={"9532_0"} label={"Can we provide a maintenance technician?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={99}  />
			<TextInput className={"question-card"} name={"9533_0"} label={"If the answer to a question above is yes please comment below"} excel_reference={100}  />
		</Accordion>,
		<TextInput className={"question-card"} name={"9525_0"} label={"Grand Total"} excel_reference={92} anchor={true} anchor_text={"Grand Total"} excel={'Q23+Q37+Q53+Q68+Q80+Q91'}  />,
		<TextInput className={"question-card"} name={"9526_0"} label={"Percentage"} excel_reference={93} excel={'(Q92/54)*100'}  />,
    ];

    return elements;
}

export default get_form_elements;
