import {ajaxWrapper} from 'functions';



function save_form_submission(id, url, data){
    var submissions = localStorage.getItem('pending_submissions');
    if (submissions){ submissions = JSON.parse(submissions);}
    else {submissions = {};}

    if (submissions.length || submissions.length == 0){ submissions = {}; }

    submissions[id] = {
        url: url,
        data: data
    };

    localStorage.setItem('pending_submissions', JSON.stringify(submissions));
}

export default save_form_submission;
