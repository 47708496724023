

function run_excel_function(excel_function, updated_state, current_state, lookups){
    var total_excel_function = '';

    for (var i=0; i<excel_function.length; i++){
        if (excel_function[i] == '('){
            var new_excel_function = excel_function.slice(i+1);
            var depth = 0;
            var end = 0;
            for (var j=0; j<new_excel_function.length; j++){
                if (new_excel_function[j] == '('){
                    depth += 1;}
                else if (new_excel_function[j] == ')'){
                    if (depth == 0){
                        end = j;
                    } else {
                        depth -= 1;}
                }
            }
            if (end != 0){
                var value = run_excel_function(new_excel_function.slice(0,end), updated_state, current_state, lookups);
                i += end;
                total_excel_function += value;
            }
        } else if (excel_function[i] == 'Q'){
            //console.log("Add 1")
            var num = '';
            while (!isNaN(parseInt(excel_function[i+1]))){
                num += excel_function[i+1];
                i += 1;
            }

            var name = lookups[num];
            var value = current_state[name];
            if (name in updated_state){
                value = updated_state[name];
            }

            if (value == 'NA') {
                value = 5;
            }
            //console.log("Value", value);
            if (isNaN(parseFloat(value))){value=0;}
            //console.log("Adding To Total 1", value)
            total_excel_function += parseFloat(value);

        } else if ('+-/*'.indexOf(excel_function[i]) > -1){
            total_excel_function += excel_function[i];
        } else if ('()'.indexOf(excel_function[i]) == -1){
            total_excel_function += excel_function[i];
        }
    }

    var final_value = eval(total_excel_function);
    var rounded_value = Math.round((final_value + Number.EPSILON) * 100) / 100;

    return rounded_value;
}

export default run_excel_function;
