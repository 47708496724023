import format_date from './format_date.js';

function format_date_string(date_string, date_format, timezone) {
  var date = new Date(Date.parse(date_string));
  //console.log("Format Date Data", date_string, date_format, date);

  if (!(timezone) || typeof(timezone) == 'undefined'){
      timezone = "America/New_York";
  }

  if (date == 'Invalid Date') {
      return '';
  }
  else {
      return format_date(date, date_format, timezone);
  }
}

export default format_date_string;
