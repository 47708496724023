import React, { Component } from 'react';
import {Wrapper, Container, Header, Div, Paragraph, FormWithChildren, DateTimePicker, TextInput, Select, TextArea, UserSearchInput, SignatureInput, ImageInput, Accordion} from 'library';

function get_form_elements(){
    var elements = [
		<Accordion index={6309} header={<div className={"question-card"} name={"6309_0"} excel_reference={1} style={{fontSize: '24px'}}  >
				<Paragraph text={"QUALITY CONTROL INSPECTION"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"6310_0,6313_0,6314_0,6315_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"6310_0"} label={"Select Position Below if QC Form is Completed by a Supervisor or Manager"} options={[{"text": "Supervisor", "value": "Supervisor"}, {"text": "Area Supervisor", "value": "Area Supervisor"}, {"text": "Dir/Mgr of Operations", "value": "Dir/Mgr of Operations"}, {"text": "Vice President", "value": "Vice President"}]} radio={true} excel_reference={2}  />
			<TextInput className={"question-card"} name={"6313_0"} label={"Area:"} excel_reference={5} required={true}  />
			<TextInput className={"question-card"} name={"6314_0"} label={"Completed by:"} excel_reference={6} required={true}  />
			<TextInput className={"question-card"} name={"6315_0"} label={"Supervisor"} excel_reference={7} required={true}  />
			<div className={"question-card"} name={"6316_0"} excel_reference={8}  >
				<Paragraph text={"5 \u2013 Perfect (exceeds contract requirements/no issues)   "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"4 \u2013 Good (meets contract requirements)   "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"3 \u2013 Fair (a couple {1 or 2} issues, Work Order required)  "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"2 \u2013 Poor (multiple {3 to 5} issues, Work Order required)   "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"1 \u2013 Bad (numerous {more than 5} issues, Work Order required)   "} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
		</Accordion>,
		<Accordion index={6317} header={<div className={"question-card"} name={"6317_0"} excel_reference={9} anchor={true} anchor_text={"MAIN ENTRANCE/LOBBY"} style={{fontSize: '24px'}}  >
				<Paragraph text={"MAIN ENTRANCE/LOBBY"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"6318_0,6319_0,6320_0,6321_0"} sub_total={"6326_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"6318_0"} label={"Door Glass / Side Glass - Main entrance"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={10}  />
			<Select className={"question-card"} name={"6319_0"} label={"Floors - Corners &amp; Edges / Walk-Off Mats"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={11}  />
			<Select className={"question-card"} name={"6320_0"} label={"Trash Containers / Ash Urns"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={12}  />
			<Select className={"question-card"} name={"6321_0"} label={"Lobby Furniture &amp; Fixtures"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={13}  />
			<TextInput className={"question-card"} name={"6322_0"} label={"COMMENTS"} excel_reference={14} fail_comment={true}  />
			<div className={"question-card"} name={"6323_0"} excel_reference={15}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"6324_0"} excel_reference={16}  />
			<Select className={"question-card"} name={"6325_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={17}  />
			<TextInput className={"question-card"} name={"6326_0"} label={"Total Rating - Maximum Score 20"} excel_reference={18} excel={'Q10+Q11+Q12+Q13'}  />
		</Accordion>,
		<Accordion index={6327} header={<div className={"question-card"} name={"6327_0"} excel_reference={19} anchor={true} anchor_text={"COMMON AREAS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"COMMON AREAS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"6328_0,6329_0,6330_0,6331_0,6332_0,6333_0,6334_0,6335_0,6336_0,6337_0"} sub_total={"6342_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"6328_0"} label={"Stairway/Ramp; Landings"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={20}  />
			<Select className={"question-card"} name={"6329_0"} label={"Interior/Exterior Glass"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={21}  />
			<Select className={"question-card"} name={"6330_0"} label={"High Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={22}  />
			<Select className={"question-card"} name={"6331_0"} label={"Low Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={23}  />
			<Select className={"question-card"} name={"6332_0"} label={"A/C Vents &amp; Return Air Vents"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={24}  />
			<Select className={"question-card"} name={"6333_0"} label={"Carpets Vacuumed / Spot Clean Stains"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={25}  />
			<Select className={"question-card"} name={"6334_0"} label={"Elevators/Tracks/Walls"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={26}  />
			<Select className={"question-card"} name={"6335_0"} label={"Hard Surface Floors (Swept, Mopped, Buffed)"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={27}  />
			<Select className={"question-card"} name={"6336_0"} label={"Corners, Edges, Baseboards"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={28}  />
			<Select className={"question-card"} name={"6337_0"} label={"Wall Spotting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={29}  />
			<TextInput className={"question-card"} name={"6338_0"} label={"COMMENTS"} excel_reference={30} fail_comment={true}  />
			<div className={"question-card"} name={"6339_0"} excel_reference={31}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"6340_0"} excel_reference={32}  />
			<Select className={"question-card"} name={"6341_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={33}  />
			<TextInput className={"question-card"} name={"6342_0"} label={"Total Rating - Maximum Score 50"} excel_reference={34} excel={'+Q20+Q21+Q22+Q23+Q24+Q25+Q26+Q27+Q28+Q29'}  />
		</Accordion>,
		<Accordion index={6343} header={<div className={"question-card"} name={"6343_0"} excel_reference={35} anchor={true} anchor_text={"OFFICES/ADMIN. AREAS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"OFFICES/ADMIN. AREAS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"6344_0,6345_0,6346_0,6347_0,6348_0,6349_0,6350_0,6351_0"} sub_total={"6356_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"6344_0"} label={"Trash Removed &amp; Liner Replaced"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={36}  />
			<Select className={"question-card"} name={"6345_0"} label={"Carpets Vacuumed &amp; Stains Spot Cleaned"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={37}  />
			<Select className={"question-card"} name={"6346_0"} label={"Vacuum Corners, Edges, &amp; Under Furniture"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={38}  />
			<Select className={"question-card"} name={"6347_0"} label={"Interior Glass"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={39}  />
			<Select className={"question-card"} name={"6348_0"} label={"High Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={40}  />
			<Select className={"question-card"} name={"6349_0"} label={"Low Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={41}  />
			<Select className={"question-card"} name={"6350_0"} label={"Blinds / Window Sills Dusted"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={42}  />
			<Select className={"question-card"} name={"6351_0"} label={"Hard Surface Floors (Swept, Mopped, Buffed)"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={43}  />
			<TextInput className={"question-card"} name={"6352_0"} label={"COMMENTS"} excel_reference={44} fail_comment={true}  />
			<div className={"question-card"} name={"6353_0"} excel_reference={45}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"6354_0"} excel_reference={46}  />
			<Select className={"question-card"} name={"6355_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={47}  />
			<TextInput className={"question-card"} name={"6356_0"} label={"Total Rating - Maximum Score 40"} excel_reference={48} excel={'+Q36+Q37+Q38+Q39+Q40+Q41+Q42+Q43'}  />
		</Accordion>,
		<Accordion index={6357} header={<div className={"question-card"} name={"6357_0"} excel_reference={49} anchor={true} anchor_text={"RESTROOMS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"RESTROOMS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"6358_0,6359_0,6360_0,6361_0,6362_0,6363_0,6364_0,6365_0,6366_0,6367_0,6368_0,6369_0,6370_0"} sub_total={"6389_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"6358_0"} label={"Trash Removed &amp; Liner Replaced"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={50}  />
			<Select className={"question-card"} name={"6359_0"} label={"Clean & Polish Stainless Steel Fixtures"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={51}  />
			<Select className={"question-card"} name={"6360_0"} label={"Replenish Tissue, Towels, &amp; Soap Dispensers"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={52}  />
			<Select className={"question-card"} name={"6361_0"} label={"Mirrors &amp; Frames"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={53}  />
			<Select className={"question-card"} name={"6362_0"} label={"Showers"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={54}  />
			<Select className={"question-card"} name={"6363_0"} label={"High Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={55}  />
			<Select className={"question-card"} name={"6364_0"} label={"Low Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={56}  />
			<Select className={"question-card"} name={"6365_0"} label={"Sinks, Faucets, &amp; Counter Tops"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={57}  />
			<Select className={"question-card"} name={"6366_0"} label={"Floors Swept, Mopped, &amp; Scrubbed"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={58}  />
			<Select className={"question-card"} name={"6367_0"} label={"Toilets - Bowls, Seats, Rim, Plumbing Fixtures"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={59}  />
			<Select className={"question-card"} name={"6368_0"} label={"Urinals - Bowl, Rim, Plumbing Fixtures"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={60}  />
			<Select className={"question-card"} name={"6369_0"} label={"Partition Walls, Doors, Etc."} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={61}  />
			<Select className={"question-card"} name={"6370_0"} label={"A/C Vents &amp; Return Air Vents"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={62}  />
			<TextInput className={"question-card"} name={"6371_0"} label={"COMMENTS"} excel_reference={63} fail_comment={true}  />
			<div className={"question-card"} name={"6372_0"} excel_reference={64}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"6373_0"} excel_reference={65}  />
			<Select className={"question-card"} name={"6374_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={66}  />
			<TextInput className={"question-card"} name={"6375_0"} label={"Total Rating - Maximum Score 65"} excel_reference={67} excel={'+Q50+Q51+Q52+Q53+Q54+Q55+Q56+Q57+Q58+Q59+Q60+Q61+Q62'}  />
			<Select className={"question-card"} name={"6377_0"} label={"Floors Swept, Mopped, Vacuum &amp; Buffed"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={69}  />
			<Select className={"question-card"} name={"6378_0"} label={"High Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={70}  />
			<Select className={"question-card"} name={"6379_0"} label={"Low Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={71}  />
			<Select className={"question-card"} name={"6380_0"} label={"Tables / Chairs"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={72}  />
			<Select className={"question-card"} name={"6381_0"} label={"A/C Vents &amp; Return Air Vents"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={73}  />
			<Select className={"question-card"} name={"6382_0"} label={"Trash Removed &amp; Liner Replaced"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={74}  />
			<Select className={"question-card"} name={"6383_0"} label={"Counter Tops, Sinks, &amp; Back Splash Areas"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={75}  />
			<Select className={"question-card"} name={"6384_0"} label={"Wall Spotting (Finger Prints, Smudges)"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={76}  />
			<TextInput className={"question-card"} name={"6385_0"} label={"COMMENTS"} excel_reference={77} fail_comment={true}  />
			<div className={"question-card"} name={"6386_0"} excel_reference={78}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"6387_0"} excel_reference={79}  />
			<Select className={"question-card"} name={"6388_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={80}  />
			<TextInput className={"question-card"} name={"6389_0"} label={"Total Rating - Maximum Score 40"} excel_reference={81} excel={'+Q69+Q70+Q71+Q72+Q73+Q74+Q75+Q76'}  />
		</Accordion>,
		<Accordion index={6390} header={<div className={"question-card"} name={"6390_0"} excel_reference={82} anchor={true} anchor_text={"CHECKLISTS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"CHECKLISTS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"6391_0"} sub_total={"6396_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"6391_0"} label={"Are the Checklists on Site and being Utilized?"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={83}  />
			<TextInput className={"question-card"} name={"6392_0"} label={"COMMENTS"} excel_reference={84} fail_comment={true}  />
			<div className={"question-card"} name={"6393_0"} excel_reference={85}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"6394_0"} excel_reference={86}  />
			<Select className={"question-card"} name={"6395_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={87}  />
			<TextInput className={"question-card"} name={"6396_0"} label={"Total Rating - Maximum Score 5"} excel_reference={88} excel={'+Q83'}  />
		</Accordion>,
		<Accordion index={6397} header={<div className={"question-card"} name={"6397_0"} excel_reference={89} anchor={true} anchor_text={"JANITOR CLOSET/STORAGE AREA"} style={{fontSize: '24px'}}  >
				<Paragraph text={"JANITOR CLOSET/STORAGE AREA"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"6398_0,6399_0,6400_0"} sub_total={"6405_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"6398_0"} label={"Mop Sink &amp; Floors"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={90}  />
			<Select className={"question-card"} name={"6399_0"} label={"Supplies/Equipment (Stored neat &amp; orderly)"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={91}  />
			<Select className={"question-card"} name={"6400_0"} label={"MSDS Binder up to date"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={92}  />
			<TextInput className={"question-card"} name={"6401_0"} label={"COMMENTS"} excel_reference={93} fail_comment={true}  />
			<div className={"question-card"} name={"6402_0"} excel_reference={94}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"6403_0"} excel_reference={95}  />
			<Select className={"question-card"} name={"6404_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={96}  />
			<TextInput className={"question-card"} name={"6405_0"} label={"Total Rating - Maximum Score - 15"} excel_reference={97} excel={'+Q90+Q91+Q92'}  />
			<Select className={"question-card"} name={"8925_0"} label={"DID YOU REQUEST ANY WORK ORDERS? "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={99} required={true}  />
		</Accordion>,
		<Accordion index={6408} header={<div className={"question-card"} name={"6408_0"} excel_reference={101} anchor={true} anchor_text={"ADDITIONAL SERVICES"} style={{fontSize: '24px'}}  >
				<Paragraph text={"ADDITIONAL SERVICES"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"6410_0,6411_0,6412_0,6413_0,6414_0,6415_0,6416_0,6417_0,6418_0,6419_0,6420_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<div className={"question-card"} name={"6409_0"} excel_reference={102}  >
				<Paragraph text={"Can we provide additional services (TAGs)?  Can we provide additional services (TAGs)?  "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"What extra services can we suggest to make the buildings look better?  "} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<TextInput className={"question-card"} name={"6410_0"} label={"If you had extra time, what cleaning tasks or projects would you do?  "} excel_reference={103}  />
			<Select className={"question-card"} name={"6411_0"} label={"Do any areas need pressure washing?  "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={104}  />
			<TextInput className={"question-card"} name={"6412_0"} label={"If the answer to a question is yes please comment below"} excel_reference={105}  />
			<Select className={"question-card"} name={"6413_0"} label={"Do we need to shampoo carpet before periodics are due?  "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={106}  />
			<TextInput className={"question-card"} name={"6414_0"} label={"If the answer to a question is yes please comment below"} excel_reference={107}  />
			<Select className={"question-card"} name={"6415_0"} label={"Do we need to strip &amp; wax the floors before periodics are due?  "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={108}  />
			<TextInput className={"question-card"} name={"6416_0"} label={"If the answer to a question is yes please comment below"} excel_reference={109}  />
			<Select className={"question-card"} name={"6417_0"} label={"Do we need to provide any exterior window cleaning?  "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={110}  />
			<TextInput className={"question-card"} name={"6418_0"} label={"If the answer to a question is yes please comment below"} excel_reference={111}  />
			<Select className={"question-card"} name={"6419_0"} label={"Can we provide a maintenance technician?  "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={112}  />
			<TextInput className={"question-card"} name={"6420_0"} label={"If the answer to a question is yes please comment below"} excel_reference={113}  />
		</Accordion>,
		<TextInput className={"question-card"} name={"6406_0"} label={"RATING TOTAL"} excel_reference={98} anchor={true} anchor_text={"RATING TOTAL"} excel={'+Q18+Q34+Q48+Q67+Q81+Q88+Q97'}  />,
		<TextInput className={"question-card"} name={"6407_0"} label={"Percentage"} excel_reference={100} excel={'+Q98/235*100'}  />,
    ];

    return elements;
}

export default get_form_elements;
