import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {ajaxWrapper} from 'functions';
import {Button, TextInput, Json_Input, CSSInput, Alert } from 'library';

import {isMobile} from "react-device-detect";

//Example
// var gender =  {'value':'','name':'gender','label':'Gender','options':[{'value':'Pick One','text':'Pick One'}, {'value':'Male', 'text':'Male'}, {'value':'Female','text':'Female'},{'value':'Other','text':"I don't identify as either"}]}

var BOOLEANS = [
    {'text':'Yes', value:true},
    {'text':'No', value:false},
];

class Select extends Component {
    static component_name = 'Select'
    constructor(props) {
        super(props);
        this.state = {options:[]}

        this.config = {
            form_components: [
                <TextInput label={'name'} name={'name'} default={'Default Text'} />,
                <TextInput label={'defaultoption'} name={'defaultoption'} />,
                <TextInput label={'label'} name={'label'} />,
                <Select label={'required'} name={'required'} boolean={true} defaultoption={false} />,
                <Select label={'boolean'} name={'boolean'} boolean={true} defaultoption={false} />,
                <Select label={'Multiple'} name={'multiple'} boolean={true} defaultoption={false} />,
                <Select label={'Radio'} name={'radio'} boolean={true} defaultoption={false} />,
                <Json_Input label={'options'} name={'options'} />,
                <TextInput label={'Options URL'} name={'optionsUrl'} />,
                <Json_Input label={'Options URL Map'} name={'optionsUrlMap'} />,
                <TextInput label={'class'} name={'className'} />,
                <CSSInput label={'css'} name={'style'}/>,
                <CSSInput label={'label css'} name={'label_style'}/>,
            ],
        }

        this.optionsCallback = this.optionsCallback.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.removeSelection = this.removeSelection.bind(this);
        this.refreshData = this.refreshData.bind(this);
        this.check_value_prop = this.check_value_prop.bind(this);
        this.update_options = this.update_options.bind(this);
    }

    componentDidMount() {
        this.update_options();
        this.refreshData();
        this.check_value_prop();
    }

    componentDidUpdate (prevProps, prevState, snapshot){
        if (this.props.optionsUrl != prevProps.optionsUrl){
            this.refreshData();
        }

        if (this.props.options != prevProps.options){
            this.update_options();
        }

        if (this.props.multiple){
            var diff = false;
            if (!(prevProps.value) || this.props.value.length != prevProps.value.length){
                diff = true;
            }
            if (!(diff)){
                for (var i in this.props.value){
                    if (this.props.value[i] != prevProps[i]){
                        diff = true;
                    }
                }
            }

            if (diff){
                this.check_value_prop();
            }
        }
        else if (this.props.value != prevProps.value){
            this.check_value_prop();
        }
    }

    update_options(){
        var optionDict = this.props.options;
        if (this.props.boolean){
            optionDict = BOOLEANS;
        } else if (!this.props.options) {
            optionDict = this.state.options;
        }
        if (optionDict != this.state.options){
            this.setState({options: optionDict});
        }
    }

    check_value_prop(){
        var value = this.props.value;
        var has_variable_inside = String(value).indexOf('{') > -1 && String(value).indexOf('}') > -1;
        var has_list_inside = String(value).indexOf('[') > -1 && String(value).indexOf(']') > -1;

        if (this.props.multiple && typeof(value) == 'string' && (has_variable_inside || has_list_inside)){
            try {
                var newState = {};
                value = JSON.parse(value);

                if (value.length > 0 && typeof(Object.keys(value[0])) != 'string' && this.props.optionsUrlMap) {
                    var new_value = [];
                    for (var index in value){
                        var resolved_value = resolveVariables(this.props.optionsUrlMap, value[index]);
                        new_value.push(resolved_value['value']);
                    }
                    value = new_value;
                }

                newState[this.props.name] = value;
                this.props.setFormState(newState);
            } catch (e) {console.log(e);}
        }
    }

    refreshData() {
        if (this.props.optionsUrl && this.props.optionsUrl != '') {
            //Subscribe to all variables in optionsUrl
            window.cmState['subscribe'](this, this.props.optionsUrl);

            var options_url = resolveVariables({'options_url':this.props.optionsUrl}, window.cmState.getGlobalState())['options_url']
            console.log("Options URL Select", options_url, this.props.optionsUrl)
            ajaxWrapper("GET", options_url, {}, this.optionsCallback.bind(this));
        }
    }

    optionsCallback(value) {
        console.log("Options Callback", value);
        var options = [];
        for (var index in value) {
            var textValue = value[index];
            var valueValue = value[index];

            if (this.props.optionsUrlMap) {
                var resolvedValue = resolveVariables(this.props.optionsUrlMap, textValue);
                textValue = resolvedValue['text']
                valueValue = resolvedValue['value']
            }
            options.push({'text':textValue,'value':valueValue});

        }
        console.log("Options",options);
        this.setState({options:options})
    }

    handleChange = (e) => {
        var selection = e.target.value;
        var newState = {}

        if (this.props.multiple == true) {
            var value = this.props.value;
            var index = value.indexOf(selection);
            if (index == -1) {
                value.push(selection);
            }
            else {
                value.splice(index, 1);
            }
            newState[this.props.name] = value;
        }
        else if (this.props.boolean){
            // If the select is designated as a boolean, we need to convert the value
            if (selection == 'true'){
                newState[this.props.name] = true;
            } else {
                newState[this.props.name] = false;
            }
        }
        else if (this.props.pass_fail){
            // If the select is designated as a boolean, we need to convert the value
            if (selection == 'true'){
                newState[this.props.name] = true;
            } else {
                newState[this.props.name] = false;
            }
        }

        else {
            newState[this.props.name] = selection;
        }

        console.log("New State",newState);
        this.props.setFormState(newState);
    }

    removeSelection(e) {
        var selection = e.currentTarget.getAttribute('num');
        var value = this.props.value;
        var index = value.indexOf(selection);
        value.splice(index,1);

        var newState = {}
        newState[this.props.name] = value;
        this.props.setFormState(newState);
    }

    render() {
        var type = " btn-default";

        var disabled = {};
        if (this.props.disabled){
            disabled = {disabled: 'disabled'};
            type += ' disabled';
        }

        var label = null;
        if (this.props.label) {
            var required = null;
            if (this.props.required){ required = <span style={{float: 'left', fontWeight: 'bold', color: 'red'}} >*</span>;}
            label = <label style={this.props.label_style}>{required}<p>{this.props.label}</p></label>;
        }

        //Check if default value should be used
        if (this.props.multiple == true) {
            var value = this.props.value;
            if (typeof(value) == 'undefined'){
                value = [];
            }

            if (this.props.value) {
                if (value.length == 0) {
                    value = this.props.defaultoption;
                }
            }
        }
        else {
            var value = String(this.props.value);
            if (value == '' || value == 'undefined') {
                value = this.props.defaultoption;
            }
        }

        var optionDict = this.state.options;
        var options = [];

        // Check if default is inside options and add it if not
        var found_default = null;
        var current_default = this.props.defaultoption;
        if (this.props.boolean){
            if (current_default == 'true'){
                current_default = true;
            }
            else {
                current_default = false;
            }
        }

        for (var index in optionDict) {
            if (optionDict[index]['value'] == current_default) {
                found_default = optionDict[index];
            }
        }

        if (this.props.radio){

            var numeric = true;
            // Render Radio Components
            // Create JSX for select options
            for (var index in optionDict) {
                var active = '';
                var current_option = String(optionDict[index]['value']);

                if (this.props.multiple && value.indexOf(current_option) > -1){
                    active = " active";
                }
                else if (value == current_option) {
                    active = " active";
                }

                options.push(<label className={"btn" + active + type} style={this.props.button_style} {...disabled} >
                    <input style={{display:"none"}} type="radio" name={this.props.name} key={this.props.key_context + '_' + index} value={String(optionDict[index]['value'])}
                        onClick={this.handleChange} {...disabled} />{optionDict[index]['text']}
                </label>);

                var current_text = String(optionDict[index]['text']);
                if (isNaN(current_text)){
                    numeric = false;
                }
            }

            var vertical = '';
            if (!(isMobile) && !(this.props.not_vertical) && ((numeric && options.length < 4) || (!(numeric) && options.length < 7))){
                vertical = '-vertical'
            }
            var content = <div className={"radios"}><div className={'btn-group' + vertical} >{options}</div></div>;

        }
        else {
            // Render Select Component
            if (!found_default) {
                options.push(<option key={-1} value={''}>{''}</option>);
            }

            // Create JSX for select options
            for (var index in optionDict) {
                options.push(<option key={index} value={String(optionDict[index]['value'])}>{optionDict[index]['text']}</option>);
            }

            var multipleSelections = []

            if (this.props.multiple == true) {
                var optionsDict = {}
                for (var index in optionDict) {
                    optionsDict[optionDict[index]['value']] = optionDict[index]['text']
                }
                for (var index in value) {
                    if (optionsDict[value[index]]){
                        multipleSelections.push(
                            <Button key={this.props.name + '-' + index} onClick={this.removeSelection} num={value[index]}
                            type={'outline-danger'} text={optionsDict[value[index]]} />);
                    }
                }

                if (multipleSelections.length > 0){
                    multipleSelections.push(<br/>);
                    multipleSelections.push(<br/>);
                }
            }

            var content = <select className="form-control" name={this.props.name} onChange={this.handleChange} value={value} {...disabled} >
                {options}
            </select>;

        }

        var show_required = null;
        if (this.props.show_required){
            show_required = <Alert type='error' text='Required' />;
        }

        return (
            <div className={"form-group " + this.props.className} style={this.props.style} >
                {show_required}
                {label}

                {multipleSelections}

                {content}
                <div style={{width:'100%', clear:'both'}} ></div>
            </div>
        )
    }

}

export default Select;
