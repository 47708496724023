import React, { Component } from 'react';
import {Wrapper, Container, Header, Div, Paragraph, FormWithChildren, DateTimePicker, TextInput, Select, TextArea, UserSearchInput, SignatureInput, ImageInput, Accordion} from 'library';

function get_form_elements(){
    var elements = [
		<Accordion index={8486} header={<div className={"question-card"} name={"8486_0"} excel_reference={1} style={{fontSize: '24px'}}  >
				<Paragraph text={"Quality Control Inspection"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"8487_0,8490_0,8491_0,8492_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"8487_0"} label={"Select Position Below If QC Form is Completed by a Supervisor or Manager"} options={[{"text": "Supervisor", "value": "Supervisor"}, {"text": "Area Supervisor", "value": "Area Supervisor"}, {"text": "Dir/Mgr of Operations", "value": "Dir/Mgr of Operations"}, {"text": "Vice President", "value": "Vice President"}]} radio={true} excel_reference={2}  />
			<TextInput className={"question-card"} name={"8490_0"} label={"Area"} excel_reference={5} required={true}  />
			<TextInput className={"question-card"} name={"8491_0"} label={"Completed by:"} excel_reference={6} required={true}  />
			<TextInput className={"question-card"} name={"8492_0"} label={"Supervisor"} excel_reference={7} required={true}  />
			<div className={"question-card"} name={"8493_0"} excel_reference={8}  >
				<Paragraph text={"5 \u2013 Perfect (exceeds contract requirements/no issues)   "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"4 \u2013 Good (meets contract requirements)   "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"3 \u2013 Fair (a couple {1 or 2} issues, Work Order required)  "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"2 \u2013 Poor (multiple {3 to 5} issues, Work Order required)   "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"1 \u2013 Bad (numerous {more than 5} issues, Work Order required)   "} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
		</Accordion>,
		<Accordion index={8494} header={<div className={"question-card"} name={"8494_0"} excel_reference={9} anchor={true} anchor_text={"MAIN ENTRANCE / LOBBY / ELEVAT"} style={{fontSize: '24px'}}  >
				<Paragraph text={"MAIN ENTRANCE / LOBBY / ELEVATORS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"8495_0,8496_0,8497_0,8498_0,8499_0,8500_0,8501_0,8502_0,8503_0,8504_0,8505_0"} sub_total={"8510_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"8495_0"} label={"Door Glass / Side Glass - Main entrance"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={10}  />
			<Select className={"question-card"} name={"8496_0"} label={"Walk Off Mats / Corners & Edges"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={11}  />
			<Select className={"question-card"} name={"8497_0"} label={"Floors - Sweep, Mop, Buff & Vacuum"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={12}  />
			<Select className={"question-card"} name={"8498_0"} label={"Carpets Vacuumed  / Spot Clean Stains"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={13}  />
			<Select className={"question-card"} name={"8499_0"} label={"Main Elevator Cabs - Interior & Exterior Directional Plates, Tracks"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={14}  />
			<Select className={"question-card"} name={"8500_0"} label={"Escalators & Landings"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={15}  />
			<Select className={"question-card"} name={"8501_0"} label={"Ice Machine"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={16}  />
			<Select className={"question-card"} name={"8502_0"} label={"High Low Dusting / A/C Vents"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={17}  />
			<Select className={"question-card"} name={"8503_0"} label={"Trash Containers / Ash Urns"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={18}  />
			<Select className={"question-card"} name={"8504_0"} label={"Lobby Furniture & Fixtures"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={19}  />
			<Select className={"question-card"} name={"8505_0"} label={"Building Exterior (police area)"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={20}  />
			<TextInput className={"question-card"} name={"8506_0"} label={"COMMENTS"} excel_reference={21} fail_comment={true}  />
			<div className={"question-card"} name={"8507_0"} excel_reference={22}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"8508_0"} excel_reference={23}  />
			<Select className={"question-card"} name={"8509_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={24}  />
			<TextInput className={"question-card"} name={"8510_0"} label={"Total Rating - Maximum Score - 55"} excel_reference={25} excel={'+Q10+Q11+Q12+Q13+Q14+Q15+Q16+Q17+Q18+Q19+Q20'}  />
		</Accordion>,
		<Accordion index={8511} header={<div className={"question-card"} name={"8511_0"} excel_reference={26} anchor={true} anchor_text={"COMMON AREAS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"COMMON AREAS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"8512_0,8513_0,8514_0,8515_0,8516_0,8517_0,8518_0,8519_0,8520_0"} sub_total={"8525_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"8512_0"} label={"Drinking Fountains         "} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={27}  />
			<Select className={"question-card"} name={"8513_0"} label={"Stairway"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={28}  />
			<Select className={"question-card"} name={"8514_0"} label={"Elevators, Cabs, Tracks, Panels"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={29}  />
			<Select className={"question-card"} name={"8515_0"} label={"Telephones"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={30}  />
			<Select className={"question-card"} name={"8516_0"} label={"Interior Glass"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={31}  />
			<Select className={"question-card"} name={"8517_0"} label={"High Low Dusting / A/C Vents"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={32}  />
			<Select className={"question-card"} name={"8518_0"} label={"Floors - Sweep, Mop, Buff & Vacuum"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={33}  />
			<Select className={"question-card"} name={"8519_0"} label={"Carpets Vacuumed  / Spot Clean Stains"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={34}  />
			<Select className={"question-card"} name={"8520_0"} label={"Wall Spotting / Corners & Edges"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={35}  />
			<TextInput className={"question-card"} name={"8521_0"} label={"COMMENTS"} excel_reference={36} fail_comment={true}  />
			<div className={"question-card"} name={"8522_0"} excel_reference={37}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"8523_0"} excel_reference={38}  />
			<Select className={"question-card"} name={"8524_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={39}  />
			<TextInput className={"question-card"} name={"8525_0"} label={"Total Rating - Maximum Score - 45"} excel_reference={40} excel={'+Q27+Q28+Q29+Q30+Q31+Q32+Q33+Q34+Q35'}  />
		</Accordion>,
		<Accordion index={8526} header={<div className={"question-card"} name={"8526_0"} excel_reference={41} anchor={true} anchor_text={"OFFICES / CONFERENCE ROOMS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"OFFICES / CONFERENCE ROOMS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"8527_0,8528_0,8529_0,8530_0,8531_0,8532_0,8533_0,8534_0,8535_0,8536_0,8537_0"} sub_total={"8542_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"8527_0"} label={"Trash Removed & Liner Replaced"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={42}  />
			<Select className={"question-card"} name={"8528_0"} label={"Carpets Vacuumed  / Spot Clean Stains"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={43}  />
			<Select className={"question-card"} name={"8529_0"} label={"Floors - Sweep, Mop, Buff & Vacuum"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={44}  />
			<Select className={"question-card"} name={"8530_0"} label={"Vacuum / Sweep Corners, Edges, & Under Furniture"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={45}  />
			<Select className={"question-card"} name={"8531_0"} label={"Interior Glass"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={46}  />
			<Select className={"question-card"} name={"8532_0"} label={"Telephones"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={47}  />
			<Select className={"question-card"} name={"8533_0"} label={"High Low Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={48}  />
			<Select className={"question-card"} name={"8534_0"} label={"Blinds / Window Sills Dusted"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={49}  />
			<Select className={"question-card"} name={"8535_0"} label={"Furniture, File Cabinets, Counter Tops, Etc."} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={50}  />
			<Select className={"question-card"} name={"8536_0"} label={"Wall Spotting (Finger Prints, Smudges, Etc.)"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={51}  />
			<Select className={"question-card"} name={"8537_0"} label={"A/C Vents & Return Air Vents"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={52}  />
			<TextInput className={"question-card"} name={"8538_0"} label={"COMMENTS"} excel_reference={53} fail_comment={true}  />
			<div className={"question-card"} name={"8539_0"} excel_reference={54}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"8540_0"} excel_reference={55}  />
			<Select className={"question-card"} name={"8541_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={56}  />
			<TextInput className={"question-card"} name={"8542_0"} label={"Total Rating - Maximum Score - 55"} excel_reference={57} excel={'+Q42+Q43+Q44+Q45+Q46+Q47+Q48+Q49+Q50+Q51+Q52'}  />
		</Accordion>,
		<Accordion index={8543} header={<div className={"question-card"} name={"8543_0"} excel_reference={58} anchor={true} anchor_text={"REST ROOMS / LOCKER ROOMS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"REST ROOMS / LOCKER ROOMS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"8544_0,8545_0,8546_0,8547_0,8548_0,8549_0,8550_0,8551_0,8552_0,8553_0"} sub_total={"8558_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"8544_0"} label={"Trash Removed & Liner Replaced"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={59}  />
			<Select className={"question-card"} name={"8545_0"} label={"Clean & Polish Stainless Steel Fixtures"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={60}  />
			<Select className={"question-card"} name={"8546_0"} label={"Replenish Tissue, Towels, & Soap Dispensers"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={61}  />
			<Select className={"question-card"} name={"8547_0"} label={"Mirrors & Frames"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={62}  />
			<Select className={"question-card"} name={"8548_0"} label={"High & Low Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={63}  />
			<Select className={"question-card"} name={"8549_0"} label={"Sinks, Faucets, & Counter Tops"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={64}  />
			<Select className={"question-card"} name={"8550_0"} label={"Floors Swept, Mopped"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={65}  />
			<Select className={"question-card"} name={"8551_0"} label={"Toilets / Urinals - Bowls, Seats, Rim, Plumbing Fixtures"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={66}  />
			<Select className={"question-card"} name={"8552_0"} label={"Partition Walls, Doors, Etc."} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={67}  />
			<Select className={"question-card"} name={"8553_0"} label={"A/C Vents & Return Air Vents"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={68}  />
			<TextInput className={"question-card"} name={"8554_0"} label={"COMMENTS"} excel_reference={69} fail_comment={true}  />
			<div className={"question-card"} name={"8555_0"} excel_reference={70}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"8556_0"} excel_reference={71}  />
			<Select className={"question-card"} name={"8557_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={72}  />
			<TextInput className={"question-card"} name={"8558_0"} label={"Total Rating - Maximum Score - 50"} excel_reference={73} excel={'+Q59+Q60+Q61+Q62+Q63+Q64+Q65+Q66+Q67+Q68'}  />
		</Accordion>,
		<Accordion index={8559} header={<div className={"question-card"} name={"8559_0"} excel_reference={74} anchor={true} anchor_text={" BREAK ROOMS / CAFETERIA"} style={{fontSize: '24px'}}  >
				<Paragraph text={" BREAK ROOMS / CAFETERIA"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"8560_0,8561_0,8562_0,8563_0,8564_0,8565_0,8566_0"} sub_total={"8571_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"8560_0"} label={"Floors Swept, Mopped, Vacuum & Buff"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={75}  />
			<Select className={"question-card"} name={"8561_0"} label={"High & Low Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={76}  />
			<Select className={"question-card"} name={"8562_0"} label={"Tables / Chairs"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={77}  />
			<Select className={"question-card"} name={"8563_0"} label={"A/C Vents & Return Air Vents"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={78}  />
			<Select className={"question-card"} name={"8564_0"} label={"Trash  removed & liners replaced"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={79}  />
			<Select className={"question-card"} name={"8565_0"} label={"Counter Tops, Sinks, & Back Splash Areas"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={80}  />
			<Select className={"question-card"} name={"8566_0"} label={"Wall Spotting (Finger Prints, Smudges)"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={81}  />
			<TextInput className={"question-card"} name={"8567_0"} label={"COMMENTS"} excel_reference={82} fail_comment={true}  />
			<ImageInput className={"question-card"} name={"8569_0"} excel_reference={84}  />
			<Select className={"question-card"} name={"8570_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={85}  />
			<TextInput className={"question-card"} name={"8571_0"} label={"Total Rating - Maximum Score - 35"} excel_reference={86} excel={'+Q75+Q76+Q77+Q78+Q79+Q80+Q81'}  />
		</Accordion>,
		<Accordion index={8572} header={<div className={"question-card"} name={"8572_0"} excel_reference={87} anchor={true} anchor_text={"JANITOR CLOSET / STORAGE AREA"} style={{fontSize: '24px'}}  >
				<Paragraph text={"JANITOR CLOSET / STORAGE AREA"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"8573_0,8574_0"} sub_total={"8579_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"8573_0"} label={"Mop Sink & Floors"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={88}  />
			<Select className={"question-card"} name={"8574_0"} label={"Supplies/Equipment (Stored neat & orderly)"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={89}  />
			<TextInput className={"question-card"} name={"8575_0"} label={"COMMENTS"} excel_reference={90} fail_comment={true}  />
			<div className={"question-card"} name={"8576_0"} excel_reference={91}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"8577_0"} excel_reference={92}  />
			<Select className={"question-card"} name={"8578_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={93}  />
			<TextInput className={"question-card"} name={"8579_0"} label={"Total Rating - Maximum Score - 10"} excel_reference={94} excel={'Q88+Q89'}  />
			<Select className={"question-card"} name={"8920_0"} label={"DID YOU REQUEST ANY WORK ORDERS? "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={96} required={true}  />
		</Accordion>,
		<Accordion index={8582} header={<div className={"question-card"} name={"8582_0"} excel_reference={98} anchor={true} anchor_text={"CAN WE PROVIDE ADDITIONAL SERV"} style={{fontSize: '24px'}}  >
				<Paragraph text={"CAN WE PROVIDE ADDITIONAL SERVICES (TAGS)?  What extra services can we suggest to make the buildings look better?"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"8583_0,8584_0,8585_0,8586_0,8587_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"8583_0"} label={"Do any areas need pressure washing? "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={99}  />
			<Select className={"question-card"} name={"8584_0"} label={"Do we need to shampoo carpet before periodics are due?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={100}  />
			<Select className={"question-card"} name={"8585_0"} label={"Do we need to strip & wax floor before periodics are due?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={101}  />
			<Select className={"question-card"} name={"8586_0"} label={"Do we need to provide any exterior window cleaning?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={102}  />
			<Select className={"question-card"} name={"8587_0"} label={"Can we provide a maintenance technician?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={103}  />
		</Accordion>,
		<TextInput className={"question-card"} name={"8580_0"} label={"Total Score"} excel_reference={95} anchor={true} anchor_text={"Total Score"} excel={'+Q25+Q40+Q57+Q73+Q86+Q94'}  />,
		<TextInput className={"question-card"} name={"8581_0"} label={"Percentage "} excel_reference={97} excel={'+(Q95/250)*100'}  />,
    ];

    return elements;
}

export default get_form_elements;
