import React, { Component } from 'react';
import {Wrapper, Container, Header, Div, Paragraph, FormWithChildren, DateTimePicker, TextInput, Select, TextArea, UserSearchInput, SignatureInput, ImageInput, Accordion} from 'library';

function get_form_elements(){
    var elements = [
		<Accordion index={6938} header={<div className={"question-card"} name={"6938_0"} excel_reference={1} style={{fontSize: '24px'}}  >
				<Paragraph text={"Plugging- In Scorecard"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"6939_0,6940_0,6941_0,6988_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<TextInput className={"question-card"} name={"6939_0"} label={"Name"} excel_reference={2}  />
			<TextInput className={"question-card"} name={"6940_0"} label={"Region"} excel_reference={3}  />
			<DateTimePicker className={"question-card"} name={"6941_0"} label={"Date"} excel_reference={4}  />
			<UserSearchInput className={"question-card"} name={"6988_0"} label={"Type and then CLICK on your SUPERVISOR's e-mail address here"} excel_reference={5}  />
		</Accordion>,
		<Accordion index={6955} header={<div className={"question-card"} name={"6955_0"} excel_reference={6} style={{fontSize: '24px'}}  >
				<Paragraph text={"Plugging-In Score"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"6943_0,6974_0,7002_0,6945_0,6975_0,7000_0,6947_0,6976_0,6998_0,6949_0,6977_0,6997_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<div className={"question-card"} name={"6942_0"} excel_reference={7} style={{fontSize: '14px'}}  >
				<Paragraph text={"Budget Compliance"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"6943_0"} label={"Director - Maintains compliance - and holds direct reports in assigned region(s) accountable to maintaining compliance - with all budgets (labor, supplies, equipment, etc.), approves POs, overtime, and requests for repairs or purchases. Ops Commitment Budgets meet Required Budgets or are actively being reduced (with documentation on WLM). "} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={8}  />
			<Select className={"question-card"} name={"6974_0"} label={"Supervisor - Maintains compliance - and holds direct reports in assigned region(s) accountable to maintaining compliance - with all budgets (labor, supplies, equipment, etc.), approves POs, overtime, and requests for repairs or purchases. Ops Commitment Budgets meet Required Budgets or are actively being reduced (with documentation on WLM). "} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={9}  />
			<TextInput className={"question-card"} name={"7002_0"} label={"Comments"} excel_reference={10}  />
			<div className={"question-card"} name={"6944_0"} excel_reference={11} style={{fontSize: '14px'}}  >
				<Paragraph text={"Green Program Implementationand/or Compliance"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"6945_0"} label={"Director - Where applicable"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={12}  />
			<Select className={"question-card"} name={"6975_0"} label={"Supervisor - Where applicable"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={13}  />
			<TextInput className={"question-card"} name={"7000_0"} label={"Comments"} excel_reference={14}  />
			<div className={"question-card"} name={"6946_0"} excel_reference={15} style={{fontSize: '14px'}}  >
				<Paragraph text={"Growth"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"6947_0"} label={"Director - Promotes, prices, and approves TAG work, meets growth goals in assigned region(s), works with S&amp;P and VP on new business such and estimating new jobs, participates in the Lead Generation Process and holds Operations Managers, Area Supervisors, and Supervisors accountable to providing quality marketing leads on a monthly basis"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={16}  />
			<Select className={"question-card"} name={"6976_0"} label={"Supervisor - Promotes, prices, and approves TAG work, meets growth goals in assigned region(s), works with S&amp;P and VP on new business such and estimating new jobs, participates in the Lead Generation Process and holds Operations Managers, Area Supervisors, and Supervisors accountable to providing quality marketing leads on a monthly basis"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={17}  />
			<TextInput className={"question-card"} name={"6998_0"} label={"Comments"} excel_reference={18}  />
			<div className={"question-card"} name={"6948_0"} excel_reference={19} style={{fontSize: '14px'}}  >
				<Paragraph text={"Organizational Management"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"6949_0"} label={"Director - Ensures staffing levels in assigned region(s) meet Organizational Standards with clear reporting lines and defined areas of responsibility by position. Holds direct reports accountable to conducting all Supervisor and Lead certification trainings and holding employees accountable to performance standards detailed in their job description"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={20}  />
			<Select className={"question-card"} name={"6977_0"} label={"Supervisor - Ensures staffing levels in assigned region(s) meet Organizational Standards with clear reporting lines and defined areas of responsibility by position. Holds direct reports accountable to conducting all Supervisor and Lead certification trainings and holding employees accountable to performance standards detailed in their job description"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={21}  />
			<TextInput className={"question-card"} name={"6997_0"} label={"Comments"} excel_reference={22}  />
		</Accordion>,
		<Accordion index={6956} header={<div className={"question-card"} name={"6956_0"} excel_reference={23} style={{fontSize: '24px'}}  >
				<Paragraph text={"Own Customer Management Score"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"6951_0,6978_0,6995_0,6953_0,6979_0,6994_0,6958_0,6980_0,6993_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<div className={"question-card"} name={"6950_0"} excel_reference={24} style={{fontSize: '14px'}}  >
				<Paragraph text={"Customer Relationships"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"6951_0"} label={"Director - Holds Operations Manager(s) and/or Area Supervisor(s)/Supervisor(s) accountable to maintaining excellent relationships with their customers through weekly visits/communications as demonstrated through robust feedback from customers via the online survey each month and collaboration with direct reports to reach goal of 100% customer survey participation in assigned region(s)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={25}  />
			<Select className={"question-card"} name={"6978_0"} label={"Supervisor - Holds Operations Manager(s) and/or Area Supervisor(s)/Supervisor(s) accountable to maintaining excellent relationships with their customers through weekly visits/communications as demonstrated through robust feedback from customers via the online survey each month and collaboration with direct reports to reach goal of 100% customer survey participation in assigned region(s)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={26}  />
			<TextInput className={"question-card"} name={"6995_0"} label={"Comments"} excel_reference={27}  />
			<div className={"question-card"} name={"6952_0"} excel_reference={28} style={{fontSize: '14px'}}  >
				<Paragraph text={"Account Status"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"6953_0"} label={"Director - Supports VP with aging reports and customer follow-up, collaborates with accounting to obtain payment for accounts receivables over 30 days past due and works with customers to expedite payment when applicable"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={29}  />
			<Select className={"question-card"} name={"6979_0"} label={"Supervisor - Supports VP with aging reports and customer follow-up, collaborates with accounting to obtain payment for accounts receivables over 30 days past due and works with customers to expedite payment when applicable"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={30}  />
			<TextInput className={"question-card"} name={"6994_0"} label={"Comments"} excel_reference={31}  />
			<div className={"question-card"} name={"6954_0"} excel_reference={32} style={{fontSize: '14px'}}  >
				<Paragraph text={"Account Changes"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"6958_0"} label={"Director - Coordinates and supports phase in/out of accounts in assigned region, submits SOA changes timely, and holds direct reports accountable to providing timely notice of account changes in the assigned region(s)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={33}  />
			<Select className={"question-card"} name={"6980_0"} label={"Supervisor - Coordinates and supports phase in/out of accounts in assigned region, submits SOA changes timely, and holds direct reports accountable to providing timely notice of account changes in the assigned region(s)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={34}  />
			<TextInput className={"question-card"} name={"6993_0"} label={"Comments"} excel_reference={35}  />
		</Accordion>,
		<Accordion index={6959} header={<div className={"question-card"} name={"6959_0"} excel_reference={36} style={{fontSize: '24px'}}  >
				<Paragraph text={"Collaboration Score"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"6961_0,6981_0,6991_0,6963_0,6983_0,7007_0,6965_0,6984_0,7006_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<div className={"question-card"} name={"6960_0"} excel_reference={37} style={{fontSize: '14px'}}  >
				<Paragraph text={"Weekly LEADERSHIP Meetings (WLMs)"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"6961_0"} label={"Director - Attends weekly, leads the WLM reporting, trains and collaborates with direct reports to promote meaningful engagement in WLM (active participation &amp; collaboration rather than checking the boxes)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={38}  />
			<Select className={"question-card"} name={"6981_0"} label={"Supervisor - Attends weekly, leads the WLM reporting, trains and collaborates with direct reports to promote meaningful engagement in WLM (active participation &amp; collaboration rather than checking the boxes)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={39}  />
			<TextInput className={"question-card"} name={"6991_0"} label={"Comments"} excel_reference={40}  />
			<div className={"question-card"} name={"6962_0"} excel_reference={41} style={{fontSize: '14px'}}  >
				<Paragraph text={"Supervision & Oversight of Operations Managers"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"6963_0"} label={"Director - Provides & receives quarterly & annual feedback, holds direct reports accountable for payroll validation and confirming punches are closed, completes and consolidates scorecards from all direct reports, holds Supervisors accountable to ownership of staffing and hiring process, approves non-standard ECs & terminations, provides investigation recommendations with HR assistance when escalated from OM or Supervisor, approves time off requests from direct reports. QCs completed in each assigned region per the standard with actions created to correct deficiencies and prevent recurrence. Direct reports meet or exceed the manual punch edit goal in each region. Conducts regular review and update of questions reviewed in Daily Calls throughout assigned region(s) - and holds direct reports accountable to the same review/update - to facilitate site-specific questions with actionable commitments that contribute to customer satisfaction and streamlined operations. "} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={42}  />
			<Select className={"question-card"} name={"6983_0"} label={"Supervisor - Provides & receives quarterly & annual feedback, holds direct reports accountable for payroll validation and confirming punches are closed, completes and consolidates scorecards from all direct reports, holds Supervisors accountable to ownership of staffing and hiring process, approves non-standard ECs & terminations, provides investigation recommendations with HR assistance when escalated from OM or Supervisor, approves time off requests from direct reports. QCs completed in each assigned region per the standard with actions created to correct deficiencies and prevent recurrence. Direct reports meet or exceed the manual punch edit goal in each region. Conducts regular review and update of questions reviewed in Daily Calls throughout assigned region(s) - and holds direct reports accountable to the same review/update - to facilitate site-specific questions with actionable commitments that contribute to customer satisfaction and streamlined operations. "} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={43}  />
			<TextInput className={"question-card"} name={"7007_0"} label={"Comments"} excel_reference={44}  />
			<div className={"question-card"} name={"6964_0"} excel_reference={45} style={{fontSize: '14px'}}  >
				<Paragraph text={"Raise the Flag "} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"6965_0"} label={"Director - Creates RTFs, follows up on RTFs and final RCA/ICA. Assigned regions meet RTF minimum/maximum quantity goals, with RTFs filed and closed timely. Supports direct reports in creation of high quality IFs, RCAs, ICAs to ensure timely issue resolution and prevent recurrence."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={46}  />
			<Select className={"question-card"} name={"6984_0"} label={"Supervisor - Creates RTFs, follows up on RTFs and final RCA/ICA. Assigned regions meet RTF minimum/maximum quantity goals, with RTFs filed and closed timely. Supports direct reports in creation of high quality IFs, RCAs, ICAs to ensure timely issue resolution and prevent recurrence."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={47}  />
			<TextInput className={"question-card"} name={"7006_0"} label={"Comments"} excel_reference={48}  />
		</Accordion>,
		<Accordion index={6966} header={<div className={"question-card"} name={"6966_0"} excel_reference={49} style={{fontSize: '24px'}}  >
				<Paragraph text={"LEADERSHIP"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"6968_0,6985_0,7005_0,6970_0,6986_0,7004_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<div className={"question-card"} name={"6967_0"} excel_reference={50} style={{fontSize: '14px'}}  >
				<Paragraph text={"LEADERSHIP Development"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"6968_0"} label={"Director - Owns results from LEADERSHIP Development Plan, conduct LEADERSHIP assessments to determine when we have the right people (yes - plug them in OR no - develop or replace), leads the team in the use &amp; implementation of all methods, holds the team accountable to deadlines &amp; commitments, leads the team to embracing &amp; applying the LEADERSHIP Development Plan to each and every task. Use the comments space below to list the LEADERSHIP Development Trainings you conducted with your direct reports since we met last. "} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={51}  />
			<Select className={"question-card"} name={"6985_0"} label={"Supervisor - Owns results from LEADERSHIP Development Plan, conduct LEADERSHIP assessments to determine when we have the right people (yes - plug them in OR no - develop or replace), leads the team in the use &amp; implementation of all methods, holds the team accountable to deadlines &amp; commitments, leads the team to embracing &amp; applying the LEADERSHIP Development Plan to each and every task. Use the comments space below to list the LEADERSHIP Development Trainings you conducted with your direct reports since we met last. "} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={52}  />
			<TextInput className={"question-card"} name={"7005_0"} label={"Comments"} excel_reference={53}  />
			<div className={"question-card"} name={"7003_0"} excel_reference={54} style={{fontSize: '14px'}}  >
				<Paragraph text={"LEADERSHIP"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"6970_0"} label={"Director - Generates lasting positive change at a quick, yet sustainable pace. Use the comments space below to list the LEADERSHIP demonstrated since your last scorecard. Must truly be examples of lasting positive change."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={55}  />
			<Select className={"question-card"} name={"6986_0"} label={"Supervisor - Generates lasting positive change at a quick, yet sustainable pace. Use the comments space below to list the LEADERSHIP demonstrated since your last scorecard. Must truly be examples of lasting positive change."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={56}  />
			<TextInput className={"question-card"} name={"7004_0"} label={"Comments"} excel_reference={57}  />
		</Accordion>,
		<Accordion index={6971} header={<div className={"question-card"} name={"6971_0"} excel_reference={58} style={{fontSize: '24px'}}  >
				<Paragraph text={"Strategic Plan Execution Score"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"6973_0,6987_0,6989_0,7008_0,7009_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<div className={"question-card"} name={"6972_0"} excel_reference={59} style={{fontSize: '14px'}}  >
				<Paragraph text={"Meet Established Goals (by assigned region(s)/customer(s)/direct report(s)"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"6973_0"} label={"Director - Assigned region(s) and direct reports meet all goals established in the annual Strategic Plan including growth, operating margin, plugging in, and customer satisfaction"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={60}  />
			<Select className={"question-card"} name={"6987_0"} label={"Supervisor - Assigned region(s) and direct reports meet all goals established in the annual Strategic Plan including growth, operating margin, plugging in, and customer satisfaction"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={61}  />
			<TextInput className={"question-card"} name={"6989_0"} label={"Comments"} excel_reference={62}  />
			<TextInput className={"question-card"} name={"7008_0"} label={"Average Directors Score"} excel_reference={63} excel={'(+Q8+Q12+Q16+Q20+Q25+Q29+Q33+Q38+Q42+Q46+Q51+Q55+Q60)/13'}  />
			<TextInput className={"question-card"} name={"7009_0"} label={"Average Supervisor Score"} excel_reference={64} excel={'(+Q9+Q13+Q17+Q21+Q26+Q30+Q34+Q39+Q43+Q47+Q52+Q56+Q61)/13'}  />
		</Accordion>,
    ];

    return elements;
}

export default get_form_elements;
