import React, { Component } from 'react';
import { ajaxWrapper, resolveVariables, format_date } from 'functions';

import QC_UCD_Downtown from 'pages/custom_forms/qc_ucd_downtown.js';
import QC_UCD_Anschutz from 'pages/custom_forms/qc_ucd_anschutz.js';
import Supervisor__Plugged_In_Scorecard from 'pages/custom_forms/supervisor__plugged_in_scorecard.js';
import Recruiters_Plugging_In_Scorecard from 'pages/custom_forms/recruiters_plugging_in_scorecard.js';
import Espanol_Plugging_In_Scorecard from 'pages/custom_forms/espanol_plugging_in_scorecard.js';
import Minnesota_1 from 'pages/custom_forms/minnesota_1.js';
import Hudson_Bay from 'pages/custom_forms/hudson_bay.js';
import Larimer_County from 'pages/custom_forms/larimer_county.js';
import _1700_Broadway from 'pages/custom_forms/_1700_broadway.js';
import Minnesota_2 from 'pages/custom_forms/minnesota_2.js';
import Haliburton from 'pages/custom_forms/haliburton.js';
import Michigan from 'pages/custom_forms/michigan.js';
import New_Mexico from 'pages/custom_forms/new_mexico.js';
import Northbelt from 'pages/custom_forms/northbelt.js';
import Schlumberger from 'pages/custom_forms/schlumberger.js';
import West_Texas from 'pages/custom_forms/west_texas.js';
import Lubbock_Animal_Kennel from 'pages/custom_forms/lubbock_animal_kennel.js';
import Employee_Recognition from 'pages/custom_forms/employee_recognition.js';
import Customer_Survey_Kleen_Tech from 'pages/custom_forms/customer_survey_kleen_tech.js';
import DO_Scorecard__Old from 'pages/custom_forms/do_scorecard__old.js';
import OM_Scorecard_Old from 'pages/custom_forms/om_scorecard_old.js';
import Foreman_Plugging_In_Scorecard from 'pages/custom_forms/foreman_plugging_in_scorecard.js';
import SNL_CA from 'pages/custom_forms/snl_ca.js';
import Customer_Survey_Sandia_National_Laboratory from 'pages/custom_forms/customer_survey_sandia_national_laboratory.js';
import Presidential_Hotline from 'pages/custom_forms/presidential_hotline.js';
import Centerpoint_Energy_Old from 'pages/custom_forms/centerpoint_energy_old.js';
import El_Paso_County from 'pages/custom_forms/el_paso_county.js';
import City_of_Lubbock from 'pages/custom_forms/city_of_lubbock.js';
import Centerpoint_Energy_North from 'pages/custom_forms/centerpoint_energy_north.js';
import Centerpoint_Energy_South from 'pages/custom_forms/centerpoint_energy_south.js';
import _1700_Broadway_QC from 'pages/custom_forms/_1700_broadway_qc.js';
import West_Texas_QC from 'pages/custom_forms/west_texas_qc.js';
import Schlumberger_QC from 'pages/custom_forms/schlumberger_qc.js';
import Northbelt_QC from 'pages/custom_forms/northbelt_qc.js';
import Minnesota_1_QC from 'pages/custom_forms/minnesota_1_qc.js';
import Minnesota_2_QC from 'pages/custom_forms/minnesota_2_qc.js';
import Michigan_QC from 'pages/custom_forms/michigan_qc.js';
import Larimer_County_QC from 'pages/custom_forms/larimer_county_qc.js';
import Hudson_Bay_QC from 'pages/custom_forms/hudson_bay_qc.js';
import Haliburton_QC from 'pages/custom_forms/haliburton_qc.js';
import _2020_Director_of_Operations_Plugging_In_Scorecard from 'pages/custom_forms/_2020_director_of_operations_plugging_in_scorecard.js';
import _2020_Operations_Managers_Plugging_In_Scorecard from 'pages/custom_forms/_2020_operations_managers_plugging_in_scorecard.js';
import El_Paso_County_QC from 'pages/custom_forms/el_paso_county_qc.js';
import Centerpoint_Energy_South_QC from 'pages/custom_forms/centerpoint_energy_south_qc.js';
import CenterPoint_Energy_North_QC from 'pages/custom_forms/centerpoint_energy_north_qc.js';
import Test from 'pages/custom_forms/test.js';
import Colorado_5_UC_Denver_Anschutz from 'pages/custom_forms/colorado_5_uc_denver_anschutz.js';
import West_TX_Xcel_QC from 'pages/custom_forms/west_tx_xcel_qc.js';
import Xcel_NM_Hobbs from 'pages/custom_forms/xcel_nm_hobbs.js';

import Minnesota_1_Checklists from 'pages/custom_forms/minnesota_1_checklists.js';
import Aurora_Carpet_QC from 'pages/custom_forms/aurora_carpet_qc.js';
import Minnesota_2_Checklists from 'pages/custom_forms/minnesota_2_checklists.js';
import _2021_Director_of_Operations_Plugging_In_Scorecard from 'pages/custom_forms/_2021_director_of_operations_plugging_in_scorecard.js';
import _2021_Operations_Manager_Plugging_In_Scorecard from 'pages/custom_forms/_2021_operations_manager_plugging_in_scorecard.js';
import Minnesota_3_QC from 'pages/custom_forms/minnesota_3_qc.js';
import Minnesota_4_QC from 'pages/custom_forms/minnesota_4_qc.js';
import Minnesota_5_QC from 'pages/custom_forms/minnesota_5_qc.js';
import Minnesota_3_Checklists from 'pages/custom_forms/minnesota_3_checklists.js';
import Minnesota_4_Checklists from 'pages/custom_forms/minnesota_4_checklists.js';
import Minnesota_5_Checklists from 'pages/custom_forms/minnesota_5_checklists.js';

import OLD_Colorado_6_QC from 'pages/custom_forms/OLD_colorado_6_qc.js';
import OLD_Colorado_6_Checklists from 'pages/custom_forms/OLD_colorado_6_checklists.js';

import Colorado_1 from 'pages/custom_forms/colorado_1.js';
import Colorado_2 from 'pages/custom_forms/colorado_2.js';
import Colorado_3 from 'pages/custom_forms/colorado_3.js';

import Colorado_1_Checklists from 'pages/custom_forms/colorado_1_checklists.js';
import Colorado_2_Checklists from 'pages/custom_forms/colorado_2_checklists.js';
import Colorado_3_Checklists from 'pages/custom_forms/colorado_3_checklists.js';
import Colorado_5_Checklists from 'pages/custom_forms/colorado_5_checklists.js';
import Colorado_6_Checklists from 'pages/custom_forms/colorado_6_checklists.js';
import Colorado_7_Checklists from 'pages/custom_forms/colorado_7_checklists.js';
import Colorado_8_Checklists from 'pages/custom_forms/colorado_8_checklists.js';
import Colorado_9_Checklists from 'pages/custom_forms/colorado_9_checklists.js';
import Colorado_10_Checklists from 'pages/custom_forms/colorado_10_checklists.js';
import Colorado_11_Checklists from 'pages/custom_forms/colorado_11_checklists.js';
import Colorado_12_Checklists from 'pages/custom_forms/colorado_12_checklists.js';

import Colorado_1_QC from 'pages/custom_forms/colorado_1_qc.js';
import Colorado_2_QC from 'pages/custom_forms/colorado_2_qc.js';
import Colorado_3_QC from 'pages/custom_forms/colorado_3_qc.js';
import Colorado_5_QC from 'pages/custom_forms/colorado_5_qc.js';
import Colorado_6_QC from 'pages/custom_forms/colorado_6_qc.js';
import Colorado_7_QC from 'pages/custom_forms/colorado_7_qc.js';
import Colorado_8_QC from 'pages/custom_forms/colorado_8_qc.js';
import Colorado_9_QC from 'pages/custom_forms/colorado_9_qc.js';
import Colorado_10_QC from 'pages/custom_forms/colorado_10_qc.js';
import Colorado_11_QC from 'pages/custom_forms/colorado_11_qc.js';
import Colorado__12_QC from 'pages/custom_forms/colorado__12_qc.js';
import Colorado_6_QC_DIA from 'pages/custom_forms/colorado_6_qc_dia.js';

import Haliburton_Checklists from 'pages/custom_forms/haliburton_checklists.js';
import Hudson_Bay_Checklists from 'pages/custom_forms/hudson_bay_checklists.js';
import Larimer_County_Checklists from 'pages/custom_forms/larimer_county_checklists.js';
import Michigan_Checklists from 'pages/custom_forms/michigan_checklists.js';
import Northbelt_Checklists from 'pages/custom_forms/northbelt_checklists.js';
import Schlumberger_Checklists from 'pages/custom_forms/schlumberger_checklists.js';
import West_Texas_Checklists from 'pages/custom_forms/west_texas_checklists.js';
import Centerpoint_Energy_North_Checklists from 'pages/custom_forms/centerpoint_energy_north_checklists.js';
import Centerpoint_Energy_South_Checklists from 'pages/custom_forms/centerpoint_energy_south_checklists.js';
import El_Paso_County_Checklists from 'pages/custom_forms/el_paso_county_checklists.js';
import West_TX_Xcel_Checklists from 'pages/custom_forms/west_tx_xcel_checklists.js';
import Harris_County_Checklists from 'pages/custom_forms/harris_county_checklists.js';
import Lubbock_Checklists from 'pages/custom_forms/lubbock_checklists.js';
import City_Of_Lubbock_QC from 'pages/custom_forms/city_of_lubbock_qc.js';
import Douglas_County_Checklists from 'pages/custom_forms/douglas_county_checklists.js';
import Florida_1_QC from 'pages/custom_forms/florida_1_qc.js';
import Florida_2_QC from 'pages/custom_forms/florida_2_qc.js';
import Florida_1_Checklists from 'pages/custom_forms/florida_1_checklists.js';
import Florida_2_Checklists from 'pages/custom_forms/florida_2_checklists.js';
import PA_Division_1_QC from 'pages/custom_forms/pa_division_1_qc.js';
import PA_Division_2_QC from 'pages/custom_forms/pa_division_2_qc.js';
import PA_Division_3_QC from 'pages/custom_forms/pa_division_3_qc.js';
import PA_Division_4_QC from 'pages/custom_forms/pa_division_4_qc.js';
import PA_Division_5_QC from 'pages/custom_forms/pa_division_5_qc.js';
import PA_Division_6_QC from 'pages/custom_forms/pa_division_6_qc.js';
import UCD_Downtown_Checklists from 'pages/custom_forms/ucd_downtown_checklists.js';
import UCD_Anschutz_Checklists from 'pages/custom_forms/ucd_anschutz_checklists.js';
import Larimer_County_Numeric from 'pages/custom_forms/larimer_county_numeric.js';

import Sentinal_Austin_QC from 'pages/custom_forms/sentinel_austin_qc.js';
import Sentinal_Austin_Checklist from 'pages/custom_forms/sentinel_austin_checklist.js';
import Sentinal_Nevada_Checklist from 'pages/custom_forms/sentinel_nevada_checklist.js';

import Houston_Airport_System_QC from 'pages/custom_forms/houston_airport_system_qc.js';
import Houston_Airport_System_Checklist from 'pages/custom_forms/houston_airport_system_checklist.js';

import INVALID_Illinois_1_QC from 'pages/custom_forms/INVALID_illinois_1_qc.js';
import INVALID_Illinois_2_QC from 'pages/custom_forms/INVALID_illinois_2_qc.js';
import INVALID_Illinois_3_QC from 'pages/custom_forms/INVALID_illinois_3_qc.js';

import Illinois_1_QC from 'pages/custom_forms/illinois_1_qc.js';
import Illinois_2_QC from 'pages/custom_forms/illinois_2_qc.js';
import Illinois_3_QC from 'pages/custom_forms/illinois_3_qc.js';
import Illinois_4_QC from 'pages/custom_forms/illinois_4_qc.js';
import SC_QC_1 from 'pages/custom_forms/sc_qc_1.js';
import SC_QC_2 from 'pages/custom_forms/sc_qc_2.js';

import Northeast_and_Nevada_QC from 'pages/custom_forms/northeast_and_nevada_qc.js';

import California_1_QC from 'pages/custom_forms/california_1_qc.js';
import California_2_QC from 'pages/custom_forms/california_2_qc.js';
import California_3_QC from 'pages/custom_forms/california_3_qc.js';
import PA_District_3_Checklist from 'pages/custom_forms/pa_district_3_checklist.js';

import Harris_County_QC from 'pages/custom_forms/harris_county_qc.js';
import HCTRA_QC from 'pages/custom_forms/HCTRA_qc.js';
import SNL_QC from 'pages/custom_forms/snl_qc.js';

import QC_Florida_1_Residential from 'pages/custom_forms/qc_florida_1_residential.js';
import QC_Florida_2_Residential from 'pages/custom_forms/qc_florida_2_residential.js';

import MI_QC from 'pages/custom_forms/mi_qc.js';

function custom_form_lookup(id) {
    console.log('\n\n\nCustom Form Lookup!', id);
    var content = null;

    if (!id) {
        return null;
    } else if (id == 'ceb4fa53-0d1a-45b4-a686-f9eed28e2053') {
        content = QC_UCD_Downtown();
    } else if (id == '76daccb1-ce8e-4431-a2f2-17072325bba8') {
        content = QC_UCD_Anschutz();
    } else if (id == '513b45bc-1d08-471d-b72d-d45d6ed7161a') {
        content = Supervisor__Plugged_In_Scorecard();
    } else if (id == '749e970f-fd89-434d-9da3-efe4e7d90a1b') {
        content = Recruiters_Plugging_In_Scorecard();
    } else if (id == '5245b442-e895-4c14-859f-ae618deb612e') {
        content = Espanol_Plugging_In_Scorecard();
    } else if (id == '74f014c2-db37-4124-bef5-d880fc3e9c79') {
        content = Minnesota_1();
    } else if (id == 'b0737d53-c7ac-43ff-a5ff-443e8bbbc919') {
        content = Hudson_Bay();
    } else if (id == '05f44ad6-89d4-44ce-a0c6-1fd00e9d45cf') {
        content = Larimer_County();
    } else if (id == '171ed076-75d8-4878-97f5-2f06cf3716ae') {
        content = _1700_Broadway();
    } else if (id == 'd5ac8916-0273-47a4-9f67-8ea0bc3973f2') {
        content = Minnesota_2();
    } else if (id == '34ab82c1-1375-43be-94df-28c91d6ae3e5') {
        content = Colorado_2();
    } else if (id == 'bad08229-bdd5-40b4-a025-5eed984e75d7') {
        content = Colorado_3();
    } else if (id == '37afa947-d2bf-48db-94f2-edfde3781be9') {
        content = Haliburton();
    } else if (id == '509bddf8-0c14-467b-8109-d2bf7e6241cd') {
        content = Michigan();
    } else if (id == '777f5468-8bca-43db-948b-594b8c7d702a') {
        content = New_Mexico();
    } else if (id == 'b6ffd6e0-f5d4-4674-bfcb-034d022cbdf3') {
        content = Northbelt();
    } else if (id == 'df6e2312-6b73-436e-9aac-c2da825f4b7e') {
        content = Schlumberger();
    } else if (id == 'ad4c4d51-d91c-4147-9c26-518770355d3f') {
        content = West_Texas();
    } else if (id == '1ef9cf46-6eda-4087-9389-b086543e2eb6') {
        content = Lubbock_Animal_Kennel();
    } else if (id == '54a88c5c-07be-4f6c-96c9-d7fb59042e4e') {
        content = Employee_Recognition();
    } else if (id == 'c7fc54f9-9e1c-432f-958e-4140ca105d6c') {
        content = Customer_Survey_Kleen_Tech();
    } else if (id == 'f74d368f-f699-461d-90b8-9fc767b253c8') {
        content = DO_Scorecard__Old();
    } else if (id == '146c3651-7086-401a-af6c-4ea07ed7cd09') {
        content = OM_Scorecard_Old();
    } else if (id == '3dafe1a5-9689-4cf9-bb2c-cbbbfb8aea86') {
        content = Foreman_Plugging_In_Scorecard();
    } else if (id == '531a359c-8d40-42eb-8d9e-b901ce4ccdb7') {
        content = Colorado_1();
    } else if (id == 'd5e74dc6-7a76-47ac-8fb3-27e2f006e586') {
        content = SNL_CA();
    } else if (id == '16980bb5-52ba-4b46-a77f-14e114b00ebd') {
        content = Customer_Survey_Sandia_National_Laboratory();
    } else if (id == '2373e0bb-c8e5-4335-ae53-1bcbf2fde86f') {
        content = Presidential_Hotline();
    } else if (id == '4f0ab06d-e42c-4501-9c83-0d05a7c40a81') {
        content = Centerpoint_Energy_Old();
    } else if (id == '2aefea51-a852-423e-a4bf-111ca70d4bb6') {
        content = El_Paso_County();
    } else if (id == 'd4a48f2b-bb8a-45c6-b983-61ddfad25f97') {
        content = City_of_Lubbock();
    } else if (id == 'db5cfb93-a7b7-430d-bd60-f89c78fa0354') {
        content = Centerpoint_Energy_North();
    } else if (id == 'd51a3941-91ef-4e89-942f-3d3c268e7ccf') {
        content = Centerpoint_Energy_South();
    } else if (id == '7e2b8788-7cb4-475f-a6ad-28a2d83c6504') {
        content = _1700_Broadway_QC();
    } else if (id == 'b3ce7752-24d8-4a6f-833f-26cadc1a6f4b') {
        content = West_Texas_QC();
    } else if (id == '193da926-1f8b-4aaa-8374-40aaa60022dd') {
        content = Schlumberger_QC();
    } else if (id == '2bd5eb86-379f-42dd-8cee-8e282e98859f') {
        content = Northbelt_QC();
    } else if (id == '6b6b2562-905c-4a3d-a51a-99f7e3652e23') {
        content = Minnesota_1_QC();
    } else if (id == '9f46f58e-905a-4298-8524-a21f917e029c') {
        content = Minnesota_2_QC();
    } else if (id == 'af49549a-d518-4e66-8361-b1c5f85e8e21') {
        content = Michigan_QC();
    } else if (id == '2a84dc44-e08e-46a8-b8f7-41c06622a0db') {
        content = Larimer_County_QC();
    } else if (id == 'c6728485-d33c-43f8-822b-21c3244d5560') {
        content = Hudson_Bay_QC();
    } else if (id == '9abe8d90-1225-48ff-a728-51d084c5659c') {
        content = Haliburton_QC();
    } else if (id == '667509fd-b745-4988-bc98-1d3ffd9b112a') {
        content = Colorado_3_QC();
    } else if (id == '923fc3da-e47c-42fd-ad89-0978bb975fd7') {
        content = Colorado_2_QC();
    } else if (id == '6852b086-e26c-4d98-af15-1f7c947dcebd') {
        content = Colorado_1_QC();
    } else if (id == '14efd21b-523f-42a0-bb22-95a4cad95abe') {
        content = _2020_Director_of_Operations_Plugging_In_Scorecard();
    } else if (id == 'e4ed459b-4417-4ff3-9380-cdae76fe556a') {
        content = _2020_Operations_Managers_Plugging_In_Scorecard();
    } else if (id == '4a45e6da-704c-440d-8675-540620435a6b') {
        content = El_Paso_County_QC();
    } else if (id == 'e4639fe8-fdfd-482f-8268-4b5abd9003f9') {
        content = Centerpoint_Energy_South_QC();
    } else if (id == 'f4ea1ad7-515c-4fb3-a6df-7ea5692886f1') {
        content = CenterPoint_Energy_North_QC();
    } else if (id == '05691384-e57b-49ca-985a-799aa1f9daf2') {
        content = Test();
    } else if (id == '61e254cd-6d45-4aeb-af74-0e9f6a5c20e1') {
        content = Colorado_5_UC_Denver_Anschutz();
    } else if (id == 'e4544ce9-1fbc-436a-a46b-f965abd84adc') {
        content = Colorado__12_QC();
    } else if (id == '4180581d-779b-4832-9fa8-236c4b1b4caf') {
        content = West_TX_Xcel_QC();
    } else if (id == '001482e2-32a1-4c56-a2ce-517c9d330e31') {
        content = Xcel_NM_Hobbs();
    } else if (id == 'b9f59545-6779-475f-a292-b547365f5670') {
        content = Colorado_5_QC();
    } else if (id == 'afedb866-53f8-482f-823f-fd9accd4ed23') {
        content = Colorado_7_QC();
    } else if (id == '1c1d2f26-a0a2-4755-a6ee-65e8b35c9351') {
        content = Colorado_8_QC();
    } else if (id == 'e3e137b9-10e8-40be-af96-a3fa9b3c57d1') {
        content = Colorado_9_QC();
    } else if (id == 'dbbf748f-fce3-4c2e-aea2-fb896d59818a') {
        content = Colorado_10_QC();
    } else if (id == '17923ffe-acd6-47eb-8e0d-a7d3b0500a7a') {
        content = Colorado_11_QC();
    } else if (id == 'a6a6e93b-6844-4860-97a0-111bce6724f6') {
        content = Minnesota_1_Checklists();
    } else if (id == '228bdf91-7883-4cfc-8cef-59ea3b077b6f') {
        content = Aurora_Carpet_QC();
    } else if (id == '23d211ae-0fd2-4079-b282-142be78fc690') {
        content = Minnesota_2_Checklists();
    } else if (id == '1919d281-98b7-4977-935a-58e44a311091') {
        content = _2021_Director_of_Operations_Plugging_In_Scorecard();
    } else if (id == 'd8f6283f-a945-4110-82ce-29e92a7487ac') {
        content = _2021_Operations_Manager_Plugging_In_Scorecard();
    } else if (id == '1a64bea3-b4e1-4444-8efb-0e2536dcd7fd') {
        content = Minnesota_3_QC();
    } else if (id == '659afb5b-fd7d-41b8-b30e-2122d0aa0445') {
        content = Minnesota_4_QC();
    } else if (id == '87562e38-1bd5-4ef4-9a91-9359eaa8da97') {
        content = Minnesota_5_QC();
    } else if (id == 'b7272d4e-2c2b-4991-b78b-6f3ca9241ef5') {
        content = Minnesota_3_Checklists();
    } else if (id == '7f86abc0-dd18-4f1d-92e9-eeaf02fc523b') {
        content = Minnesota_4_Checklists();
    } else if (id == '7f86abc0-dd18-4f1d-92e9-eeaf02fc523b') {
        content = Minnesota_5_Checklists();
    } else if (id == '657cc221-3e42-48f4-8c42-981decc6e2d8') {
        content = Colorado_1_Checklists();
    } else if (id == '5e6f54cd-2e2f-4b53-b90d-fe68d504d3fe') {
        content = Colorado_2_Checklists();
    } else if (id == 'ae36a91b-30f8-4759-a017-8cd5ee69b761') {
        content = Colorado_3_Checklists();
    } else if (id == '891bab1c-7a8e-4970-9714-b8921ad74388') {
        content = Colorado_5_Checklists();
    } else if (id == '5c891392-d4cc-40b6-88c7-3502c5b512bf') {
        content = Colorado_7_Checklists();
    } else if (id == 'dad00cdc-6a93-4ec4-9071-77f985ed3626') {
        content = Colorado_8_Checklists();
    } else if (id == '142fd710-2e1f-490b-9efb-b29658884a40') {
        content = Colorado_9_Checklists();
    } else if (id == '7d1ddcf4-e7c5-424c-ba5c-cb275a59ea6c') {
        content = Colorado_10_Checklists();
    } else if (id == '61510ef8-9ed4-48e1-b81e-99f175e7bf03') {
        content = Colorado_11_Checklists();
    } else if (id == '12f2d5c2-d2ac-4889-8f28-8a1cd1177edc') {
        content = Colorado_12_Checklists();
    } else if (id == '25534437-0470-473a-9253-33a730d87a7f') {
        content = OLD_Colorado_6_QC();
    } else if (id == '042f72ec-38ed-4786-a1d6-7bd0ae1bdfa2') {
        content = OLD_Colorado_6_Checklists();
    } else if (id == '362baf3f-b039-4ba4-a4fe-a081757ba856') {
        content = Colorado_6_QC_DIA();
    } else if (id == '82bbd1ff-573b-4011-a004-acce0dd8b2e1') {
        content = Colorado_6_QC();
    } else if (id == 'f698cddc-f5f5-4dee-b3a9-db0be2d91ecb') {
        content = Colorado_6_Checklists();
    } else if (id == '22d5e679-9ba4-4da9-a7eb-7623eb27df2a') {
        content = Haliburton_Checklists();
    } else if (id == '5cfd1306-72f1-476a-bfe0-9d35787802bf') {
        content = Hudson_Bay_Checklists();
    } else if (id == 'd8278fdb-b210-47ad-8724-8e5e460fb4cd') {
        content = Larimer_County_Checklists();
    } else if (id == 'a9fbfa18-ebfa-459c-872c-b6ce5d993353') {
        content = Michigan_Checklists();
    } else if (id == 'b63e1448-68c9-4582-a717-594743dbffea') {
        content = Northbelt_Checklists();
    } else if (id == 'f10d2b4d-bc1a-4cea-a5b8-367b5eaf5bb4') {
        content = Schlumberger_Checklists();
    } else if (id == 'd645abb1-83d5-470f-be95-146cf94dc204') {
        content = West_Texas_Checklists();
    } else if (id == 'b110afe6-07ee-4e22-a4a4-81ab477f900d') {
        content = Centerpoint_Energy_North_Checklists();
    } else if (id == '0b955335-4086-47bd-b606-af8de6ba9f24') {
        content = Centerpoint_Energy_South_Checklists();
    } else if (id == '27f99e57-ed46-4fbd-bee1-54cc535128bf') {
        content = El_Paso_County_Checklists();
    } else if (id == '25747185-0c27-4afd-a0af-47f02d7e65dd') {
        content = West_TX_Xcel_Checklists();
    } else if (id == 'b30eb803-0a12-4cf6-a13d-c9f3f801db7a') {
        content = Harris_County_Checklists();
    } else if (id == '501cadda-cab4-47af-8bcc-b4462586e185') {
        content = Lubbock_Checklists();
    } else if (id == '5af35c7a-4a09-40f8-8042-153553f66257') {
        content = City_Of_Lubbock_QC();
    } else if (id == '97a25e23-bac8-47c0-a71b-d0a7d647c9f6') {
        content = Douglas_County_Checklists();
    } else if (id == 'dafbbb9f-2223-4c5f-81ca-4e3105cab6aa') {
        content = Florida_1_QC();
    } else if (id == 'ba9f6bbe-beae-46b8-aec9-9bea0f00d2a2') {
        content = Florida_2_QC();
    } else if (id == '5fe1dba7-1abf-4125-9477-3171d9ebc18e') {
        content = Florida_1_Checklists();
    } else if (id == 'e160e45e-e215-424b-932b-646033e72d15') {
        content = Florida_2_Checklists();
    } else if (id == '90437d55-f10e-49ae-a431-d7ab950088b8') {
        content = PA_Division_1_QC();
    } else if (id == '02dad4cb-a14b-4e59-b61a-117a5e958862') {
        content = PA_Division_2_QC();
    } else if (id == 'c2c14347-9917-4e33-aff5-d37b2911979c') {
        content = PA_Division_3_QC();
    } else if (id == 'cc7cd769-b8e4-4127-b40f-6791ba063f13') {
        content = PA_Division_4_QC();
    } else if (id == 'bb127e66-5dba-4868-8d84-3817d42e7b16') {
        content = PA_Division_5_QC();
    } else if (id == '8cb0dc6c-ac02-4f3e-bf39-8b7aa394e734') {
        content = PA_Division_6_QC();
    } else if (id == 'c820329c-43e5-4d4e-93f5-812f9ef80eb8') {
        content = UCD_Downtown_Checklists();
    } else if (id == '37c7f3b3-d7a5-44a6-9d95-f27f29eb8a4f') {
        content = UCD_Anschutz_Checklists();
    } else if (id == '433f123a-71dc-4734-86be-715194cb03c3') {
        content = Larimer_County_Numeric();
    } else if (id == '9e0caf59-d444-4050-953c-05c2f601458f') {
        content = Sentinal_Austin_QC();
    } else if (id == '15305c69-06ce-4ddd-b842-47782031cf95') {
        content = Sentinal_Austin_Checklist();
    } else if (id == '4fc98221-7bbc-49f4-892e-e1b3bd4763a3') {
        content = Sentinal_Nevada_Checklist();
    } else if (id == '30fe12a1-26d4-4b70-a2e9-e42dce24660b') {
        content = Houston_Airport_System_Checklist();
    } else if (id == 'a81b3917-5016-4adb-ad57-d9f0875fc623') {
        content = Houston_Airport_System_QC();
    } else if (id == '2776c13c-d804-4437-8a18-4faea2b1d44c') {
        content = INVALID_Illinois_1_QC();
    } else if (id == '70256142-265b-4956-b5e3-f0d020f16d91') {
        content = INVALID_Illinois_2_QC();
    } else if (id == 'ed123669-3d77-4819-9104-ea21cf0332a8') {
        content = INVALID_Illinois_3_QC();
    } else if (id == '05245df6-8d1e-49c6-abc1-0debc46743f7') {
        content = Illinois_1_QC();
    } else if (id == 'cdaeb06e-b8bb-4999-bd45-1d46f389dc6e') {
        content = Illinois_2_QC();
    } else if (id == '5aab91e6-ac6f-478e-b3e9-267050630d8f') {
        content = Illinois_3_QC();
    } else if (id == '61d97494-eaaf-4a42-87a1-080a224009bd') {
        content = Illinois_4_QC();
    } else if (id == 'a725e601-04c9-41ca-8e1e-7b60d36e12ce') {
        content = SC_QC_1();
    } else if (id == 'a23dd7f4-ddc4-4320-b570-3126025396c3') {
        content = SC_QC_2();
    } else if (id == 'c2659957-122e-4f70-b3d8-c9be402d1ab3') {
        content = Northeast_and_Nevada_QC();
    } else if (id == '0801dfa6-650f-4ce9-ac77-bfbd388d4cf9') {
        content = California_1_QC();
    } else if (id == '6b29284d-f284-4028-8560-560d78e59be9') {
        content = California_2_QC();
    } else if (id == 'ebfeaffd-3c88-4067-98c2-5c5efde0a384') {
        content = California_3_QC();
    } else if (id == 'a458fa78-ee09-450f-9518-a9cf0e620e65') {
        content = PA_District_3_Checklist();
    } else if (id == '0fa4390c-8d00-4ca4-b8dd-80b57df87ad1') {
        content = Harris_County_QC();
    } else if (id == '14d4eaeb-8f0b-4c5d-a421-ebbf60d26091') {
        content = HCTRA_QC();
    } else if (id == '58e602b9-77cb-479b-b5dd-42a930cfb97c') {
        content = SNL_QC();
    } else if (id == '32494c6c-a482-44c8-a6c4-c8df5703ebf0') {
        content = QC_Florida_1_Residential();
    } else if (id == 'd1d29e01-2443-4474-8933-e07787007725') {
        content = QC_Florida_2_Residential();
    } else if (id == 'e3551228-978e-49e4-8f51-244f558cb182') {
        content = MI_QC();
    }

    return content;
}

export default custom_form_lookup;
