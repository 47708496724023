import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {Json_Input, TextInput, Select} from 'library';
import jsonLogic from 'json-logic-js';

class If extends Component {
    static component_name = 'If';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <Json_Input label={'Logic'} name={'logic'} />,
                <Json_Input label={'Anti Logic'} name={'anti_logic'} />,
                <Json_Input label={'Json Logic'} name='json_logic' />,
                <Select label={'Override'} name='override' boolean={true} />,
            ],
            can_have_children: true,
        }
    }

    componentDidMount() {
        //Subscribe to all variables in logic
        if (this.props.logic) {
            for (var i in this.props.logic){
                window.cmState.subscribe(this, this.props.logic[i][0]);
                window.cmState.subscribe(this, this.props.logic[i][1]);
            }
        }
        if (this.props.anti_logic) {
            for (var i in this.props.anti_logic){
                window.cmState.subscribe(this, this.props.anti_logic[i][0]);
                window.cmState.subscribe(this, this.props.anti_logic[i][1]);
            }
        }
    }

    componentWillUnmount() {
        //Subscribe to all variables in logic
        //THIS SHOULD BE MORE CONSICE!!!!!
        if (this.props.logic) {
            for (var i in this.props.logic){
                window.cmState.unsubscribe(this, this.props.logic[i][0]);
                window.cmState.unsubscribe(this, this.props.logic[i][1]);
            }
        }
        if (this.props.anti_logic) {
            for (var i in this.props.anti_logic){
                window.cmState.unsubscribe(this, this.props.anti_logic[i][0]);
                window.cmState.unsubscribe(this, this.props.anti_logic[i][1]);
            }
        }
    }

    render() {
        var logic = false;
        var failed = false;

        var logic_list;
        var anti_logic;
        if (this.props.logic) {
            logic_list = resolveVariables(this.props.logic, window.cmState.getGlobalState(this));
            logic_list = resolveVariables(logic_list, {props: this.props});
        }
        else {
            logic_list = [];
        }
        if (this.props.anti_logic) {
            anti_logic = resolveVariables(this.props.anti_logic, window.cmState.getGlobalState(this));
            anti_logic = resolveVariables(anti_logic, {props: this.props});
        }
        else {
            anti_logic = [];
        }

        for (var index in logic_list) {
            var logic_check = logic_list[index][0]
            var logic_value = logic_list[index][1];
            if (logic_check == 'exists') {
                var is_empty_list = (Array.isArray(logic_value) && logic_value.length == 0);
                if (logic_value && logic_value != '' && !(String(logic_value).indexOf('{') > -1 && String(logic_value).indexOf('}') > -1) && !(failed) && !(is_empty_list)) {
                    logic = true;
                } else {
                    logic = false;
                    failed=true;
                }
            }
            else if (logic_check == 'positive') {
                var integer_value = parseInt(logic_value);
                if (!isNaN(integer_value) && integer_value >= 0) {
                    logic = true;
                } else {
                    logic = false;
                    failed=true;
                }
            }
            else if (logic_check == 'older_than'){
                // Parse date and delta objects from input
                var date = new Date(Date.parse(logic_value['date']));
                var now = new Date();
                var diff_minutes = parseInt(logic_value['delta']) * 24 * 60;
                var allowed_age = new Date(now.getTime() - diff_minutes*60000);

                if (date < allowed_age){
                    logic = true;
                } else {
                    logic = false;
                    failed=true;
                }
            }
            else {
                if (logic_check == logic_value && !(failed)) {
                    logic = true;
                } else {
                    logic = false;
                    failed=true;}
            }
        }

        if (!(failed)){
            for (var index in anti_logic) {
                var logic_check = anti_logic[index][0]
                var logic_value = anti_logic[index][1];
                if (logic_check == 'exists') {
                    if (!logic_value || logic_value == '' || (logic_value.indexOf('{') > -1 && logic_value.indexOf('}') > -1)) {
                        logic = true;
                    } else {
                        logic = false;
                        break;
                    }
                }
                else if (logic_check == 'positive') {
                    var integer_value = parseInt(logic_value);
                    if (isNaN(integer_value) || integer_value < 0) {
                        logic = true;
                    } else {
                        logic = false;
                    }
                }
                else {
                    if (logic_check == logic_value) {
                        logic = false;
                        break;
                    }
                    else {
                        logic = true;
                    }
                }
            }
        }

        if (this.props.json_logic) {
            var logic_check = resolveVariables(this.props.json_logic, this.props);
            var logic_check = resolveVariables(logic_check, window.cmState.getGlobalState(this));
            logic = jsonLogic.apply(logic_check);
        }

        if (this.props.override) {
            logic = true;
        }


        if (logic) {
            if (this.props.children) {
                return (this.props.children)
            }
            else {
                return (null)
            }
        }
        else {
            return (null);
        }
    }
}

export default If;
