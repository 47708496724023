import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {NumberInput, TextInput, CSSInput} from 'library';

class Paragraph extends React.Component {
    static component_name = 'Paragraph';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'text'} name={'text'} default={'Default Text'} />,
                <TextInput label={'Class'} name={'className'} default={''} />,
                <CSSInput label={'css'} name={'style'} default={{}} />
            ],
        }
    }

    render() {
        return (
            <p name={this.props.name} className={this.props.className} onClick={this.props.onClick} style={this.props.style || {}}>{this.props.text}</p>
        );
    }
}

export default Paragraph;
