import React, { Component } from 'react';
import {Wrapper, Container, Header, Div, Paragraph, FormWithChildren, DateTimePicker, TextInput, Select, TextArea, UserSearchInput, SignatureInput, ImageInput, Accordion} from 'library';

function get_form_elements(){
    var elements = [
		<Accordion index={6197} header={<div className={"question-card"} name={"6197_0"} excel_reference={1} style={{fontSize: '24px'}}  >
				<Paragraph text={"QUALITY CONTROL INSPECTION"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"6198_0,6201_0,6202_0,6203_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"6198_0"} label={"Select Position Below if QC Form is Completed by a Supervisor or Manager"} options={[{"text": "Supervisor", "value": "Supervisor"}, {"text": "Area Supervisor", "value": "Area Supervisor"}, {"text": "Dir/Mgr of Operations", "value": "Dir/Mgr of Operations"}, {"text": "Vice President", "value": "Vice President"}]} radio={true} excel_reference={2}  />
			<TextInput className={"question-card"} name={"6201_0"} label={"Area:"} excel_reference={5} required={true}  />
			<TextInput className={"question-card"} name={"6202_0"} label={"Completed by:"} excel_reference={6} required={true}  />
			<TextInput className={"question-card"} name={"6203_0"} label={"Supervisor"} excel_reference={7} required={true}  />
			<div className={"question-card"} name={"6204_0"} excel_reference={8}  >
				<Paragraph text={"5 \u2013 Perfect (exceeds contract requirements/no issues)   "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"4 \u2013 Good (meets contract requirements)   "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"3 \u2013 Fair (a couple {1 or 2} issues, Work Order required)  "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"2 \u2013 Poor (multiple {3 to 5} issues, Work Order required)   "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"1 \u2013 Bad (numerous {more than 5} issues, Work Order required)   "} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
		</Accordion>,
		<Accordion index={6205} header={<div className={"question-card"} name={"6205_0"} excel_reference={9} anchor={true} anchor_text={"MAIN ENTRANCE/LOBBY"} style={{fontSize: '24px'}}  >
				<Paragraph text={"MAIN ENTRANCE/LOBBY"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"6206_0,6207_0,6208_0,6209_0"} sub_total={"6214_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"6206_0"} label={"Door Glass / Side Glass - Main entrance"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={10}  />
			<Select className={"question-card"} name={"6207_0"} label={"Floors - Corners & Edges / Walk-Off Mats"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={11}  />
			<Select className={"question-card"} name={"6208_0"} label={"Trash Containers / Ash Urns"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={12}  />
			<Select className={"question-card"} name={"6209_0"} label={"Lobby Furniture & Fixtures"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={13}  />
			<TextInput className={"question-card"} name={"6210_0"} label={"COMMENTS"} excel_reference={14} fail_comment={true}  />
			<div className={"question-card"} name={"6211_0"} excel_reference={15}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"6212_0"} excel_reference={16}  />
			<Select className={"question-card"} name={"6213_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={17}  />
			<TextInput className={"question-card"} name={"6214_0"} label={"Total Rating - Maximum Score 20"} excel_reference={18} excel={'Q10+Q11+Q12+Q13'}  />
		</Accordion>,
		<Accordion index={6215} header={<div className={"question-card"} name={"6215_0"} excel_reference={19} anchor={true} anchor_text={"COMMON AREAS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"COMMON AREAS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"6216_0,6217_0,6218_0,6219_0,6220_0,6221_0,6222_0,6223_0,6224_0,6225_0"} sub_total={"6230_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"6216_0"} label={"Stairway/Ramp; Landings"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={20}  />
			<Select className={"question-card"} name={"6217_0"} label={"Interior/Exterior Glass"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={21}  />
			<Select className={"question-card"} name={"6218_0"} label={"High Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={22}  />
			<Select className={"question-card"} name={"6219_0"} label={"Low Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={23}  />
			<Select className={"question-card"} name={"6220_0"} label={"A/C Vents &amp; Return Air Vents"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={24}  />
			<Select className={"question-card"} name={"6221_0"} label={"Carpets Vacuumed / Spot Clean Stains"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={25}  />
			<Select className={"question-card"} name={"6222_0"} label={"Elevators/Tracks/Walls"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={26}  />
			<Select className={"question-card"} name={"6223_0"} label={"Hard Surface Floors (Swept, Mopped, Buffed)"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={27}  />
			<Select className={"question-card"} name={"6224_0"} label={"Corners, Edges, Baseboards"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={28}  />
			<Select className={"question-card"} name={"6225_0"} label={"Wall Spotting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={29}  />
			<TextInput className={"question-card"} name={"6226_0"} label={"COMMENTS"} excel_reference={30} fail_comment={true}  />
			<div className={"question-card"} name={"6227_0"} excel_reference={31}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"6228_0"} excel_reference={32}  />
			<Select className={"question-card"} name={"6229_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={33}  />
			<TextInput className={"question-card"} name={"6230_0"} label={"Total Rating - Maximum Score 50"} excel_reference={34} excel={'+Q20+Q21+Q22+Q23+Q24+Q25+Q26+Q27+Q28+Q29'}  />
		</Accordion>,
		<Accordion index={6231} header={<div className={"question-card"} name={"6231_0"} excel_reference={35} anchor={true} anchor_text={"OFFICES/ADMIN. AREAS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"OFFICES/ADMIN. AREAS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"6232_0,6233_0,6234_0,6235_0,6236_0,6237_0,6238_0,6239_0"} sub_total={"6244_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"6232_0"} label={"Trash Removed &amp; Liner Replaced"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={36}  />
			<Select className={"question-card"} name={"6233_0"} label={"Carpets Vacuumed &amp; Stains Spot Cleaned"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={37}  />
			<Select className={"question-card"} name={"6234_0"} label={"Vacuum Corners, Edges, &amp; Under Furniture"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={38}  />
			<Select className={"question-card"} name={"6235_0"} label={"Interior Glass"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={39}  />
			<Select className={"question-card"} name={"6236_0"} label={"High Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={40}  />
			<Select className={"question-card"} name={"6237_0"} label={"Low Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={41}  />
			<Select className={"question-card"} name={"6238_0"} label={"Blinds / Window Sills Dusted"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={42}  />
			<Select className={"question-card"} name={"6239_0"} label={"Hard Surface Floors (Swept, Mopped, Buffed)"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={43}  />
			<TextInput className={"question-card"} name={"6240_0"} label={"COMMENTS"} excel_reference={44} fail_comment={true}  />
			<div className={"question-card"} name={"6241_0"} excel_reference={45}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"6242_0"} excel_reference={46}  />
			<Select className={"question-card"} name={"6243_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={47}  />
			<TextInput className={"question-card"} name={"6244_0"} label={"Total Rating - Maximum Score 40"} excel_reference={48} excel={'+Q36+Q37+Q38+Q39+Q40+Q41+Q42+Q43'}  />
		</Accordion>,
		<Accordion index={6245} header={<div className={"question-card"} name={"6245_0"} excel_reference={49} anchor={true} anchor_text={"RESTROOMS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"RESTROOMS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"6246_0,6247_0,6248_0,6249_0,6250_0,6251_0,6252_0,6253_0,6254_0,6255_0,6256_0,6257_0,6258_0"} sub_total={"6277_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"6246_0"} label={"Trash Removed &amp; Liner Replaced"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={50}  />
			<Select className={"question-card"} name={"6247_0"} label={"Clean & Polish Stainless Steel Fixtures"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={51}  />
			<Select className={"question-card"} name={"6248_0"} label={"Replenish Tissue, Towels, &amp; Soap Dispensers"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={52}  />
			<Select className={"question-card"} name={"6249_0"} label={"Mirrors &amp; Frames"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={53}  />
			<Select className={"question-card"} name={"6250_0"} label={"Showers"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={54}  />
			<Select className={"question-card"} name={"6251_0"} label={"High Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={55}  />
			<Select className={"question-card"} name={"6252_0"} label={"Low Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={56}  />
			<Select className={"question-card"} name={"6253_0"} label={"Sinks, Faucets, &amp; Counter Tops"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={57}  />
			<Select className={"question-card"} name={"6254_0"} label={"Floors Swept, Mopped, &amp; Scrubbed"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={58}  />
			<Select className={"question-card"} name={"6255_0"} label={"Toilets - Bowls, Seats, Rim, Plumbing Fixtures"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={59}  />
			<Select className={"question-card"} name={"6256_0"} label={"Urinals - Bowl, Rim, Plumbing Fixtures"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={60}  />
			<Select className={"question-card"} name={"6257_0"} label={"Partition Walls, Doors, Etc."} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={61}  />
			<Select className={"question-card"} name={"6258_0"} label={"A/C Vents &amp; Return Air Vents"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={62}  />
			<TextInput className={"question-card"} name={"6259_0"} label={"COMMENTS"} excel_reference={63} fail_comment={true}  />
			<div className={"question-card"} name={"6260_0"} excel_reference={64}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"6261_0"} excel_reference={65}  />
			<Select className={"question-card"} name={"6262_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={66}  />
			<TextInput className={"question-card"} name={"6263_0"} label={"Total Rating - Maximum Score 65"} excel_reference={67} excel={'+Q50+Q51+Q52+Q53+Q54+Q55+Q56+Q57+Q58+Q59+Q60+Q61+Q62'}  />
			<Select className={"question-card"} name={"6265_0"} label={"Floors Swept, Mopped, Vacuum &amp; Buffed"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={69}  />
			<Select className={"question-card"} name={"6266_0"} label={"High Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={70}  />
			<Select className={"question-card"} name={"6267_0"} label={"Low Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={71}  />
			<Select className={"question-card"} name={"6268_0"} label={"Tables / Chairs"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={72}  />
			<Select className={"question-card"} name={"6269_0"} label={"A/C Vents &amp; Return Air Vents"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={73}  />
			<Select className={"question-card"} name={"6270_0"} label={"Trash Removed &amp; Liner Replaced"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={74}  />
			<Select className={"question-card"} name={"6271_0"} label={"Counter Tops, Sinks, &amp; Back Splash Areas"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={75}  />
			<Select className={"question-card"} name={"6272_0"} label={"Wall Spotting (Finger Prints, Smudges)"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={76}  />
			<TextInput className={"question-card"} name={"6273_0"} label={"COMMENTS"} excel_reference={77} fail_comment={true}  />
			<div className={"question-card"} name={"6274_0"} excel_reference={78}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"6275_0"} excel_reference={79}  />
			<Select className={"question-card"} name={"6276_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={80}  />
			<TextInput className={"question-card"} name={"6277_0"} label={"Total Rating - Maximum Score 40"} excel_reference={81} excel={'+Q69+Q70+Q71+Q72+Q73+Q74+Q75+Q76'}  />
		</Accordion>,
		<Accordion index={6278} header={<div className={"question-card"} name={"6278_0"} excel_reference={82} anchor={true} anchor_text={"CHECKLISTS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"CHECKLISTS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"6279_0"} sub_total={"6284_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"6279_0"} label={"Are the Checklists on Site and being Utilized?"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={83}  />
			<TextInput className={"question-card"} name={"6280_0"} label={"COMMENTS"} excel_reference={84} fail_comment={true}  />
			<div className={"question-card"} name={"6281_0"} excel_reference={85}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"6282_0"} excel_reference={86}  />
			<Select className={"question-card"} name={"6283_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={87}  />
			<TextInput className={"question-card"} name={"6284_0"} label={"Total Rating - Maximum Score 5"} excel_reference={88} excel={'+Q83'}  />
		</Accordion>,
		<Accordion index={6285} header={<div className={"question-card"} name={"6285_0"} excel_reference={89} anchor={true} anchor_text={"JANITOR CLOSET/STORAGE AREA"} style={{fontSize: '24px'}}  >
				<Paragraph text={"JANITOR CLOSET/STORAGE AREA"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"6286_0,6287_0,6288_0"} sub_total={"6293_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"6286_0"} label={"Mop Sink &amp; Floors"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={90}  />
			<Select className={"question-card"} name={"6287_0"} label={"Supplies/Equipment (Stored neat &amp; orderly)"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={91}  />
			<Select className={"question-card"} name={"6288_0"} label={"MSDS Binder up to date"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={92}  />
			<TextInput className={"question-card"} name={"6289_0"} label={"COMMENTS"} excel_reference={93} fail_comment={true}  />
			<div className={"question-card"} name={"6290_0"} excel_reference={94}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"6291_0"} excel_reference={95}  />
			<Select className={"question-card"} name={"6292_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={96}  />
			<TextInput className={"question-card"} name={"6293_0"} label={"Total Rating - Maximum Score - 15"} excel_reference={97} excel={'+Q90+Q91+Q92'}  />
			<Select className={"question-card"} name={"8921_0"} label={"DID YOU REQUEST ANY WORK ORDERS? "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={99} required={true}  />
		</Accordion>,
		<Accordion index={6296} header={<div className={"question-card"} name={"6296_0"} excel_reference={101} anchor={true} anchor_text={"ADDITIONAL SERVICES"} style={{fontSize: '24px'}}  >
				<Paragraph text={"ADDITIONAL SERVICES"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"6298_0,6299_0,6300_0,6301_0,6302_0,6303_0,6304_0,6305_0,6306_0,6307_0,6308_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<div className={"question-card"} name={"6297_0"} excel_reference={102}  >
				<Paragraph text={"Can we provide additional services (TAGs)?  Can we provide additional services (TAGs)?  "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"What extra services can we suggest to make the buildings look better?  "} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<TextInput className={"question-card"} name={"6298_0"} label={"If you had extra time, what cleaning tasks or projects would you do?  "} excel_reference={103}  />
			<Select className={"question-card"} name={"6299_0"} label={"Do any areas need pressure washing?  "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={104}  />
			<TextInput className={"question-card"} name={"6300_0"} label={"If the answer to a question is yes please comment below"} excel_reference={105}  />
			<Select className={"question-card"} name={"6301_0"} label={"Do we need to shampoo carpet before periodics are due?  "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={106}  />
			<TextInput className={"question-card"} name={"6302_0"} label={"If the answer to a question is yes please comment below"} excel_reference={107}  />
			<Select className={"question-card"} name={"6303_0"} label={"Do we need to strip &amp; wax the floors before periodics are due?  "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={108}  />
			<TextInput className={"question-card"} name={"6304_0"} label={"If the answer to a question is yes please comment below"} excel_reference={109}  />
			<Select className={"question-card"} name={"6305_0"} label={"Do we need to provide any exterior window cleaning?  "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={110}  />
			<TextInput className={"question-card"} name={"6306_0"} label={"If the answer to a question is yes please comment below"} excel_reference={111}  />
			<Select className={"question-card"} name={"6307_0"} label={"Can we provide a maintenance technician?  "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={112}  />
			<TextInput className={"question-card"} name={"6308_0"} label={"If the answer to a question is yes please comment below"} excel_reference={113}  />
		</Accordion>,
		<TextInput className={"question-card"} name={"6294_0"} label={"RATING TOTAL"} excel_reference={98} anchor={true} anchor_text={"RATING TOTAL"} excel={'+Q18+Q34+Q48+Q67+Q81+Q88+Q97'}  />,
		<TextInput className={"question-card"} name={"6295_0"} label={"Percentage"} excel_reference={100} excel={'+Q98/235*100'}  />,
    ];

    return elements;
}

export default get_form_elements;
