import React, { Component } from 'react';
import {Wrapper, Container, Header, Div, Paragraph, FormWithChildren, DateTimePicker, TextInput, Select, TextArea, UserSearchInput, SignatureInput, ImageInput, Accordion} from 'library';

function get_form_elements(){
    var elements = [
		<Accordion index={13060} header={<div className={"question-card"} name={"13060_0"} excel_reference={1} style={{fontSize: '24px'}}  >
				<Paragraph text={"Carpet Cleaning"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"13078_0,13079_0,13061_0,13062_0,13063_0,13064_0,13065_0,13066_0,13067_0,13068_0"} sub_total={"13068_0"} className={"col-12"} style={{padding:"0px"}} >
			<TextInput className={"question-card"} name={"13078_0"} label={"Completed By:"} excel_reference={3}  />
			<TextInput className={"question-card"} name={"13079_0"} label={"Supervisor:"} excel_reference={4}  />
			<Select className={"question-card"} name={"13061_0"} label={"Was area vacuumed before carpet cleaning?"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={5}  />
			<Select className={"question-card"} name={"13062_0"} label={"Was furniture removed from the area (if applicable)?"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={6}  />
			<Select className={"question-card"} name={"13063_0"} label={"Was area pre-sprayed with approved chemicals?"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={7}  />
			<Select className={"question-card"} name={"13064_0"} label={"Were extraction and disinfection performed?"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={8}  />
			<Select className={"question-card"} name={"13065_0"} label={"Was furniture moved back to the correct location (if applicable)?"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={9}  />
			<Select className={"question-card"} name={"13066_0"} label={"Is the carpet free of any spots and stains?"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={10}  />
			<Select className={"question-card"} name={"13067_0"} label={"Are carpets dry in all areas?"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={11}  />
			<TextInput className={"question-card"} name={"13068_0"} label={"Total Rating - Maximum Score - 7"} excel_reference={12} excel={'+Q8+Q9+Q10+Q11+Q12+Q13+Q14'}  />
		</Accordion>,
		<TextInput className={"question-card"} name={"13069_0"} label={"Score"} excel_reference={13} excel={'(+Q8+Q9+Q10+Q11+Q12+Q13+Q14)/7*100'}  />,
    ];

    return elements;
}

export default get_form_elements;
