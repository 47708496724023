import React, { Component } from 'react';
import {Wrapper, Container, Header, Div, Paragraph, FormWithChildren, DateTimePicker, TextInput, Select, TextArea, UserSearchInput, SignatureInput, ImageInput, Accordion} from 'library';

function get_form_elements(){
    var elements = [
		<Accordion index={10758} header={<div className={"question-card"} name={"10758_0"} excel_reference={1} style={{fontSize: '24px'}}  >
				<Paragraph text={"Plugging- In Scorecard"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"10759_0,10760_0,10761_0,10762_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<TextInput className={"question-card"} name={"10759_0"} label={"Name"} excel_reference={2}  />
			<TextInput className={"question-card"} name={"10760_0"} label={"Region"} excel_reference={3}  />
			<DateTimePicker className={"question-card"} name={"10761_0"} label={"Date"} excel_reference={4}  />
			<UserSearchInput className={"question-card"} name={"10762_0"} label={"Type and then CLICK on your SUPERVISOR's e-mail address here"} excel_reference={5}  />
		</Accordion>,
		<Accordion index={10763} header={<div className={"question-card"} name={"10763_0"} excel_reference={6} style={{fontSize: '24px'}}  >
				<Paragraph text={"Plugging-In Score"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"10765_0,10766_0,10767_0,10769_0,10770_0,10771_0,10773_0,10774_0,10775_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<div className={"question-card"} name={"10764_0"} excel_reference={7} style={{fontSize: '14px'}}  >
				<Paragraph text={"Budget Compliance"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"10765_0"} label={"Director - Continually advocates for positive change by leading their direct reports to maintain compliance - and holds direct reports in assigned region(s) accountable to maintaining compliance - with all budgets (labor, supplies, equipment, etc.), approves POs, overtime, and requests for repairs, purchases, and vehicle maintenance.  Plugs direct reports in to the Gap Process (with documentation on WLM). "} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={8}  />
			<Select className={"question-card"} name={"10766_0"} label={"Supervisor - Continually advocates for positive change by leading their direct reports to maintain compliance - and holds direct reports in assigned region(s) accountable to maintaining compliance - with all budgets (labor, supplies, equipment, etc.), approves POs, overtime, and requests for repairs, purchases, and vehicle maintenance.  Plugs direct reports in to the Gap Process (with documentation on WLM). "} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={9}  />
			<TextArea className={"question-card"} name={"10767_0"} label={"Comments"} excel_reference={10}  />
			<div className={"question-card"} name={"10768_0"} excel_reference={11} style={{fontSize: '14px'}}  >
				<Paragraph text={"Contract Compliance"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"10769_0"} label={"Director - Continually advocates for positive change by holding direct reports accountable to meeting all contractual requirements via our Systems, Processes, and Tools (SPTs), creating &amp; assigning work orders, and ensuring compliance with green program implementation/execution when applicable."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={12}  />
			<Select className={"question-card"} name={"10770_0"} label={"Supervisor - Continually advocates for positive change by holding direct reports accountable to meeting all contractual requirements via our Systems, Processes, and Tools (SPTs), creating &amp; assigning work orders, and ensuring compliance with green program implementation/execution when applicable."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={13}  />
			<TextArea className={"question-card"} name={"10771_0"} label={"Comments"} excel_reference={14}  />
			<div className={"question-card"} name={"10772_0"} excel_reference={15} style={{fontSize: '14px'}}  >
				<Paragraph text={"Organizational Management"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"10773_0"} label={"Director - Continually advocates for positive change by Plugging In direct reports to staffing process and related SPTs ensuring staffing levels in assigned region(s) meet Organizational Standards with clear reporting lines and defined areas of responsibility by position. Holds direct reports accountable to conducting all Supervisor and Lead certification trainings and holding employees accountable to performance standards detailed in their job description."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={16}  />
			<Select className={"question-card"} name={"10774_0"} label={"Supervisor - Continually advocates for positive change by Plugging In direct reports to staffing process and related SPTs ensuring staffing levels in assigned region(s) meet Organizational Standards with clear reporting lines and defined areas of responsibility by position. Holds direct reports accountable to conducting all Supervisor and Lead certification trainings and holding employees accountable to performance standards detailed in their job description."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={17}  />
			<TextArea className={"question-card"} name={"10775_0"} label={"Comments"} excel_reference={18}  />
		</Accordion>,
		<Accordion index={10780} header={<div className={"question-card"} name={"10780_0"} excel_reference={19} style={{fontSize: '24px'}}  >
				<Paragraph text={"Own Customer Management Score"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"10782_0,10783_0,10784_0,10786_0,10787_0,10788_0,10790_0,10791_0,10792_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<div className={"question-card"} name={"10781_0"} excel_reference={20} style={{fontSize: '14px'}}  >
				<Paragraph text={"Customer Satisfaction"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"10782_0"} label={"Director - Continually advocates for positive change by looking for additional survey promoters, passive responders, and detractors and adding them to our customer contact database."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={21}  />
			<Select className={"question-card"} name={"10783_0"} label={"Supervisor - Continually advocates for positive change by looking for additional survey promoters, passive responders, and detractors and adding them to our customer contact database."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={22}  />
			<TextArea className={"question-card"} name={"10784_0"} label={"Comments"} excel_reference={23}  />
			<div className={"question-card"} name={"10785_0"} excel_reference={24} style={{fontSize: '14px'}}  >
				<Paragraph text={"Account Changes"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"10786_0"} label={"Director - Continually advocates for positive change by coordinating and supporting direct reports in the phase in/out of accounts throughout the Enterprise advocating for the robust use of the associated SPTs, submits SOA changes timely, and holds direct reports accountable to providing timely notice of account changes in the assigned region(s)."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={25}  />
			<Select className={"question-card"} name={"10787_0"} label={"Supervisor - Continually advocates for positive change by coordinating and supporting direct reports in the phase in/out of accounts throughout the Enterprise advocating for the robust use of the associated SPTs, submits SOA changes timely, and holds direct reports accountable to providing timely notice of account changes in the assigned region(s)."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={26}  />
			<TextArea className={"question-card"} name={"10788_0"} label={"Comments"} excel_reference={27}  />
			<div className={"question-card"} name={"10789_0"} excel_reference={28} style={{fontSize: '14px'}}  >
				<Paragraph text={"Collaboration Score"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"10790_0"} label={"Director - Coordinates and supports phase in/out of accounts in assigned region, submits SOA changes timely, and holds direct reports accountable to providing timely notice of account changes in the assigned region(s)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={29}  />
			<Select className={"question-card"} name={"10791_0"} label={"Supervisor - Coordinates and supports phase in/out of accounts in assigned region, submits SOA changes timely, and holds direct reports accountable to providing timely notice of account changes in the assigned region(s)"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={30}  />
			<TextArea className={"question-card"} name={"10792_0"} label={"Comments"} excel_reference={31}  />
		</Accordion>,
		<Accordion index={10793} header={<div className={"question-card"} name={"10793_0"} excel_reference={32} style={{fontSize: '24px'}}  >
				<Paragraph text={"Collaboration "} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"10795_0,10796_0,10797_0,10799_0,10800_0,10801_0,10803_0,10804_0,10805_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<div className={"question-card"} name={"10794_0"} excel_reference={33} style={{fontSize: '14px'}}  >
				<Paragraph text={"Weekly LEADERSHIP Meetings (WLMs)"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"10795_0"} label={"Director - Continually advocates for positive change by holding direct reports accountable to plugging in as an active participants, trains and collaborates with direct reports leading them to be plugged in to Enterprise SPTs, thereby promoting their meaningful engagement in WLM (active participation &amp; collaboration rather than checking the boxes)."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={34}  />
			<Select className={"question-card"} name={"10796_0"} label={"Supervisor - Continually advocates for positive change by holding direct reports accountable to plugging in as an active participants, trains and collaborates with direct reports leading them to be plugged in to Enterprise SPTs, thereby promoting their meaningful engagement in WLM (active participation &amp; collaboration rather than checking the boxes)."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={35}  />
			<TextArea className={"question-card"} name={"10797_0"} label={"Comments"} excel_reference={36}  />
			<div className={"question-card"} name={"10798_0"} excel_reference={37} style={{fontSize: '14px'}}  >
				<Paragraph text={"Supervision & Oversight of Supervisors"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"10799_0"} label={"Director - Continually advocates for positive change by using the Plugged In Scorecard with each Supervisor to promote training and coaching to further plug them in to Enterprise SPTs, provides &amp; receives mid-year and annual feedback, advocates for direct reports to plug in and take ownership of staffing and hiring SPTs. Approves time off requests for direct reports, approves standard ECs and terminations with HR assistance, escalates non-standard ECs and terminations to DO as appropriate. Plugs into the daily call process by conducting regular review and update of questions reviewed in Daily Calls throughout assigned region(s) - and holds direct reports accountable to the same review/update."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={38}  />
			<Select className={"question-card"} name={"10800_0"} label={"Supervisor - Continually advocates for positive change by using the Plugged In Scorecard with each Supervisor to promote training and coaching to further plug them in to Enterprise SPTs, provides &amp; receives mid-year and annual feedback, advocates for direct reports to plug in and take ownership of staffing and hiring SPTs. Approves time off requests for direct reports, approves standard ECs and terminations with HR assistance, escalates non-standard ECs and terminations to DO as appropriate. Plugs into the daily call process by conducting regular review and update of questions reviewed in Daily Calls throughout assigned region(s) - and holds direct reports accountable to the same review/update."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={39}  />
			<TextArea className={"question-card"} name={"10801_0"} label={"Comments"} excel_reference={40}  />
			<div className={"question-card"} name={"10802_0"} excel_reference={41} style={{fontSize: '14px'}}  >
				<Paragraph text={"Raise the Flag "} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"10803_0"} label={"Director - Continually advocates for positive change by creating RTFs and follows up on RTFs and final RCA/ICA. Plugs direct reports in to RTFs by advocating for their personal creation and commitment to actions through the RTF system. Verifies direct reports are plugged in by assessing results related to RTF minimum/maximum quantity goals, with RTFs filed and closed timely. Supports direct reports in creation of high quality IFs, RCAs, ICAs to ensure timely issue resolution and prevent recurrence."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={42}  />
			<Select className={"question-card"} name={"10804_0"} label={"Supervisor - Continually advocates for positive change by creating RTFs and follows up on RTFs and final RCA/ICA. Plugs direct reports in to RTFs by advocating for their personal creation and commitment to actions through the RTF system. Verifies direct reports are plugged in by assessing results related to RTF minimum/maximum quantity goals, with RTFs filed and closed timely. Supports direct reports in creation of high quality IFs, RCAs, ICAs to ensure timely issue resolution and prevent recurrence."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={43}  />
			<TextArea className={"question-card"} name={"10805_0"} label={"Comments"} excel_reference={44}  />
		</Accordion>,
		<Accordion index={10806} header={<div className={"question-card"} name={"10806_0"} excel_reference={45} style={{fontSize: '24px'}}  >
				<Paragraph text={"LEADERSHIP"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"10808_0,10809_0,10810_0,10812_0,10813_0,10814_0,10823_0,10824_0,10825_0,10826_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<div className={"question-card"} name={"10807_0"} excel_reference={46} style={{fontSize: '14px'}}  >
				<Paragraph text={"LEADERSHIP Development"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"10808_0"} label={"Director - Continually advocates for positive change by leading the team to embrace & apply the LEADERSHIP Development section of the Strategic Plan to each and every task, coaches and develops Supervisors, advocating to get direct reports plugged in. Use the comments space below to list the LEADERSHIP Development Trainings you conducted with your direct reports since we met last. "} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={47}  />
			<Select className={"question-card"} name={"10809_0"} label={"Supervisor - Continually advocates for positive change by leading the team to embrace & apply the LEADERSHIP Development section of the Strategic Plan to each and every task, coaches and develops Supervisors, advocating to get direct reports plugged in. Use the comments space below to list the LEADERSHIP Development Trainings you conducted with your direct reports since we met last. "} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={48}  />
			<TextArea className={"question-card"} name={"10810_0"} label={"Comments"} excel_reference={49}  />
			<div className={"question-card"} name={"10811_0"} excel_reference={50} style={{fontSize: '14px'}}  >
				<Paragraph text={"LEADERSHIP Assessments"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"10812_0"} label={"Director - Continually advocates for positive change by conducting LEADERSHIP assessments and advocating that direct reports ensure we have the right people (yes - plug them in, no - develop or replace)."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={51}  />
			<Select className={"question-card"} name={"10813_0"} label={"Supervisor - Continually advocates for positive change by conducting LEADERSHIP assessments and advocating that direct reports ensure we have the right people (yes - plug them in, no - develop or replace)."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={52}  />
			<TextArea className={"question-card"} name={"10814_0"} label={"Comments"} excel_reference={53}  />
			<Select className={"question-card"} name={"10824_0"} label={"Director - Continually advocates for positive change at a quick, yet sustainable pace by plugging in to our SPTs and advocating for direct reports to be further plugged in to our SPTs. Use the comments space below to list the LEADERSHIP demonstrated since your last scorecard. Must truly be examples of lasting positive change."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={55}  />
			<Select className={"question-card"} name={"10825_0"} label={"Supervisor - Continually advocates for positive change at a quick, yet sustainable pace by plugging in to our SPTs and advocating for direct reports to be further plugged in to our SPTs. Use the comments space below to list the LEADERSHIP demonstrated since your last scorecard. Must truly be examples of lasting positive change."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={56}  />
			<TextArea className={"question-card"} name={"10826_0"} label={"Comments"} excel_reference={57}  />
		</Accordion>,
		<Accordion index={10815} header={<div className={"question-card"} name={"10815_0"} excel_reference={58} style={{fontSize: '24px'}}  >
				<Paragraph text={"Strategic Plan Awareness"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"10817_0,10818_0,10819_0,10820_0,10821_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<div className={"question-card"} name={"10816_0"} excel_reference={59} style={{fontSize: '14px'}}  >
				<Paragraph text={"Meeting or Exceeding  Established Goals (by assigned region(s)/customer(s)/direct report(s)"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"10817_0"} label={"Director - Make Area Supervisors/Supervisors, Leads, and Foreman aware of how they contribute to the accomplishment of their applicable Strategic Plan goals.Assigned region(s) and direct reports meet or exceed all goals established in the annual Strategic Plan including growth, operating margin, plugging in, and customer satisfaction."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={60}  />
			<Select className={"question-card"} name={"10818_0"} label={"Supervisor - Make Area Supervisors/Supervisors, Leads, and Foreman aware of how they contribute to the accomplishment of their applicable Strategic Plan goals.Assigned region(s) and direct reports meet or exceed all goals established in the annual Strategic Plan including growth, operating margin, plugging in, and customer satisfaction."} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={61}  />
			<TextArea className={"question-card"} name={"10819_0"} label={"Comments"} excel_reference={62}  />
			<TextInput className={"question-card"} name={"10820_0"} label={"Average Directors Score"} excel_reference={63} excel={'(+Q8+Q12+Q16+Q21+Q25+Q29+Q34+Q38+Q42+Q47+Q51+Q55+Q60)/13'}  />
			<TextInput className={"question-card"} name={"10821_0"} label={"Average Supervisor Score"} excel_reference={64} excel={'(+Q9+Q13+Q17+Q22+Q26+Q30+Q35+Q39+Q43+Q48+Q52+Q56+Q61)/13'}  />
		</Accordion>,
    ];

    return elements;
}

export default get_form_elements;
