import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {Select} from 'library';
import $ from 'jquery';

import {
  isBrowser,
  isTablet,
  isMobile,
} from "react-device-detect";

var display_options = [
    {'text':'', value:''},
    {'text':'Desktop/Laptop', value:'browser'},
    {'text':'Tablet', value:'tablet'},
    {'text':'Mobile', value:'mobile'},
]

class DisplaySize extends React.Component {
    static component_name = 'DisplaySize';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <Select label={'Display Type'} name={'display_type'} options={display_options} multiple={true} default={[]} />,
            ],
            can_have_children: true,
        }

        this.state = {width: $(window).width(), height: $(window).height()}
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    updateDimensions() {
        this.setState({width: $(window).width(), height: $(window).height()});
    }

    componentWillMount() {
        this.updateDimensions();
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    render() {
        var device_type = null;

        if (isBrowser) {
            var device_type = 'browser'
            if (this.state.width < 1025) {
                device_type = 'tablet'
            }
            if (this.state.width < 481) {
                device_type = 'mobile'
            }

            console.log("Check Device", device_type, this.props.display_type)
        }
        else if (isTablet) {
            device_type = 'tablet'
        }
        else if (isMobile) {
            device_type = 'mobile'
        }

        if (this.props.display_type && this.props.display_type.indexOf(device_type) > -1) {
            return (this.props.children);
        }
        else {
            return (null);
        }
    }
}


export default DisplaySize;
