import React, { Component } from 'react';
import {Wrapper, Container, Header, Div, Paragraph, FormWithChildren, DateTimePicker, TextInput, Select, TextArea, UserSearchInput, SignatureInput, ImageInput, Accordion} from 'library';

function get_form_elements(){
    var elements = [
		<Accordion index={8933} header={<div className={"question-card"} name={"8933_0"} excel_reference={1} style={{fontSize: '24px'}}  >
				<Paragraph text={"Quality Control Inspection"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"8934_0,8937_0,8938_0,8939_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"8934_0"} label={"Select Position Below If QC Form is Completed by a Supervisor or Manager"} options={[{"text": "Supervisor", "value": "Supervisor"}, {"text": "Area Supervisor", "value": "Area Supervisor"}, {"text": "Dir/Mgr of Operations", "value": "Dir/Mgr of Operations"}, {"text": "Vice President", "value": "Vice President"}]} radio={true} excel_reference={2}  />
			<TextInput className={"question-card"} name={"8937_0"} label={"Area"} excel_reference={5} required={true}  />
			<TextInput className={"question-card"} name={"8938_0"} label={"Completed by:"} excel_reference={6} required={true}  />
			<TextInput className={"question-card"} name={"8939_0"} label={"Supervisor"} excel_reference={7} required={true}  />
			<div className={"question-card"} name={"8940_0"} excel_reference={8}  >
				<Paragraph text={"5 \u2013 Perfect (exceeds contract requirements/no issues)  "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"4 \u2013 Good (meets contract requirements)  "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"3 \u2013 Fair (a couple {1 or 2} issues, Work Order required)  "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"2 \u2013 Poor (multiple {3 to 5} issues, Work Order required)  "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"1 \u2013 Bad (numerous {more than 5} issues, Work Order required)  "} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
		</Accordion>,
		<Accordion index={8941} header={<div className={"question-card"} name={"8941_0"} excel_reference={9} anchor={true} anchor_text={"MAIN ENTRANCE / LOBBY / ELEVAT"} style={{fontSize: '24px'}}  >
				<Paragraph text={"MAIN ENTRANCE / LOBBY / ELEVATORS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"8942_0,8943_0,8944_0,8945_0,8946_0,8947_0,8948_0,8949_0,8950_0,8951_0,8952_0"} sub_total={"8957_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"8942_0"} label={"Door Glass / Side Glass - Main entrance"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={10}  />
			<Select className={"question-card"} name={"8943_0"} label={"Walk Off Mats / Corners & Edges"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={11}  />
			<Select className={"question-card"} name={"8944_0"} label={"Floors - Sweep, Mop, Buff & Vacuum"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={12}  />
			<Select className={"question-card"} name={"8945_0"} label={"Carpets Vacuumed  / Spot Clean Stains"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={13}  />
			<Select className={"question-card"} name={"8946_0"} label={"Main Elevator Cabs - Interior & Exterior Directional Plates, Tracks"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={14}  />
			<Select className={"question-card"} name={"8947_0"} label={"Escalators & Landings"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={15}  />
			<Select className={"question-card"} name={"8948_0"} label={"Ice Machine"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={16}  />
			<Select className={"question-card"} name={"8949_0"} label={"High Low Dusting / A/C Vents"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={17}  />
			<Select className={"question-card"} name={"8950_0"} label={"Trash Containers / Ash Urns"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={18}  />
			<Select className={"question-card"} name={"8951_0"} label={"Lobby Furniture & Fixtures"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={19}  />
			<Select className={"question-card"} name={"8952_0"} label={"Building Exterior (police area)"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={20}  />
			<TextInput className={"question-card"} name={"8953_0"} label={"COMMENTS"} excel_reference={21} fail_comment={true}  />
			<div className={"question-card"} name={"8954_0"} excel_reference={22}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"8955_0"} excel_reference={23}  />
			<Select className={"question-card"} name={"8956_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={24}  />
			<TextInput className={"question-card"} name={"8957_0"} label={"Total Rating - Maximum Score - 55"} excel_reference={25} excel={'+Q10+Q11+Q12+Q13+Q14+Q15+Q16+Q17+Q18+Q19+Q20'}  />
		</Accordion>,
		<Accordion index={8958} header={<div className={"question-card"} name={"8958_0"} excel_reference={26} anchor={true} anchor_text={"COMMON AREAS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"COMMON AREAS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"8959_0,8960_0,8961_0,8962_0,8963_0,8964_0,8965_0,8966_0,8967_0"} sub_total={"8972_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"8959_0"} label={"Drinking Fountains         "} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={27}  />
			<Select className={"question-card"} name={"8960_0"} label={"Stairway"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={28}  />
			<Select className={"question-card"} name={"8961_0"} label={"Elevators, Cabs, Tracks, Panels"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={29}  />
			<Select className={"question-card"} name={"8962_0"} label={"Telephones"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={30}  />
			<Select className={"question-card"} name={"8963_0"} label={"Interior Glass"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={31}  />
			<Select className={"question-card"} name={"8964_0"} label={"High Low Dusting / A/C Vents"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={32}  />
			<Select className={"question-card"} name={"8965_0"} label={"Floors - Sweep, Mop, Buff & Vacuum"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={33}  />
			<Select className={"question-card"} name={"8966_0"} label={"Carpets Vacuumed  / Spot Clean Stains"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={34}  />
			<Select className={"question-card"} name={"8967_0"} label={"Wall Spotting / Corners & Edges"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={35}  />
			<TextInput className={"question-card"} name={"8968_0"} label={"COMMENTS"} excel_reference={36} fail_comment={true}  />
			<div className={"question-card"} name={"8969_0"} excel_reference={37}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"8970_0"} excel_reference={38}  />
			<Select className={"question-card"} name={"8971_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={39}  />
			<TextInput className={"question-card"} name={"8972_0"} label={"Total Rating - Maximum Score - 45"} excel_reference={40} excel={'+Q27+Q28+Q29+Q30+Q31+Q32+Q33+Q34+Q35'}  />
		</Accordion>,
		<Accordion index={8973} header={<div className={"question-card"} name={"8973_0"} excel_reference={41} anchor={true} anchor_text={"OFFICES / CONFERENCE ROOMS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"OFFICES / CONFERENCE ROOMS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"8974_0,8975_0,8976_0,8977_0,8978_0,8979_0,8980_0,8981_0,8982_0,8983_0,8984_0"} sub_total={"8989_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"8974_0"} label={"Trash Removed & Liner Replaced"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={42}  />
			<Select className={"question-card"} name={"8975_0"} label={"Carpets Vacuumed  / Spot Clean Stains"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={43}  />
			<Select className={"question-card"} name={"8976_0"} label={"Floors - Sweep, Mop, Buff & Vacuum"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={44}  />
			<Select className={"question-card"} name={"8977_0"} label={"Vacuum / Sweep Corners, Edges, & Under Furniture"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={45}  />
			<Select className={"question-card"} name={"8978_0"} label={"Interior Glass"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={46}  />
			<Select className={"question-card"} name={"8979_0"} label={"Telephones"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={47}  />
			<Select className={"question-card"} name={"8980_0"} label={"High Low Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={48}  />
			<Select className={"question-card"} name={"8981_0"} label={"Blinds / Window Sills Dusted"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={49}  />
			<Select className={"question-card"} name={"8982_0"} label={"Furniture, File Cabinets, Counter Tops, Etc."} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={50}  />
			<Select className={"question-card"} name={"8983_0"} label={"Wall Spotting (Finger Prints, Smudges, Etc.)"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={51}  />
			<Select className={"question-card"} name={"8984_0"} label={"A/C Vents & Return Air Vents"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={52}  />
			<TextInput className={"question-card"} name={"8985_0"} label={"COMMENTS"} excel_reference={53} fail_comment={true}  />
			<div className={"question-card"} name={"8986_0"} excel_reference={54}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"8987_0"} excel_reference={55}  />
			<Select className={"question-card"} name={"8988_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={56}  />
			<TextInput className={"question-card"} name={"8989_0"} label={"Total Rating - Maximum Score - 55"} excel_reference={57} excel={'+Q42+Q43+Q44+Q45+Q46+Q47+Q48+Q49+Q50+Q51+Q52'}  />
		</Accordion>,
		<Accordion index={8990} header={<div className={"question-card"} name={"8990_0"} excel_reference={58} anchor={true} anchor_text={"REST ROOMS / LOCKER ROOMS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"REST ROOMS / LOCKER ROOMS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"8991_0,8992_0,8993_0,8994_0,8995_0,8996_0,8997_0,8998_0,8999_0,9000_0,9001_0"} sub_total={"9006_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"8991_0"} label={"Trash Removed & Liner Replaced"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={59}  />
			<Select className={"question-card"} name={"8992_0"} label={"Clean & Polish Stainless Steel Fixtures"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={60}  />
			<Select className={"question-card"} name={"8993_0"} label={"Replenish Tissue, Towels, & Soap Dispensers"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={61}  />
			<Select className={"question-card"} name={"8994_0"} label={"Mirrors & Frames"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={62}  />
			<Select className={"question-card"} name={"8995_0"} label={"High & Low Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={63}  />
			<Select className={"question-card"} name={"8996_0"} label={"Sinks, Faucets, & Counter Tops"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={64}  />
			<Select className={"question-card"} name={"8997_0"} label={"Floors Swept, Mopped"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={65}  />
			<Select className={"question-card"} name={"8998_0"} label={"Toilets / Urinals - Bowls, Seats, Rim, Plumbing Fixtures"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={66}  />
			<Select className={"question-card"} name={"8999_0"} label={"Partition Walls, Doors, Etc."} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={67}  />
			<Select className={"question-card"} name={"9000_0"} label={"A/C Vents & Return Air Vents"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={68}  />
			<Select className={"question-card"} name={"9001_0"} label={"Do the shower curtains need to be replaced? "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={69}  />
			<TextInput className={"question-card"} name={"9002_0"} label={"COMMENTS"} excel_reference={70} fail_comment={true}  />
			<div className={"question-card"} name={"9003_0"} excel_reference={71}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"9004_0"} excel_reference={72}  />
			<Select className={"question-card"} name={"9005_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={73}  />
			<TextInput className={"question-card"} name={"9006_0"} label={"Total Rating - Maximum Score - 50"} excel_reference={74} excel={'+Q59+Q60+Q61+Q62+Q63+Q64+Q65+Q66+Q67+Q68'}  />
		</Accordion>,
		<Accordion index={9007} header={<div className={"question-card"} name={"9007_0"} excel_reference={75} anchor={true} anchor_text={" BREAK ROOMS / CAFETERIA"} style={{fontSize: '24px'}}  >
				<Paragraph text={" BREAK ROOMS / CAFETERIA"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"9008_0,9009_0,9010_0,9011_0,9012_0,9013_0,9014_0"} sub_total={"9019_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"9008_0"} label={"Floors Swept, Mopped, Vacuum & Buff"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={76}  />
			<Select className={"question-card"} name={"9009_0"} label={"High & Low Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={77}  />
			<Select className={"question-card"} name={"9010_0"} label={"Tables / Chairs"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={78}  />
			<Select className={"question-card"} name={"9011_0"} label={"A/C Vents & Return Air Vents"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={79}  />
			<Select className={"question-card"} name={"9012_0"} label={"Trash  removed & liners replaced"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={80}  />
			<Select className={"question-card"} name={"9013_0"} label={"Counter Tops, Sinks, & Back Splash Areas"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={81}  />
			<Select className={"question-card"} name={"9014_0"} label={"Wall Spotting (Finger Prints, Smudges)"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={82}  />
			<TextInput className={"question-card"} name={"9015_0"} label={"COMMENTS"} excel_reference={83} fail_comment={true}  />
			<ImageInput className={"question-card"} name={"9017_0"} excel_reference={85}  />
			<Select className={"question-card"} name={"9018_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={86}  />
			<TextInput className={"question-card"} name={"9019_0"} label={"Total Rating - Maximum Score - 35"} excel_reference={87} excel={'+Q76+Q77+Q78+Q79+Q80+Q81+Q82'}  />
		</Accordion>,
		<Accordion index={9020} header={<div className={"question-card"} name={"9020_0"} excel_reference={88} anchor={true} anchor_text={"JANITOR CLOSET / STORAGE AREA"} style={{fontSize: '24px'}}  >
				<Paragraph text={"JANITOR CLOSET / STORAGE AREA"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"9021_0,9022_0"} sub_total={"9027_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"9021_0"} label={"Mop Sink & Floors"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={89}  />
			<Select className={"question-card"} name={"9022_0"} label={"Supplies/Equipment (Stored neat & orderly)"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={90}  />
			<TextInput className={"question-card"} name={"9023_0"} label={"COMMENTS"} excel_reference={91} fail_comment={true}  />
			<div className={"question-card"} name={"9024_0"} excel_reference={92}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"9025_0"} excel_reference={93}  />
			<Select className={"question-card"} name={"9026_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={94}  />
			<TextInput className={"question-card"} name={"9027_0"} label={"Total Rating - Maximum Score - 10"} excel_reference={95} excel={'+Q89+Q90'}  />
			<Select className={"question-card"} name={"9029_0"} label={"DID YOU REQUEST ANY WORK ORDERS?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={97} required={true}  />
		</Accordion>,
		<Accordion index={9031} header={<div className={"question-card"} name={"9031_0"} excel_reference={99} anchor={true} anchor_text={"CAN WE PROVIDE ADDITIONAL SERV"} style={{fontSize: '24px'}}  >
				<Paragraph text={"CAN WE PROVIDE ADDITIONAL SERVICES (TAGS)?  What extra services can we suggest to make the buildings look better?"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"9032_0,9033_0,9034_0,9035_0,9036_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"9032_0"} label={"Do any areas need pressure washing? "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={100}  />
			<Select className={"question-card"} name={"9033_0"} label={"Do we need to shampoo carpet before periodics are due?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={101}  />
			<Select className={"question-card"} name={"9034_0"} label={"Do we need to strip & wax floor before periodics are due?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={102}  />
			<Select className={"question-card"} name={"9035_0"} label={"Do we need to provide any exterior window cleaning?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={103}  />
			<Select className={"question-card"} name={"9036_0"} label={"Can we provide a maintenance technician?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={104}  />
		</Accordion>,
		<TextInput className={"question-card"} name={"9028_0"} label={"Total Score"} excel_reference={96} anchor={true} anchor_text={"Total Score"} excel={'+Q25+Q40+Q57+Q74+Q87+Q95'}  />,
		<TextInput className={"question-card"} name={"9030_0"} label={"Percentage "} excel_reference={98} excel={'+Q96/250*100'}  />,
    ];

    return elements;
}

export default get_form_elements;
