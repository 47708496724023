import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {TextInput, Select, CSSInput, TextAutocomplete} from 'library';


class UserSearchInput extends Component {
    static component_name = 'UserSearchInput';
    constructor(props) {
        super(props);

        this.state = {
            show_options: false
        };

        this.search_input = this.search_input.bind(this);
        this.select_option = this.select_option.bind(this);
        this.blur_options = this.blur_options.bind(this);
        this.clear_search = this.clear_search.bind(this);
    }

    componentDidMount(){
        window.cmState.subscribe(this, '{user_search_options}');
    }

    search_input(e){
        var state = {};
        state[this.props.name] = e.target.value;
        this.props.setFormState(state);

        this.setState({
            show_options: true
        });
    }

    select_option(e){
        var state = {};
        state[this.props.name] = e.target.text;
        this.props.setFormState(state);
        this.clear_search();
    }

    blur_options(){
        setTimeout(
            function(){
                this.clear_search();
            }.bind(this),
            500
        );
    }

    clear_search(){
        this.setState({show_options:false});
    }

    render() {
        var options = this.props.options || [];
        var global_state = window.cmState.getGlobalState();
        var options = [];
        for (var key in global_state['user_search_options']){
            options.push(global_state['user_search_options'][key]);
        }

        var option_display = null;
        if (this.state.show_options){
            var option_list = [];

            var displayed_options = 0;
    		for (var index=0;index<options.length;index++){
                var option = options[index];

    			if (displayed_options < 100 && option != '' && option.toLowerCase().indexOf(this.props.value.toLowerCase()) >= 0){
    				displayed_options += 1;
    				option_list.push(<div class="tagItem"><a class="btn btn-default" onClick={this.select_option}>{option}</a></div>);
    			}
    		}

            option_display = <div class="completebox">{option_list}</div>;
        }

        var sneaky_style = {
            height: '1px',
            padding: '0px',
            border: 'none',
            position: 'relative',
            zIndex: '-10'
        };

        var update_functions = {
            onClick: this.search_input,
            onChange: this.search_input,
            onBlur: this.blur_options,
            onFocus: this.clear_search
        }

        var value = this.props.default;
        if (this.props.value){
            value = this.props.value;
        }

        var label = null;
        if (this.props.label && this.props.label != '') {
            var required = null;
            if (this.props.required){ required = <span style={{float: 'left', fontWeight: 'bold', color: 'red'}} >*</span>;}
            label = <label style={this.props.label_style}>{required}<p>{this.props.label}</p></label>;
        }

        var search = <div className={"form-group search_me_input " + this.props.className}>
            {label}
            <input type="text" className="form-control" name={this.props.name}  value={value} placeholder={this.props.placeholder} autocomplete="off" {...update_functions} disabled={this.props.disabled} />
            {option_display}
        </div>;

        return (search);
    }
}

export default UserSearchInput;
