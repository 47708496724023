import React, { Component } from 'react';
import {
    ajaxWrapper,
    resolveVariables,
    format_date,
    run_excel_function,
} from 'functions';

import {
    Wrapper,
    Container,
    Header,
    Div,
    Paragraph,
    FormWithChildren,
    TextInput,
    Select,
    TextArea,
    Button,
    EmptyModal,
    Alert,
    DateFormat,
} from 'library';

import Navbar from 'projectLibrary/navbar.js';
import Footer from 'projectLibrary/footer.js';

import save_form_submission from 'pages/custom_functions/save_form_submission.js';
import custom_form_lookup from 'pages/custom_functions/custom_form_lookup.js';
import $ from 'jquery';

function find_field(content, label) {
    for (let section of content) {
        if (section.props && section.props.children) {
            for (let child of section.props.children) {
                console.log(child);
                if (child.props.label == label) {
                    return child.props.name;
                }
            }
        }
    }
}

class Submission extends Component {
    constructor(props) {
        super(props);

        var now = new Date();
        var double_value = format_date(now, 'mm/dd/yyyy');

        this.state = {
            loaded: false,

            search_value: this.props.search_value,
            double_value: this.props.double_value || double_value,
            process: {
                forms: [{ processform: {} }],
            },
            submission: {
                answers: {},
            },
            users: [],
            show_required: false,

            pending_lookup: false,
            submission_in_progress: false,
        };

        this.load_process = this.load_process.bind(this);
        this.get_submission = this.get_submission.bind(this);
        this.load_submission = this.load_submission.bind(this);
        this.load_submission_failure = this.load_submission_failure.bind(this);

        this.check_for_completed_sections =
            this.check_for_completed_sections.bind(this);
        this.check_required_children = this.check_required_children.bind(this);

        this.set_search_value = this.set_search_value.bind(this);
        this.get_anchor = this.get_anchor.bind(this);
        this.jump_to_anchor = this.jump_to_anchor.bind(this);

        this.request_submit_answers = this.request_submit_answers.bind(this);
        this.submit_answers = this.submit_answers.bind(this);

        this.email_followup = this.email_followup.bind(this);
        this.submit_success = this.submit_success.bind(this);

        this.handle_error = this.handle_error.bind(this);

        this.run_all_excel_functions = this.run_all_excel_functions.bind(this);
    }

    componentDidMount() {
        ajaxWrapper(
            'GET',
            '/api/home/process/' + this.props.id + '/?related=forms,',
            {},
            this.load_process,
        );

        ajaxWrapper(
            'GET',
            '/process_users/' + this.props.id + '/',
            {},
            this.load_users,
        );

        window.cmState['subscribe_by_name'](this, 'form_answers');
    }

    load_process(value) {
        var global_state = window.cmState.getGlobalState();
        let process = value[0]['process'];

        if (!process.public && !global_state.user) {
            window.location.href = '/login/';
        }

        let form = process.forms[0].processform;

        //Load form content
        this.setState(
            {
                process: process,
                form_content: custom_form_lookup(form.id),
            },
            this.get_submission,
        );
    }

    load_users(value) {
        window.cmState.setGlobalState('user_search_options', value);
    }

    get_submission() {
        if (this.state.pending_lookup) {
            return false;
        }
        if (!this.state.search_value) {
            return false;
        }

        var global_state = window.cmState.getGlobalState();
        var user = global_state.user;
        var process = this.state.process;
        var form = process.forms[0].processform;

        var data = {
            process_id: process.id,
            form_id: form.id,
            owner_id: user.id,
            search_value: decodeURI(this.state.search_value),
            double_value: this.state.double_value,
        };

        this.setState({ pending_lookup: true }, () =>
            ajaxWrapper(
                'POST',
                '/get_or_create_submission/',
                data,
                this.load_submission,
                this.load_submission_failure,
            ),
        );
    }

    load_submission(value) {
        var global_state = window.cmState.getGlobalState();

        if (value.length > 0) {
            var answers = Object.assign(
                {},
                value[0]['formsubmission']['answers'],
            );
            var clean_answers = {};
            for (var key in answers) {
                var pieces = key.split('_');
                if (pieces.length > 2) {
                    var new_key = pieces[0] + '_' + pieces[1];
                    clean_answers[new_key] = pieces[2];
                } else {
                    clean_answers[key] = answers[key];
                }
            }

            let user_field = find_field(
                this.state.form_content,
                'Completed by:',
            );
            if (!(user_field in clean_answers)) {
                if (global_state.user.name) {
                    clean_answers[user_field] = global_state.user.name;
                } else {
                    clean_answers[user_field] = global_state.user.email;
                }
            }
            let supervisor_field = find_field(
                this.state.form_content,
                'Supervisor',
            );
            if (!(supervisor_field in clean_answers)) {
                clean_answers[supervisor_field] = global_state.user.supervisor;
            }

            this.setState({
                loaded: true,
                pending_lookup: false,
                submission: value[0]['formsubmission'],
                answers: clean_answers,
            });
        } else {
            this.load_submission_failure();
        }
    }

    load_submission_failure() {
        this.handle_error(
            'This submission does not exist or has been archived.  Please contact your administrator if this is in error.',
        );
    }

    set_search_value(state) {
        this.setState(
            { search_value: state['search_value'] },
            this.get_submission,
        );
    }

    jump_to_anchor(e) {
        var name = e.currentTarget.getAttribute('num');
        var component = $('[name=' + name + ']').eq(0);
        if (component.attr('class').indexOf('question-card') == -1) {
            component = component.closest('.question-card');
        }
        var top = component.offset().top - 185;
        window.scrollTo(0, top);
    }

    run_all_excel_functions(form_data) {
        var form_excel = form_data._form_excel;
        var updated_form_data = JSON.parse(JSON.stringify(form_data));

        for (var key in form_excel['functions']) {
            var excel_function = form_excel['functions'][key];
            var value = run_excel_function(
                excel_function,
                updated_form_data,
                form_data,
                form_data._form_excel.lookups,
            );
            updated_form_data[key] = value;
        }

        return updated_form_data;
    }

    request_submit_answers(state, form_submitted) {
        if (this.state.submission_in_progress) {
            return false;
        } else {
            this.setState(
                {
                    submission_in_progress: true,
                },
                () => this.submit_answers(state, form_submitted),
            );
        }
    }

    submit_answers(state, form_submitted) {
        var process = this.state.process;
        var form = process.forms[0].processform;

        var content = custom_form_lookup(form.id);
        content = this.check_for_completed_sections(content);
        var required_count = this.check_required_children(content);
        if (required_count > 0) {
            this.setState({
                show_required: true,
                required_count: required_count,
                submission_in_progress: false,
            });
            if (form_submitted) {
                form_submitted();
            }
            return false;
        }

        var date_string = new Date().toJSON();

        var global_state = window.cmState.getGlobalState();
        var form_data = global_state['form_answers'];
        form_data = this.run_all_excel_functions(form_data);

        var url = '/submit_form/' + this.state.submission.id + '/';
        var data = {
            answers: form_data,
            completed: true,
            completeddate: date_string,
            needs_update: true,
        };

        if (global_state.online) {
            ajaxWrapper(
                'POST',
                url,
                data,
                this.email_followup,
                this.handle_error,
            );

            this.setState(
                {
                    submission_in_progress: false,
                    answers: form_data,
                    modal_content: (
                        <div>
                            <Alert
                                type="info"
                                style={{ padding: '20px', margin: '20px' }}
                            >
                                <Header
                                    size={2}
                                    text={'Submitting Answers. Please Wait...'}
                                    style={{ textAlign: 'center' }}
                                />
                            </Alert>
                        </div>
                    ),
                },
                form_submitted,
            );
        } else {
            save_form_submission(this.state.submission.id, url, data);

            this.setState(
                {
                    submission_in_progress: false,
                    modal_content: (
                        <div>
                            <Alert
                                type="info"
                                style={{
                                    padding: '20px',
                                    margin: '20px',
                                    textAlign: 'center',
                                }}
                            >
                                <Header
                                    size={2}
                                    text={
                                        'Your answers have been recorded and can be submitted at a later time.'
                                    }
                                    style={{ textAlign: 'center' }}
                                />
                                <Button
                                    type="primary"
                                    text="Return to dashboard"
                                    href="/profile/"
                                    style={{ fontSize: '1.2rem' }}
                                />
                                <br />
                                <br />
                            </Alert>
                        </div>
                    ),
                },
                form_submitted,
            );
        }
    }

    check_required_children(context) {
        var answers = window.cmState['form_answers'];

        var required_count = 0;
        for (var index in context) {
            var child = context[index];

            if (
                child.props.required == true ||
                (child.props.options && child.props.options.length == 2)
            ) {
                if (
                    !(child.props.name in answers) ||
                    answers[child.props.name] == undefined ||
                    answers[child.props.name] === ''
                ) {
                    required_count += 1;
                }
            }

            var children = child.props.children;
            if (typeof children != 'undefined') {
                if (typeof children.length == 'undefined') {
                    children = [child.props.children];
                }
                required_count += this.check_required_children(children);
            }
        }

        return required_count;
    }

    email_followup(value) {
        var url = '/email_followup/' + this.state.submission.id + '/';
        ajaxWrapper('POST', url, {}, this.submit_success, this.handle_error);
    }

    submit_success() {
        this.setState({
            modal_content: (
                <div>
                    <Alert
                        type="success"
                        style={{
                            padding: '20px',
                            margin: '20px',
                            textAlign: 'center',
                        }}
                    >
                        <Header
                            size={2}
                            text={
                                'Your submission has been completed successfully'
                            }
                            style={{ textAlign: 'center' }}
                        />
                        <br />
                        <Button
                            type="primary"
                            text="Return to dashboard"
                            href="/profile/"
                            style={{ fontSize: '1.2rem' }}
                        />
                        <br />
                        <br />
                        <Button
                            type="default"
                            text="Review answers"
                            style={{ fontSize: '1.2rem' }}
                            href={
                                '/process/' +
                                this.props.id +
                                '/review/?search_value=' +
                                this.state.search_value +
                                '&double_value=' +
                                this.state.double_value
                            }
                        />
                    </Alert>
                </div>
            ),
        });
    }

    get_anchor(anchors, child) {
        if (child.props.anchor) {
            var anchor_text = child.props.anchor_text;
            var name = child.props.name;
            anchors.push(
                <li>
                    <Button
                        type="default"
                        onClick={this.jump_to_anchor}
                        num={name}
                        text={anchor_text}
                        style={{ display: 'block', width: '100%' }}
                    />
                </li>,
            );
        }

        return anchors;
    }

    check_for_completed_sections(content) {
        var process = this.state.process;
        var answers = window.cmState['form_answers'] || {};

        //Check for completed sections
        var new_content = [];
        for (var index = 0; index < content.length; index++) {
            var child = content[index];
            if (child.props.names) {
                var names = child.props.names.split(',');
                var required = { required: false };

                if (process.is_pass_fail) {
                    required['className'] = 'question-card hidden';

                    for (
                        var name_index = 0;
                        name_index < names.length;
                        name_index++
                    ) {
                        var name = names[name_index];
                        if (!(name in answers) || answers[name] == '0') {
                            required = { required: true };
                        }
                    }
                } else if (process.is_numeric_but_requires_comments) {
                    required['className'] = 'question-card hidden';

                    for (
                        var name_index = 0;
                        name_index < names.length;
                        name_index++
                    ) {
                        var name = names[name_index];
                        if (
                            !(name in answers) ||
                            ['4', '3', '2', '1'].indexOf(answers[name]) > -1
                        ) {
                            required = { required: true };
                        }
                    }
                }

                var new_children = [];
                for (
                    var second_index = 0;
                    second_index < child.props.children.length;
                    second_index++
                ) {
                    var grandchild = child.props.children[second_index];

                    if (grandchild.props.fail_comment) {
                        grandchild = React.cloneElement(grandchild, required);
                    }

                    if (
                        this.state.show_required &&
                        (grandchild.props.required ||
                            (grandchild.props.options &&
                                grandchild.props.options.length == 2))
                    ) {
                        if (
                            !(grandchild.props.name in answers) ||
                            answers[grandchild.props.name] == undefined ||
                            answers[grandchild.props.name] === ''
                        ) {
                            grandchild = React.cloneElement(grandchild, {
                                show_required: true,
                            });
                        }
                    }

                    new_children.push(grandchild);
                }

                child = React.cloneElement(child, { children: new_children });
            }

            new_content.push(child);
        }

        return new_content;
    }

    handle_error(value) {
        this.setState({
            modal_content: (
                <div>
                    <Alert
                        type="danger"
                        style={{ padding: '20px', margin: '20px' }}
                    >
                        <Header
                            size={2}
                            text={value}
                            style={{ textAlign: 'center' }}
                        />
                    </Alert>
                </div>
            ),
            loaded: true,
        });
    }

    render() {
        var loaded = this.state.loaded;

        var process = this.state.process;
        var form = process.forms[0].processform;
        var inlineStyle = {
            display: 'inline-block',
            verticalAlign: 'top',
        };
        var inlineStyleMargin = {
            display: 'inline-block',
            verticalAlign: 'top',
            marginRight: '15px',
        };

        var content = null;
        var search_value_form = null;
        var anchor_dropdown = null;
        var floating_submit = null;
        var submit_action = null;

        if (!form) {
            content = <Header size={2} text={'Loading . . . '} />;
        } else if (!this.state.search_value) {
            search_value_form = (
                <FormWithChildren
                    submit={this.set_search_value}
                    submit_text={'Submit'}
                >
                    <TextInput
                        className={'question-card'}
                        name="search_value"
                        label={process.search_term}
                    />
                </FormWithChildren>
            );

            if (window.cmState.params['show_term']) {
                loaded = true;
            }
        } else {
            //This is where all the custom form content comes from!
            content = this.state.form_content;
            console.log('Content', form.id, content);

            if (content) {
                var anchors = [];
                for (var index = 0; index < content.length; index++) {
                    var child = content[index];
                    anchors = this.get_anchor(anchors, child);

                    if (
                        child.props.children &&
                        child.props.children.length > 0
                    ) {
                        for (
                            var second_index = 0;
                            second_index < child.props.children.length;
                            second_index++
                        ) {
                            var grandchild = child.props.children[second_index];
                            anchors = this.get_anchor(anchors, grandchild);
                        }
                    }
                    if (child.props.header) {
                        anchors = this.get_anchor(anchors, child.props.header);
                    }
                }

                if (anchors.length > 0) {
                    anchor_dropdown = (
                        <div style={{ float: 'right' }}>
                            <button
                                class="btn btn-default dropdown-toggle"
                                type="button"
                                id="dropdownMenu"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="true"
                            >
                                Go To: <span class="caret"></span>
                            </button>
                            <ul
                                class="dropdown-menu"
                                aria-labelledby="dropdownMenu"
                            >
                                {anchors}
                            </ul>
                        </div>
                    );
                }

                content = this.check_for_completed_sections(content);

                if (this.props.review) {
                    var disabled_content = [];
                    for (var index = 0; index < content.length; index++) {
                        var child = content[index];
                        var disabled_children = [];
                        if (
                            child.props.children &&
                            child.props.children.length > 0
                        ) {
                            for (
                                var second_index = 0;
                                second_index < child.props.children.length;
                                second_index++
                            ) {
                                var grandchild =
                                    child.props.children[second_index];
                                var new_grandchild = React.cloneElement(
                                    grandchild,
                                    { disabled: 'disabled' },
                                );
                                disabled_children.push(new_grandchild);
                            }
                        }

                        var new_child = React.cloneElement(child, {
                            disabled: 'disabled',
                            children: disabled_children,
                        });
                        disabled_content.push(new_child);
                    }

                    content = disabled_content;
                }

                var review_details = null;

                floating_submit = (
                    <div
                        class="floatingsubmitbutton"
                        style={{ float: 'right' }}
                    >
                        <div
                            class="btn btn-primary server_submission_float"
                            onClick={this.request_submit_answers}
                        >
                            Submit
                        </div>
                    </div>
                );

                submit_action = this.request_submit_answers;
                if (this.props.review) {
                    let submitted_date_classname = '';
                    if (process.title == 'QC - City of Lubbock') {
                        submitted_date_classname = 'no-print';
                    }

                    review_details = (
                        <div style={inlineStyle}>
                            <div>
                                <b>
                                    {this.state.submission.search_value} -{' '}
                                    {this.state.submission.double_value}
                                </b>
                            </div>
                            <div
                                className={submitted_date_classname}
                                style={inlineStyle}
                            >
                                <div style={inlineStyleMargin}>Submitted: </div>
                                <DateFormat
                                    style={inlineStyle}
                                    text={this.state.submission.completeddate}
                                />
                            </div>
                        </div>
                    );

                    floating_submit = null;
                    submit_action = null;
                }
            }
        }

        var modal = null;
        if (this.state.modal_content) {
            modal = (
                <EmptyModal show={true} onHide={this.hide_modal}>
                    <Container>
                        <div>{this.state.modal_content}</div>
                    </Container>
                </EmptyModal>
            );
        }

        var content = (
            <div style={{ background: '#f6f6f6', paddingTop: '69px' }}>
                <Navbar
                    style={{
                        position: 'fixed',
                        width: '100%',
                        zIndex: '10',
                        top: '0px',
                    }}
                />

                <Container style={{ background: 'white' }} min_height={true}>
                    <div
                        className="row submission-container no-float-on-print"
                        style={{}}
                    >
                        <Container className="floatingnav">
                            <div>
                                <a
                                    href="/profile"
                                    style={{
                                        display: 'inline-block',
                                        padding: '5px',
                                    }}
                                >
                                    <span
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: '10px' }}
                                    ></span>{' '}
                                    Return to Profile
                                </a>
                                <br />
                                <h2 style={inlineStyleMargin}>
                                    {process.title + ':'}
                                </h2>
                                {review_details}
                                {floating_submit}
                                {anchor_dropdown}
                            </div>
                        </Container>

                        <div
                            className="transitions col-12"
                            style={{ fontSize: '1.4em' }}
                        >
                            {search_value_form}
                            <FormWithChildren
                                defaults={this.state.answers}
                                className="rendered_form"
                                submit={submit_action}
                                autoSetGlobalState={true}
                                globalStateName={'form_answers'}
                                external_required={true}
                                show_required={this.state.show_required}
                                required_count={this.state.required_count}
                            >
                                {content}
                            </FormWithChildren>
                            <div className="col-12">
                                <br />
                                <br />
                            </div>
                        </div>
                    </div>
                </Container>

                {modal}
            </div>
        );

        return <Wrapper content={content} loaded={loaded} />;
    }
}

export default Submission;
