import React, { Component } from 'react';
import {Wrapper, Container, Header, Div, Paragraph, FormWithChildren, DateTimePicker, TextInput, Select, TextArea, UserSearchInput, SignatureInput, ImageInput, Accordion} from 'library';

function get_form_elements(){
    var elements = [
		<Accordion index={10648} header={<div className={"question-card"} name={"10648_0"} excel_reference={1} style={{fontSize: '24px'}}  >
				<Paragraph text={"QUALITY CONTROL INSPECTION"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"10649_0,10652_0,10653_0,10654_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"10649_0"} label={"Select Position Below if QC Form is Completed by a Supervisor or Manager"} options={[{"text": "Supervisor", "value": "Supervisor"}, {"text": "Area Supervisor", "value": "Area Supervisor"}, {"text": "Dir/Mgr of Operations", "value": "Dir/Mgr of Operations"}, {"text": "Vice President", "value": "Vice President"}]} radio={true} excel_reference={2}  />
			<TextInput className={"question-card"} name={"10652_0"} label={"Area:"} excel_reference={5} required={true}  />
			<TextInput className={"question-card"} name={"10653_0"} label={"Completed by:"} excel_reference={6} required={true}  />
			<TextInput className={"question-card"} name={"10654_0"} label={"Supervisor"} excel_reference={7} required={true}  />
		</Accordion>,
		<Accordion index={10655} header={<div className={"question-card"} name={"10655_0"} excel_reference={8} anchor={true} anchor_text={"MAIN ENTRANCE / LOBBY / ELEVAT"} style={{fontSize: '24px'}}  >
				<Paragraph text={"MAIN ENTRANCE / LOBBY / ELEVATORS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"10656_0,10657_0,10658_0,10659_0,10660_0,10661_0,10662_0,10663_0,10664_0,10665_0,10666_0"} sub_total={"10670_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"10656_0"} label={"Door Glass / Side Glass - Main entrance"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={9}  />
			<Select className={"question-card"} name={"10657_0"} label={"Walk Off Mats / Corners & Edges"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={10}  />
			<Select className={"question-card"} name={"10658_0"} label={"Carpets Vacuumed  / Spot Clean Stains"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={11}  />
			<Select className={"question-card"} name={"10659_0"} label={"Floors - Sweep, Mop, Buff & Vacuum"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={12}  />
			<Select className={"question-card"} name={"10660_0"} label={"Main Elevator Cabs - Interior & Exterior Directional Plates, Tracks"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={13}  />
			<Select className={"question-card"} name={"10661_0"} label={"Escalators & Landings"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={14}  />
			<Select className={"question-card"} name={"10662_0"} label={"Ice Machine"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={15}  />
			<Select className={"question-card"} name={"10663_0"} label={"High Low Dusting / A/C Vents"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={16}  />
			<Select className={"question-card"} name={"10664_0"} label={"Trash Containers / Ash Urns"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={17}  />
			<Select className={"question-card"} name={"10665_0"} label={"Lobby Furniture & Fixtures"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={18}  />
			<Select className={"question-card"} name={"10666_0"} label={"Building Exterior (police area)"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={19}  />
			<TextInput className={"question-card"} name={"10667_0"} label={"COMMENTS "} excel_reference={20} fail_comment={true}  />
			<div className={"question-card"} name={"10668_0"} excel_reference={21}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"10669_0"} excel_reference={22}  />
			<TextInput className={"question-card"} name={"10670_0"} label={"Total Rating"} excel_reference={23} excel={'+Q9+Q10+Q11+Q12+Q13+Q14+Q15+Q16+Q17+Q18+Q19'}  />
		</Accordion>,
		<Accordion index={10671} header={<div className={"question-card"} name={"10671_0"} excel_reference={24} anchor={true} anchor_text={"COMMON AREAS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"COMMON AREAS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"10672_0,10673_0,10674_0,10675_0,10676_0,10677_0,10678_0,10679_0,10680_0"} sub_total={"10684_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"10672_0"} label={"Drinking Fountains         "} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={25}  />
			<Select className={"question-card"} name={"10673_0"} label={"Stairway"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={26}  />
			<Select className={"question-card"} name={"10674_0"} label={"Elevators, Cabs, Tracks, Panels"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={27}  />
			<Select className={"question-card"} name={"10675_0"} label={"Telephones"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={28}  />
			<Select className={"question-card"} name={"10676_0"} label={"Interior Glass"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={29}  />
			<Select className={"question-card"} name={"10677_0"} label={"High Low Dusting / A/C Vents"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={30}  />
			<Select className={"question-card"} name={"10678_0"} label={"Floors - Sweep, Mop, Buff & Vacuum"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={31}  />
			<Select className={"question-card"} name={"10679_0"} label={"Carpets Vacuumed  / Spot Clean Stains"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={32}  />
			<Select className={"question-card"} name={"10680_0"} label={"Wall Spotting / Corners & Edges"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={33}  />
			<TextInput className={"question-card"} name={"10681_0"} label={"COMMENTS"} excel_reference={34} fail_comment={true}  />
			<div className={"question-card"} name={"10682_0"} excel_reference={35}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"10683_0"} excel_reference={36}  />
			<TextInput className={"question-card"} name={"10684_0"} label={"Total Rating"} excel_reference={37} excel={'Q25+Q26+Q27+Q28+Q29+Q30+Q31+Q32+Q33'}  />
		</Accordion>,
		<Accordion index={10685} header={<div className={"question-card"} name={"10685_0"} excel_reference={38} anchor={true} anchor_text={"OFFICES / CONFERENCE ROOMS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"OFFICES / CONFERENCE ROOMS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"10686_0,10687_0,10688_0,10689_0,10690_0,10691_0,10692_0,10693_0,10694_0,10695_0,10696_0"} sub_total={"10700_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"10686_0"} label={"Trash Removed & Liner Replaced"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={39}  />
			<Select className={"question-card"} name={"10687_0"} label={"Carpets Vacuumed  / Spot Clean Stains"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={40}  />
			<Select className={"question-card"} name={"10688_0"} label={"Floors - Sweep, Mop, Buff & Vacuum"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={41}  />
			<Select className={"question-card"} name={"10689_0"} label={"Vacuum / Sweep Corners, Edges, & Under Furniture"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={42}  />
			<Select className={"question-card"} name={"10690_0"} label={"Interior Glass"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={43}  />
			<Select className={"question-card"} name={"10691_0"} label={"Telephones"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={44}  />
			<Select className={"question-card"} name={"10692_0"} label={"High Low Dusting"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={45}  />
			<Select className={"question-card"} name={"10693_0"} label={"Blinds / Window Sills Dusted"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={46}  />
			<Select className={"question-card"} name={"10694_0"} label={"Furniture, File Cabinets, Counter Tops, Etc."} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={47}  />
			<Select className={"question-card"} name={"10695_0"} label={"Wall Spotting (Finger Prints, Smudges, Etc.)"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={48}  />
			<Select className={"question-card"} name={"10696_0"} label={"A/C Vents & Return Air Vents"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={49}  />
			<TextInput className={"question-card"} name={"10697_0"} label={"COMMENTS"} excel_reference={50} fail_comment={true}  />
			<div className={"question-card"} name={"10698_0"} excel_reference={51}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"10699_0"} excel_reference={52}  />
			<TextInput className={"question-card"} name={"10700_0"} label={"Total Rating"} excel_reference={53} excel={'Q39+Q40+Q41+Q42+Q43+Q44+Q45+Q46+Q47+Q48+Q49'}  />
		</Accordion>,
		<Accordion index={10701} header={<div className={"question-card"} name={"10701_0"} excel_reference={54} anchor={true} anchor_text={"REST ROOMS / LOCKER ROOMS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"REST ROOMS / LOCKER ROOMS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"10702_0,10703_0,10704_0,10705_0,10706_0,10707_0,10708_0,10709_0,10710_0,10711_0"} sub_total={"10715_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"10702_0"} label={"Trash Removed & Liner Replaced"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={55}  />
			<Select className={"question-card"} name={"10703_0"} label={"Clean & Polish Stainless Steel Fixtures"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={56}  />
			<Select className={"question-card"} name={"10704_0"} label={"Replenish Tissue, Towels, & Soap Dispensers"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={57}  />
			<Select className={"question-card"} name={"10705_0"} label={"Mirrors & Frames"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={58}  />
			<Select className={"question-card"} name={"10706_0"} label={"High & Low Dusting"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={59}  />
			<Select className={"question-card"} name={"10707_0"} label={"Sinks, Faucets, & Counter Tops"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={60}  />
			<Select className={"question-card"} name={"10708_0"} label={"Floors Swept, Mopped"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={61}  />
			<Select className={"question-card"} name={"10709_0"} label={"Toilets / Urinals - Bowls, Seats, Rim, Plumbing Fixtures"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={62}  />
			<Select className={"question-card"} name={"10710_0"} label={"Partition Walls, Doors, Etc."} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={63}  />
			<Select className={"question-card"} name={"10711_0"} label={"A/C Vents & Return Air Vents"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={64}  />
			<TextInput className={"question-card"} name={"10712_0"} label={"COMMENTS"} excel_reference={65} fail_comment={true}  />
			<div className={"question-card"} name={"10713_0"} excel_reference={66}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"10714_0"} excel_reference={67}  />
			<TextInput className={"question-card"} name={"10715_0"} label={"Total Rating "} excel_reference={68} excel={'Q55+Q56+Q57+Q58+Q59+Q60+Q61+Q62+Q63+Q64'}  />
		</Accordion>,
		<Accordion index={10716} header={<div className={"question-card"} name={"10716_0"} excel_reference={69} anchor={true} anchor_text={" BREAK ROOMS / CAFETERIA"} style={{fontSize: '24px'}}  >
				<Paragraph text={" BREAK ROOMS / CAFETERIA"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"10717_0,10718_0,10719_0,10720_0,10721_0,10722_0,10723_0"} sub_total={"10727_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"10717_0"} label={"Floors Swept, Mopped, Vacuum & Buff"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={70}  />
			<Select className={"question-card"} name={"10718_0"} label={"High & Low Dusting"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={71}  />
			<Select className={"question-card"} name={"10719_0"} label={"Tables / Chairs"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={72}  />
			<Select className={"question-card"} name={"10720_0"} label={"A/C Vents & Return Air Vents"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={73}  />
			<Select className={"question-card"} name={"10721_0"} label={"Trash  removed & liners replaced"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={74}  />
			<Select className={"question-card"} name={"10722_0"} label={"Counter Tops, Sinks, & Back Splash Areas"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={75}  />
			<Select className={"question-card"} name={"10723_0"} label={"Wall Spotting (Finger Prints, Smudges)"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={76}  />
			<TextInput className={"question-card"} name={"10724_0"} label={"COMMENTS"} excel_reference={77} fail_comment={true}  />
			<div className={"question-card"} name={"10725_0"} excel_reference={78}  >
				<Paragraph text={"Take a Photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"10726_0"} excel_reference={79}  />
			<TextInput className={"question-card"} name={"10727_0"} label={"Total Rating"} excel_reference={80} excel={'Q70+Q71+Q72+Q73+Q74+Q75+Q76'}  />
		</Accordion>,
		<Accordion index={10728} header={<div className={"question-card"} name={"10728_0"} excel_reference={81} anchor={true} anchor_text={"JANITOR CLOSET / STORAGE AREA"} style={{fontSize: '24px'}}  >
				<Paragraph text={"JANITOR CLOSET / STORAGE AREA"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"10729_0,10730_0,10731_0,10746_0,11524_0,11525_0"} sub_total={"10735_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"10729_0"} label={"Mop Sink & Floors"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={82}  />
			<Select className={"question-card"} name={"10730_0"} label={"Supplies/Equipment (Stored neat & orderly)"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={83}  />
			<Select className={"question-card"} name={"10731_0"} label={"SDS binders in closet and chemicals labeled"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={84}  />
			<Select className={"question-card"} name={"10746_0"} label={"All chemicals in the closet are approved"} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={85}  />
			<Select className={"question-card"} name={"11524_0"} label={"Have all checklists been completed? "} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={86}  />
			<Select className={"question-card"} name={"11525_0"} label={"Have all employees completed the sign in/out sheet for their shift?  "} options={[{"text": "Pass", "value": 1}, {"text": "Fail", "value": 0}]} radio={true} excel_reference={87}  />
			<TextInput className={"question-card"} name={"10732_0"} label={"COMMENTS"} excel_reference={88} fail_comment={true}  />
			<div className={"question-card"} name={"10733_0"} excel_reference={89}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"10734_0"} excel_reference={90}  />
			<TextInput className={"question-card"} name={"10735_0"} label={"Rating Total"} excel_reference={91} excel={'Q82+Q83+Q84+Q85+Q86+Q87'}  />
		</Accordion>,
		<Accordion index={10738} header={<div className={"question-card"} name={"10738_0"} excel_reference={94} anchor={true} anchor_text={"CAN WE PROVIDE ADDITIONAL SERV"} style={{fontSize: '24px'}}  >
				<Paragraph text={"CAN WE PROVIDE ADDITIONAL SERVICES (TAGS)?  What extra services can we suggest to make the buildings look better?"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"10739_0,10740_0,10741_0,10742_0,10743_0,10744_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"10739_0"} label={"Do any areas need pressure washing? "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={95}  />
			<Select className={"question-card"} name={"10740_0"} label={"Do we need to shampoo carpet before periodics are due?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={96}  />
			<Select className={"question-card"} name={"10741_0"} label={"Do we need to strip & wax floor before periodics are due?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={97}  />
			<Select className={"question-card"} name={"10742_0"} label={"Do we need to provide any exterior window cleaning?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={98}  />
			<Select className={"question-card"} name={"10743_0"} label={"Can we provide a maintenance technician?"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={99}  />
			<TextInput className={"question-card"} name={"10744_0"} label={"If the answer to a question above is yes please comment below"} excel_reference={100}  />
		</Accordion>,
		<TextInput className={"question-card"} name={"10736_0"} label={"Grand Total"} excel_reference={92} anchor={true} anchor_text={"Grand Total"} excel={'Q23+Q37+Q53+Q68+Q80+Q91'}  />,
		<TextInput className={"question-card"} name={"10737_0"} label={"Percentage"} excel_reference={93} excel={'(Q92/54)*100'}  />,
    ];

    return elements;
}

export default get_form_elements;
