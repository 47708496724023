import React, { Component } from 'react';
import { Wrapper, Container, Header, Div, Paragraph, FormWithChildren, DateTimePicker, TextInput, Select, TextArea, UserSearchInput, SignatureInput, ImageInput, Accordion } from 'library';

function get_form_elements() {
    var elements = [
        <Accordion index={9775} header={<div className={"question-card"} name={"9775_0"} excel_reference={1} style={{ fontSize: '24px' }}  >
            <Paragraph text={"QUALITY CONTROL INSPECTION"} style={{ margin: "0px", fontSize: "inherit", marginRight: "200px" }} />
        </div>} names={"9776_0,9779_0,9780_0,9781_0"} sub_total={""} className={"col-12"} style={{ padding: "0px" }} >
            <Select className={"question-card"} name={"9776_0"} label={"Select Position Below if QC Form is Completed by a Supervisor or Manager"} options={[{ "text": "Supervisor", "value": "Supervisor" }, { "text": "Area Supervisor", "value": "Area Supervisor" }, { "text": "Dir/Mgr of Operations", "value": "Dir/Mgr of Operations" }, { "text": "Vice President", "value": "Vice President" }]} radio={true} excel_reference={2} />
            <TextInput className={"question-card"} name={"9779_0"} label={"Area:"} excel_reference={5} required={true} />
            <TextInput className={"question-card"} name={"9780_0"} label={"Completed by:"} excel_reference={6} required={true} />
            <TextInput className={"question-card"} name={"9781_0"} label={"Supervisor"} excel_reference={7} required={true} />
        </Accordion>,
        <Accordion index={9782} header={<div className={"question-card"} name={"9782_0"} excel_reference={8} anchor={true} anchor_text={"MAIN ENTRANCE / LOBBY / ELEVAT"} style={{ fontSize: '24px' }}  >
            <Paragraph text={"MAIN ENTRANCE / LOBBY / ELEVATORS"} style={{ margin: "0px", fontSize: "inherit", marginRight: "200px" }} />
        </div>} names={"9783_0,9784_0,9785_0,9786_0,9787_0,9788_0,9789_0,9790_0,9791_0,9792_0,9793_0"} sub_total={"9797_0"} className={"col-12"} style={{ padding: "0px" }} >
            <Select className={"question-card"} name={"9783_0"} label={"Door Glass / Side Glass - Main entrance"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={9} />
            <Select className={"question-card"} name={"9784_0"} label={"Walk Off Mats / Corners & Edges"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={10} />
            <Select className={"question-card"} name={"9785_0"} label={"Carpets Vacuumed  / Spot Clean Stains"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={11} />
            <Select className={"question-card"} name={"9786_0"} label={"Floors - Sweep, Mop, Buff & Vacuum"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={12} />
            <Select className={"question-card"} name={"9787_0"} label={"Main Elevator Cabs - Interior & Exterior Directional Plates, Tracks"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={13} />
            <Select className={"question-card"} name={"9788_0"} label={"Escalators & Landings"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={14} />
            <Select className={"question-card"} name={"9789_0"} label={"Ice Machine"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={15} />
            <Select className={"question-card"} name={"9790_0"} label={"High Low Dusting / A/C Vents"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={16} />
            <Select className={"question-card"} name={"9791_0"} label={"Trash Containers / Ash Urns"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={17} />
            <Select className={"question-card"} name={"9792_0"} label={"Lobby Furniture & Fixtures"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={18} />
            <Select className={"question-card"} name={"9793_0"} label={"Building Exterior (police area)"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={19} />
            <TextInput className={"question-card"} name={"9794_0"} label={"COMMENTS "} excel_reference={20} fail_comment={true} />
            <div className={"question-card"} name={"9795_0"} excel_reference={21}  >
                <Paragraph text={"Take a photo"} style={{ margin: "0px", fontSize: "inherit" }} />
            </div>
            <ImageInput className={"question-card"} name={"9796_0"} excel_reference={22} />
            <TextInput className={"question-card"} name={"9797_0"} label={"Total Rating"} excel_reference={23} excel={'+Q9+Q10+Q11+Q12+Q13+Q14+Q15+Q16+Q17+Q18+Q19'} />
        </Accordion>,
        <Accordion index={9798} header={<div className={"question-card"} name={"9798_0"} excel_reference={24} anchor={true} anchor_text={"COMMON AREAS"} style={{ fontSize: '24px' }}  >
            <Paragraph text={"COMMON AREAS"} style={{ margin: "0px", fontSize: "inherit", marginRight: "200px" }} />
        </div>} names={"9799_0,9800_0,9801_0,9802_0,9803_0,9804_0,9805_0,9806_0,9807_0"} sub_total={"9811_0"} className={"col-12"} style={{ padding: "0px" }} >
            <Select className={"question-card"} name={"9799_0"} label={"Drinking Fountains         "} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={25} />
            <Select className={"question-card"} name={"9800_0"} label={"Stairway"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={26} />
            <Select className={"question-card"} name={"9801_0"} label={"Elevators, Cabs, Tracks, Panels"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={27} />
            <Select className={"question-card"} name={"9802_0"} label={"Telephones"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={28} />
            <Select className={"question-card"} name={"9803_0"} label={"Interior Glass"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={29} />
            <Select className={"question-card"} name={"9804_0"} label={"High Low Dusting / A/C Vents"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={30} />
            <Select className={"question-card"} name={"9805_0"} label={"Floors - Sweep, Mop, Buff & Vacuum"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={31} />
            <Select className={"question-card"} name={"9806_0"} label={"Carpets Vacuumed  / Spot Clean Stains"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={32} />
            <Select className={"question-card"} name={"9807_0"} label={"Wall Spotting / Corners & Edges"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={33} />
            <TextInput className={"question-card"} name={"9808_0"} label={"COMMENTS"} excel_reference={34} fail_comment={true} />
            <div className={"question-card"} name={"9809_0"} excel_reference={35}  >
                <Paragraph text={"Take a photo"} style={{ margin: "0px", fontSize: "inherit" }} />
            </div>
            <ImageInput className={"question-card"} name={"9810_0"} excel_reference={36} />
            <TextInput className={"question-card"} name={"9811_0"} label={"Total Rating"} excel_reference={37} excel={'Q25+Q26+Q27+Q28+Q29+Q30+Q31+Q32+Q33'} />
        </Accordion>,
        <Accordion index={9812} header={<div className={"question-card"} name={"9812_0"} excel_reference={38} anchor={true} anchor_text={"OFFICES / CONFERENCE ROOMS"} style={{ fontSize: '24px' }}  >
            <Paragraph text={"OFFICES / CONFERENCE ROOMS"} style={{ margin: "0px", fontSize: "inherit", marginRight: "200px" }} />
        </div>} names={"9813_0,9814_0,9815_0,9816_0,9817_0,9818_0,9819_0,9820_0,9821_0,9822_0,9823_0"} sub_total={"9827_0"} className={"col-12"} style={{ padding: "0px" }} >
            <Select className={"question-card"} name={"9813_0"} label={"Trash Removed & Liner Replaced"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={39} />
            <Select className={"question-card"} name={"9814_0"} label={"Carpets Vacuumed  / Spot Clean Stains"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={40} />
            <Select className={"question-card"} name={"9815_0"} label={"Floors - Sweep, Mop, Buff & Vacuum"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={41} />
            <Select className={"question-card"} name={"9816_0"} label={"Vacuum / Sweep Corners, Edges, & Under Furniture"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={42} />
            <Select className={"question-card"} name={"9817_0"} label={"Interior Glass"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={43} />
            <Select className={"question-card"} name={"9818_0"} label={"Telephones"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={44} />
            <Select className={"question-card"} name={"9819_0"} label={"High Low Dusting"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={45} />
            <Select className={"question-card"} name={"9820_0"} label={"Blinds / Window Sills Dusted"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={46} />
            <Select className={"question-card"} name={"9821_0"} label={"Furniture, File Cabinets, Counter Tops, Etc."} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={47} />
            <Select className={"question-card"} name={"9822_0"} label={"Wall Spotting (Finger Prints, Smudges, Etc.)"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={48} />
            <Select className={"question-card"} name={"9823_0"} label={"A/C Vents & Return Air Vents"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={49} />
            <TextInput className={"question-card"} name={"9824_0"} label={"COMMENTS"} excel_reference={50} fail_comment={true} />
            <div className={"question-card"} name={"9825_0"} excel_reference={51}  >
                <Paragraph text={"Take a photo"} style={{ margin: "0px", fontSize: "inherit" }} />
            </div>
            <ImageInput className={"question-card"} name={"9826_0"} excel_reference={52} />
            <TextInput className={"question-card"} name={"9827_0"} label={"Total Rating"} excel_reference={53} excel={'Q39+Q40+Q41+Q42+Q43+Q44+Q45+Q46+Q47+Q48+Q49'} />
        </Accordion>,
        <Accordion index={9828} header={<div className={"question-card"} name={"9828_0"} excel_reference={54} anchor={true} anchor_text={"REST ROOMS / LOCKER ROOMS"} style={{ fontSize: '24px' }}  >
            <Paragraph text={"REST ROOMS / LOCKER ROOMS"} style={{ margin: "0px", fontSize: "inherit", marginRight: "200px" }} />
        </div>} names={"9829_0,9830_0,9831_0,9832_0,9833_0,9834_0,9835_0,9836_0,9837_0,9838_0"} sub_total={"9842_0"} className={"col-12"} style={{ padding: "0px" }} >
            <Select className={"question-card"} name={"9829_0"} label={"Trash Removed & Liner Replaced"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={55} />
            <Select className={"question-card"} name={"9830_0"} label={"Clean & Polish Stainless Steel Fixtures"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={56} />
            <Select className={"question-card"} name={"9831_0"} label={"Replenish Tissue, Towels, & Soap Dispensers"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={57} />
            <Select className={"question-card"} name={"9832_0"} label={"Mirrors & Frames"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={58} />
            <Select className={"question-card"} name={"9833_0"} label={"High & Low Dusting"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={59} />
            <Select className={"question-card"} name={"9834_0"} label={"Sinks, Faucets, & Counter Tops"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={60} />
            <Select className={"question-card"} name={"9835_0"} label={"Floors Swept, Mopped"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={61} />
            <Select className={"question-card"} name={"9836_0"} label={"Toilets / Urinals - Bowls, Seats, Rim, Plumbing Fixtures"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={62} />
            <Select className={"question-card"} name={"9837_0"} label={"Partition Walls, Doors, Etc."} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={63} />
            <Select className={"question-card"} name={"9838_0"} label={"A/C Vents & Return Air Vents"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={64} />
            <TextInput className={"question-card"} name={"9839_0"} label={"COMMENTS"} excel_reference={65} fail_comment={true} />
            <div className={"question-card"} name={"9840_0"} excel_reference={66}  >
                <Paragraph text={"Take a photo"} style={{ margin: "0px", fontSize: "inherit" }} />
            </div>
            <ImageInput className={"question-card"} name={"9841_0"} excel_reference={67} />
            <TextInput className={"question-card"} name={"9842_0"} label={"Total Rating "} excel_reference={68} excel={'Q55+Q56+Q57+Q58+Q59+Q60+Q61+Q62+Q63+Q64'} />
        </Accordion>,
        <Accordion index={9843} header={<div className={"question-card"} name={"9843_0"} excel_reference={69} anchor={true} anchor_text={" BREAK ROOMS / CAFETERIA"} style={{ fontSize: '24px' }}  >
            <Paragraph text={" BREAK ROOMS / CAFETERIA"} style={{ margin: "0px", fontSize: "inherit", marginRight: "200px" }} />
        </div>} names={"9844_0,9845_0,9846_0,9847_0,9848_0,9849_0,9850_0"} sub_total={"9854_0"} className={"col-12"} style={{ padding: "0px" }} >
            <Select className={"question-card"} name={"9844_0"} label={"Floors Swept, Mopped, Vacuum & Buff"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={70} />
            <Select className={"question-card"} name={"9845_0"} label={"High & Low Dusting"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={71} />
            <Select className={"question-card"} name={"9846_0"} label={"Tables / Chairs"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={72} />
            <Select className={"question-card"} name={"9847_0"} label={"A/C Vents & Return Air Vents"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={73} />
            <Select className={"question-card"} name={"9848_0"} label={"Trash  removed & liners replaced"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={74} />
            <Select className={"question-card"} name={"9849_0"} label={"Counter Tops, Sinks, & Back Splash Areas"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={75} />
            <Select className={"question-card"} name={"9850_0"} label={"Wall Spotting (Finger Prints, Smudges)"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={76} />
            <TextInput className={"question-card"} name={"9851_0"} label={"COMMENTS"} excel_reference={77} fail_comment={true} />
            <div className={"question-card"} name={"9852_0"} excel_reference={78}  >
                <Paragraph text={"Take a Photo"} style={{ margin: "0px", fontSize: "inherit" }} />
            </div>
            <ImageInput className={"question-card"} name={"9853_0"} excel_reference={79} />
            <TextInput className={"question-card"} name={"9854_0"} label={"Total Rating"} excel_reference={80} excel={'Q70+Q71+Q72+Q73+Q74+Q75+Q76'} />
        </Accordion>,
        <Accordion index={9855} header={<div className={"question-card"} name={"9855_0"} excel_reference={81} anchor={true} anchor_text={"JANITOR CLOSET / STORAGE AREA"} style={{ fontSize: '24px' }}  >
            <Paragraph text={"JANITOR CLOSET / STORAGE AREA"} style={{ margin: "0px", fontSize: "inherit", marginRight: "200px" }} />
        </div>} names={"9856_0,9857_0,9858_0,10755_0,11546_0,11547_0"} sub_total={"9862_0"} className={"col-12"} style={{ padding: "0px" }} >
            <Select className={"question-card"} name={"9856_0"} label={"Mop Sink & Floors"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={82} />
            <Select className={"question-card"} name={"9857_0"} label={"Supplies/Equipment (Stored neat & orderly)"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={83} />
            <Select className={"question-card"} name={"9858_0"} label={"SDS binders in closet and chemicals labeled"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={84} />
            <Select className={"question-card"} name={"10755_0"} label={"All chemicals in the closet are approved"} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={85} />
            <Select className={"question-card"} name={"11546_0"} label={"Have all checklists been completed? "} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={86} />
            <Select className={"question-card"} name={"11547_0"} label={"Have all employees completed the sign in/out sheet for their shift?  "} options={[{ "text": "Pass", "value": 1 }, { "text": "Fail", "value": 0 }]} radio={true} excel_reference={87} />
            <TextInput className={"question-card"} name={"9859_0"} label={"COMMENTS"} excel_reference={88} fail_comment={true} />
            <div className={"question-card"} name={"9860_0"} excel_reference={89}  >
                <Paragraph text={"Take a photo"} style={{ margin: "0px", fontSize: "inherit" }} />
            </div>
            <ImageInput className={"question-card"} name={"9861_0"} excel_reference={90} />
            <TextInput className={"question-card"} name={"9862_0"} label={"Rating Total"} excel_reference={91} excel={'Q82+Q83+Q84+Q85+Q86+Q87'} />
        </Accordion>,
        <Accordion index={9865} header={<div className={"question-card"} name={"9865_0"} excel_reference={94} anchor={true} anchor_text={"CAN WE PROVIDE ADDITIONAL SERV"} style={{ fontSize: '24px' }}  >
            <Paragraph text={"CAN WE PROVIDE ADDITIONAL SERVICES (TAGS)?  What extra services can we suggest to make the buildings look better?"} style={{ margin: "0px", fontSize: "inherit", marginRight: "200px" }} />
        </div>} names={"9866_0,9867_0,9868_0,9869_0,9870_0,9871_0"} sub_total={""} className={"col-12"} style={{ padding: "0px" }} >
            <Select className={"question-card"} name={"9866_0"} label={"Do any areas need pressure washing? "} options={[{ "text": "Yes", "value": "Yes" }, { "text": "No", "value": "No" }]} radio={true} excel_reference={95} />
            <Select className={"question-card"} name={"9867_0"} label={"Do we need to shampoo carpet before periodics are due?"} options={[{ "text": "Yes", "value": "Yes" }, { "text": "No", "value": "No" }]} radio={true} excel_reference={96} />
            <Select className={"question-card"} name={"9868_0"} label={"Do we need to strip & wax floor before periodics are due?"} options={[{ "text": "Yes", "value": "Yes" }, { "text": "No", "value": "No" }]} radio={true} excel_reference={97} />
            <Select className={"question-card"} name={"9869_0"} label={"Do we need to provide any exterior window cleaning?"} options={[{ "text": "Yes", "value": "Yes" }, { "text": "No", "value": "No" }]} radio={true} excel_reference={98} />
            <Select className={"question-card"} name={"9870_0"} label={"Can we provide a maintenance technician?"} options={[{ "text": "Yes", "value": "Yes" }, { "text": "No", "value": "No" }]} radio={true} excel_reference={99} />
            <TextInput className={"question-card"} name={"9871_0"} label={"If the answer to a question above is yes please comment below"} excel_reference={100} />
        </Accordion>,
        <TextInput className={"question-card"} name={"9863_0"} label={"Grand Total"} excel_reference={92} anchor={true} anchor_text={"Grand Total"} excel={'Q23+Q37+Q53+Q68+Q80+Q91'} />,
        <TextInput className={"question-card"} name={"9864_0"} label={"Percentage"} excel_reference={93} excel={'(Q92/54)*100'} />,
    ];

    return elements;
}

export default get_form_elements;
