import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';

import {Wrapper, LogInForm, Header, Div, Container, Span, Link, Image,
    Paragraph, Button, If, Alert, FormWithChildren, TextInput, Checkbox,
    Select, TextArea, ImageInput, ListWithChildren, CopyToClipboard} from 'library';
import Navbar from 'projectLibrary/navbar.js';
import Footer from 'projectLibrary/footer.js';


class Device extends Component {
    constructor(props) {
        super(props);

        this.state = {
            position: this.props.position
        };

        this.update_device = this.update_device.bind(this);
        this.remove_device = this.remove_device.bind(this);
    }

    update_device(state, callback){
        var data = {
            current_user: state['current_user_id'],
            title: state['title']
        };
        ajaxWrapper('POST', '/api/home/device/'+ this.props.id +'/', data, this.props.refresh_data);

        this.setState({message: 'Device Updated Successfully'});

        callback();
    }

    remove_device(e){
        ajaxWrapper('POST', '/api/home/device/'+ this.props.id +'/delete/', {}, this.props.refresh_data);
    }

    render() {
        var user_options = [];
        for (var index=0;index<this.props.users.length;index++){
            var user = this.props.users[index];
            user_options.push({'text': user['email'], 'value': user['id']});
        }
        user_options.sort((a, b) => (a.text > b.text) ? 1 : -1);

        return (
            <div class="usersmall row">
                <div class="row col-12" style={{padding:'0px'}} >
                    <div class="col-6" style={{padding:'0px'}} >
                        <h3 style={{display:'block'}}>{this.props.title}</h3>
                        <CopyToClipboard text={'http://' + window.location.hostname + '/easy_login/'+ this.props.verified_string + '/'} style={{display:'block'}} />
                    </div>
                    <div class="col-6" style={{padding:'0px'}} >
                        <div class="useractions">
                            <FormWithChildren defaults={this.props} autoSetGlobalState={true} globalStateName={'position'} submit={this.update_device} >
                                <TextInput name='title' label='Title' required={true} />
                                <Select label='Current User' className='normal-radios' name={"current_user_id"} options={user_options} required={true}  />
                            </FormWithChildren>
                        </div>
                    </div>
                    <div class="col-6" ><div>Created: {this.props.created_at}</div></div>
                </div>
                <If logic={[['exists', this.state.message]]}>
                    <Alert type='default' text={this.state.message} style={{width:'100%'}} />
                </If>
            </div>
        )
    }
}


class Devices extends Component {
    constructor(props) {
        super(props);

        this.state = {
            devices: [],
            users: []
        };

        this.refresh_data = this.refresh_data.bind(this);
        this.load_devices = this.load_devices.bind(this);
        this.load_users = this.load_users.bind(this);
        this.add_device = this.add_device.bind(this);
    }

    componentDidMount(){
        this.refresh_data();

        ajaxWrapper('GET', '/process_list/', {}, this.load_users);
    }

    refresh_data(){
        ajaxWrapper('GET', '/api/home/device/', {}, this.load_devices);
    }

    load_devices(value){
        this.setState({
            devices: value
        });
    }

    load_users(value){
        var user_options = [];
        var emails = [];

        for (var index=0;index<value.length;index++){
            var process = value[index]['Process'];
            for (var user_index=0;user_index<process.new_users.length;user_index++){
                var user = process.new_users[user_index]['user'];
                if (emails.indexOf(user.email) > -1){
                    continue;
                }
                user_options.push(user);
                emails.push(user.email);
            }
        }

        this.setState({
            users: user_options
        });
    }

    add_device(){
        ajaxWrapper('POST', '/api/home/device/', {}, this.refresh_data);
    }

    render() {
        var devices = this.state.devices;
        var users = this.state.users;

        return (<div>
			<Navbar />

            <Container style={{background: 'white'}} min_height={true} >
                <br/>
                <a href="/profile" style={{display:'inline-block', padding:'5px'}} ><span class="fa fa-arrow-left" style={{marginRight:'10px'}} ></span>  Return to Profile</a>
                <Header size={2} text={'Manage Devices'} />

                <If logic={[['exists', this.state.message]]}>
                  <Alert type='default' text={this.state.message} />
                </If>
                <br/>

                <div className='formlist' >
                    <ListWithChildren dataList={devices} objectName={"device"} nowrapper={true} >
                        <Device key={resolveVariables({"text":"{props.id}"}, this)["text"]} users={this.state.users} refresh_data={this.refresh_data} />
                    </ListWithChildren>
                    <Button onClick={this.add_device} text='Add New' type='primary' className='btn-lg' style={{display: 'block', width: '100%'}} />
                </div>

                <br/><br/><br/><br/>
            </Container>

            <Footer />
        </div>);
    }
}

export default Devices;
