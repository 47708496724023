// index.js
import React from 'react';
import {ajaxWrapper, run_functions, injectStripe} from 'functions';
import {StripeProvider, Elements, CardElement} from 'react-stripe-elements';
import {Alert} from 'library';
import StripeBankForm from './bank_account_form.js';


class StripeBankAccount extends React.Component {
    static component_name = 'StripeBankAccount';
    constructor(props) {
        super(props);

        this.config = {
            form_components: [],
        };

        this.state = {};
    }

    render() {
        var api_key = 'pk_test_M0O0AzznoXGvrZx5UA6huSXW';
        console.log("API Key", api_key);

        var message = null;
        if (this.state.error){
            message = <Alert type={'danger'} text={this.state.error} />;
        }

        var btn_style = {marginTop: "20px"};
        var button = <button className="btn btn-primary" style={btn_style} >{this.props.submit || "Pay"}</button>;
        if (this.state.saving) {
            button = <button className="btn btn-primary disabled" disabled="disabled" style={btn_style} >{this.props.submit || "Pay"}</button>;
        }
        else if (this.state.saved) {
            button = null;
        }

        var stripe_bank_form = <StripeBankForm />;

        return (
            <StripeProvider apiKey={api_key} >
                <Elements>
                    {stripe_bank_form}
                </Elements>
            </StripeProvider>
        );
    }
}




export default StripeBankAccount;
