import React, { Component } from 'react';
import {Wrapper, Container, Header, Div, Paragraph, FormWithChildren, DateTimePicker, TextInput, Select, TextArea, UserSearchInput, SignatureInput, ImageInput, Accordion} from 'library';

function get_form_elements(){
    var elements = [
		<Accordion index={7838} header={<div className={"question-card"} name={"7838_0"} excel_reference={1} style={{fontSize: '24px'}}  >
				<Paragraph text={" WEEKLY CUSTOMER SCORECARD"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"7839_0,7840_0,7841_0,7844_0,7845_0,7846_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<TextInput className={"question-card"} name={"7839_0"} label={"Customer Name  "} excel_reference={2}  />
			<TextInput className={"question-card"} name={"7840_0"} label={"Customer Location "} excel_reference={3}  />
			<DateTimePicker className={"question-card"} name={"7841_0"} label={"Date"} excel_reference={4}  />
			<div className={"question-card"} name={"7842_0"} excel_reference={5} style={{fontSize: '14px'}}  >
				<Paragraph text={"Please rate the Custodial Department\u2019s services last month on a 10 to 1 scale:  "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"10 \u2013 services exceeded expectations, I am very happy!   "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"1 \u2013 I have concerns about last month\u2019s services that must be addressed immediately  "} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<Select className={"question-card"} name={"7844_0"} label={"Select Score"} options={[{"text": "1", "value": "1"}, {"text": "2", "value": "2"}, {"text": "3", "value": "3"}, {"text": "4", "value": "4"}, {"text": "5", "value": "5"}, {"text": "6", "value": "6"}, {"text": "7", "value": "7"}, {"text": "8", "value": "8"}, {"text": "9", "value": "9"}, {"text": "10", "value": "10"}]} radio={true} excel_reference={6}  />
			<TextInput className={"question-card"} name={"7845_0"} label={"What is the TOP IMPROVEMENT that would get us to the next higher number score?"} excel_reference={7}  />
			<TextInput className={"question-card"} name={"7846_0"} label={"Any additional comments or requests may be noted in the space below"} excel_reference={8}  />
			<div className={"question-card"} name={"7847_0"} excel_reference={9}  >
				<Paragraph text={"For immediate assistance please contact our 24/7 Mission Control center  by e-mail at KTMissionControl@K-TServices.com or by telephone at 1-877-219-1863. "} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
		</Accordion>,
    ];

    return elements;
}

export default get_form_elements;
