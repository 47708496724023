import React, { Component } from 'react';
import { Paragraph, TextInput, Select, ImageInput, Accordion } from 'library';

const SECTIONS = ['INSPECTION TASKS'];

const QUESTIONS = [
    'Free of dust',
    'Free of smudges',
    'Free of tape',
    'Free of stickers',
    'Free of watermarks',
    'Free of oil film',
    'Free of all other types of soil from all glass surfaces',
    'Remove glass cleaner splash and drip marks from all adjacent surfaces, i.e., window frames, sills, and other horizontal and vertical surfaces, to maintain the overall cleanliness.',
];

function create_question(index, text) {
    return (
        <Select
            className={'question-card'}
            name={`${index}_0`}
            label={text}
            options={[
                { text: 'Pass', value: 1 },
                { text: 'Fail', value: 0 },
            ]}
            radio={true}
            excel_reference={index}
        />
    );
}

function create_section(index, name) {
    let header = (
        <div
            className={'question-card'}
            anchor={true}
            anchor_text={name}
            style={{ fontSize: '24px' }}
        >
            <Paragraph
                text={name}
                style={{
                    margin: '0px',
                    fontSize: 'inherit',
                    marginRight: '200px',
                }}
            />
        </div>
    );

    let questions = [];
    let question_excel_keys = '';
    let question_name_string = '';
    for (let item of QUESTIONS) {
        let question_index = parseInt(
            String(index) + String(QUESTIONS.indexOf(item) + 1),
        );

        questions.push(create_question(question_index, item));
        question_excel_keys += `+Q${question_index}`;
        if (question_name_string) {
            question_name_string += ',';
        }
        question_name_string += `${question_index}_0`;
    }

    let all_children = [];
    all_children.push(...questions);
    all_children.push(
        ...[
            <TextInput
                className={'question-card'}
                name={`${index}01_0`}
                label={'COMMENTS'}
                fail_comment={true}
            />,
            <div className={'question-card'}>
                <Paragraph
                    text={'Take a photo'}
                    style={{ margin: '0px', fontSize: 'inherit' }}
                />
            </div>,
            <ImageInput className={'question-card'} name={`${index}02_0`} />,
            <TextInput
                className={'question-card'}
                name={`${index}0_0`}
                label={'Total Rating'}
                excel_reference={`${index}00`}
                excel={question_excel_keys}
            />,
        ],
    );

    let wrapper = (
        <Accordion
            index={index}
            header={header}
            names={question_name_string}
            sub_total={`${index}0_0`}
            className={'col-12'}
            style={{ padding: '0px' }}
        ></Accordion>
    );

    wrapper = React.cloneElement(wrapper, wrapper.props, ...all_children);

    return wrapper;
}

function get_form_elements() {
    let sections = [];
    let section_subtotal_excel_keys = '';

    let count = 1;
    for (let item of SECTIONS) {
        sections.push(create_section(count, item));
        section_subtotal_excel_keys += `+Q${count}00`;

        count += 1;
    }

    var elements = [
        <Accordion
            header={
                <div className={'question-card'} style={{ fontSize: '24px' }}>
                    <Paragraph
                        text={'QUALITY CONTROL INSPECTION'}
                        style={{
                            margin: '0px',
                            fontSize: 'inherit',
                            marginRight: '200px',
                        }}
                    />
                </div>
            }
            className={'col-12'}
            style={{ padding: '0px' }}
        >
            <Select
                className={'question-card'}
                name={'10649_0'}
                label={
                    'Select Position Below if QC Form is Completed by a Supervisor or Manager'
                }
                options={[
                    { text: 'Supervisor', value: 'Supervisor' },
                    { text: 'Area Supervisor', value: 'Area Supervisor' },
                    {
                        text: 'Dir/Mgr of Operations',
                        value: 'Dir/Mgr of Operations',
                    },
                    { text: 'Vice President', value: 'Vice President' },
                ]}
                radio={true}
                excel_reference={2}
            />
            <TextInput
                className={'question-card'}
                name={'10652_0'}
                label={'Area:'}
                excel_reference={5}
                required={true}
            />
            <TextInput
                className={'question-card'}
                name={'10653_0'}
                label={'Completed by:'}
                excel_reference={6}
                required={true}
            />
            <TextInput
                className={'question-card'}
                name={'10654_0'}
                label={'Supervisor'}
                excel_reference={7}
                required={true}
            />
        </Accordion>,
    ];

    elements.push(...sections);

    elements.push(
        <TextInput
            className={'question-card'}
            name={'1_0'}
            label={'Grand Total'}
            excel_reference={1}
            anchor={true}
            anchor_text={'Grand Total'}
            excel={section_subtotal_excel_keys}
        />,
    );
    elements.push(
        <TextInput
            className={'question-card'}
            name={'2_0'}
            label={'Percentage'}
            excel_reference={2}
            excel={`(Q1/${SECTIONS.length * QUESTIONS.length})*100`}
        />,
    );

    return elements;
}

export default get_form_elements;
