import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';
import {FormWithChildren, TextInput, Button, PasswordInput,
    Alert, If, NumberInput, CSSInput, Header} from 'library';

class LogInForm extends Component {
    static component_name = 'LogInForm';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [

                <TextInput label={'redirectUrl'} name={'redirectUrl'} default={''} />,
                <CSSInput label={'css'} name={'style'} default={{}} />,
            ],
            can_have_children: true,
        }

        this.state = {
            email:'',
            error:'',
            password: '',
            prompt_verification: false
        };

        this.formSubmit = this.formSubmit.bind(this);
        this.formSubmitCallback = this.formSubmitCallback.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);
        this.prompt_verification = this.prompt_verification.bind(this);
        this.login_and_redirect = this.login_and_redirect.bind(this);
    }

    setGlobalState(name, state) {
      this.setState(state);
    }

    formSubmit(data, callback) {
        console.log("Submitting", this.state, '/users/token/');

        var data = Object.assign({},this.state);
        delete data['children'];
        data['email'] = data['email'].toLowerCase().replace(/ /g,'');

        if (this.state.prompt_verification){
            ajaxWrapper("POST", '/users/verification/', data, this.formSubmitCallback);
        }
        else {
            ajaxWrapper("POST", '/users/token/', data, this.formSubmitCallback);
        }

        this.setState({'form_completed_callback': callback});
    }

    formSubmitCallback (value) {
        console.log("Value",value);
        if ('error' in value) {
            if (value['error'] == 'Bad Request') {
              this.setState({error: 'Wrong Email or Password.'});
            }
            else {
              this.setState({error:value['error']});
            }
        }
        else {
            this.setState({error:''});
            if (window.secretReactVars['twofactor'] && !(this.state.prompt_verification)) {
                this.prompt_verification();
            }
            else {
                this.login_and_redirect(value);
            }
        }

        this.state.form_completed_callback();
    }

    prompt_verification() {
        this.setState({prompt_verification: true});
    }

    login_and_redirect(value) {
        console.log("User",value);
        localStorage.setItem('token', value['access']);
        localStorage.setItem('refresh_token', value['refresh']);
        localStorage.setItem('token_time', new Date());

        window.location.href = this.props.redirectUrl;
    }

    handleKeyPress = (event) => {
        if (this.props.submit_on_enter != false) {
            if(event.key == 'Enter') {
                this.formSubmit();
            }
        }
    }

    render() {
        var classCss = "form";
        if (this.props.row == true) {
            classCss ="form-row";
        }

        var hide_css = {};
        var verify_jsx = <div></div>;
        if (this.state.prompt_verification) {
            hide_css['display'] = 'none';

            verify_jsx = <div>
                <FormWithChildren defaults={this.state} autoSetGlobalState={true} setGlobalState={this.setGlobalState} globalStateName={'login'} submit_on_enter={true} submit={this.formSubmit} submit_text="Verify" submitButtonType='success'>
                    <Header size='5' text='A text has been sent to your phone.  Please enter it below.' />
                    <TextInput name='verification' label='' required={true} />
                </FormWithChildren>
            </div>;
        }

        var content = <div>
            {verify_jsx}
            <div style={hide_css}>
                <FormWithChildren defaults={this.state} autoSetGlobalState={true} setGlobalState={this.setGlobalState} globalStateName={'login'} submit_on_enter={true} submit={this.formSubmit} submit_text="Log In" submitButtonType='success'>
                    <TextInput label="Email" placeholder="email@domain.com" name="email" required={true} />
                    <PasswordInput label="Password" name="password" required={true} />
                </FormWithChildren>
            </div>
        </div>;

        //need to add in formsubmit, delete, and handle change functions to components.
        return(
            <div className={classCss}>
                {content}
                <If logic={[['exists', this.state.error]]}>
                  <Alert type='danger' text={this.state.error} />
                </If>
            </div>
        )
    }
}

export default LogInForm;
