import React, { Component } from 'react';
import {Wrapper, Container, Header, Div, Paragraph, FormWithChildren, DateTimePicker, TextInput, Select, TextArea, UserSearchInput, SignatureInput, ImageInput, Accordion} from 'library';

function get_form_elements(){
    var elements = [
		<div className={"question-card"} name={"6929_0"} excel_reference={1}  >
				<Paragraph text={"  MONTHLY CUSTOMER SCORECARD"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>,
		<TextInput className={"question-card"} name={"6930_0"} label={"Company Name"} excel_reference={2}  />,
		<TextInput className={"question-card"} name={"6931_0"} label={"Customer Location "} excel_reference={3}  />,
		<DateTimePicker className={"question-card"} name={"6932_0"} label={"Date"} excel_reference={4}  />,
		<div className={"question-card"} name={"6933_0"} excel_reference={5}  >
				<Paragraph text={"Please rate Kleen-Tech\u2019s services last month on a 10 to 1 scale:  "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"10 \u2013 services exceeded expectations, I am very happy!   "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"1 \u2013 I have concerns about last month\u2019s services that must be addressed immediately  "} style={{margin:"0px", fontSize:"inherit"}} />
			</div>,
		<Select className={"question-card"} name={"6934_0"} label={"Select Score:"} options={[{"text": "10", "value": "10"}, {"text": "9", "value": "9"}, {"text": "8", "value": "8"}, {"text": "7", "value": "7"}, {"text": "6", "value": "6"}, {"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={6}  />,
		<TextInput className={"question-card"} name={"6936_0"} label={"What is the TOP IMPROVEMENT that would get us to the next higher number score?"} excel_reference={7}  />,
		<TextInput className={"question-card"} name={"6935_0"} label={"Any additional comments or requests may be noted in the space below"} excel_reference={8}  />,
		<div className={"question-card"} name={"6937_0"} excel_reference={9}  >
				<Paragraph text={"For immediate assistance please contact our 24/7 Mission Control center by e-mail at KTMissionControl@K-TServices.com or by telephone at 1-866-385-0672. "} style={{margin:"0px", fontSize:"inherit"}} />
			</div>,
    ];

    return elements;
}

export default get_form_elements;
