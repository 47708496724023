import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {Container, CSSInput, TextInput} from 'library';

class Navbar extends React.Component {
    static component_name = 'Navbar';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput name='name' label='Name' />,
                <TextInput name='logo' label='Logo Url' />,
                <TextInput name='name_link' label='Url' />,
                <TextInput name='className' label='Class' />,
                <CSSInput label={'Css'} name={'style'} default={{}} />,
            ],
            can_have_children: true,
        }
    }

    render() {
        var global_state = window.cmState.getGlobalState();

        var classes = 'navbar navbar-expand-lg navbar-light ' + this.props.className;

        var links = [];

        for (var index in this.props.children) {
            links.push(<li key={index} className="nav-item">{this.props.children[index]}</li>);
        }

        var name = null;
        if (this.props.logo) {
            name = <img src={this.props.logo} style={{height:'60px'}} />
        }
        else if (this.props.name) {
            name = this.props.name;
        }

        var logo_link = this.props.name_link;

        return (
            <nav className={classes} style={this.props.style}>
                <Container>
                    <a className="navbar-brand" href={logo_link}>{name}</a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ml-auto">
                            {links}
                        </ul>
                    </div>
                </Container>
            </nav>
        );
    }
}



export default Navbar;
