import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';
import $ from 'jquery';

import {Wrapper, LogInForm, Header, Div, Container, Span, Link, Image, Paragraph, EmptyModal} from 'library';
import Navbar from 'projectLibrary/navbar.js';
import Footer from 'projectLibrary/footer.js';

import ProcessSmall from 'projectLibrary/process/small.js';


class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            processes: []
        };

        this.load_processes = this.load_processes.bind(this);
        this.push_modal_content = this.push_modal_content.bind(this);
        this.hide_modal = this.hide_modal.bind(this);
    }

    componentDidMount() {
        var url = '/process_list/';
        if (this.props.archived){
            url += '?archived=true';
        }

        ajaxWrapper('GET', url, {}, this.load_processes);
    }

    load_processes(value){
        this.setState({processes: value});
    }

    push_modal_content(content){
        var state = {'modal_content': content};
        this.setState(state);
        $('body').addClass('no-scroll');
    }

    hide_modal(){
        var state = {modal_content: null};
        this.setState(state);
        $('body').removeClass('no-scroll');
    }


    render() {
        var processes = [];
        for (var index=0;index<this.state.processes.length;index++) {
            var process = this.state.processes[index]['Process'];
            processes.push(<ProcessSmall process={process} push_modal_content={this.push_modal_content} hide_modal={this.hide_modal} />);
        }

        var view_archived = <a className="btn btn-default" style={{float:'right'}} href={"/profile/?archived=true"} >View Archived Processes</a>;
        if (this.props.archived){
            view_archived = <a className="btn btn-primary" style={{float:'right'}} href={"/profile/"} >View Active Processes</a>;
        }

        var modal = null;
        if (this.state.modal_content){
            modal = <EmptyModal show={true} onHide={this.hide_modal} >
                <Container>
                    <div>{this.state.modal_content}</div>
                </Container>
            </EmptyModal>;
        }

        return (<div className='profile-page'>
				<Navbar />

                <Container style={{background: 'white'}} min_height={true} >
                    <div style={{padding:'15px'}} >
                        {view_archived}
                        <h1 style={{borderBottom: 'thin solid #666', marginBottom: '30px'}} >Processes:</h1>
                        <div className="item-list">
                            {processes}
                        </div>
                    </div>
                </Container>

                {modal}
				<Footer />
        </div>);
    }
}

export default Profile;
