import React, { Component } from 'react';
import {resolveVariables, ajaxWrapper} from 'functions';
import {Wrapper, Paragraph, Container, Break, Button, Header, Alert, TextInput, Select} from 'library';


class DropboxEntry extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.onClick = this.onClick.bind(this);
        this.view = this.view.bind(this);
        this.download = this.download.bind(this);
        this.open_link = this.open_link.bind(this);
        this.delete_file = this.delete_file.bind(this);
    }

    componentDidMount() {

    }

    onClick() {
        this.props.redirect(this.props.data);
    }

    view() {
        ajaxWrapper("GET", '/dropbox/view_link/?folder=' + this.props.data['id'], {}, this.open_link);
    }
    download() {
        ajaxWrapper("GET", '/dropbox/download_link/?folder=' + this.props.data['id'], {}, this.open_link);
    }

    open_link(value){
        window.open(value['url'], "_blank");
    }

    delete_file(){
        this.props.delete_file(this.props.data.name);
    }

    render() {
        var type = 'outline-info';
        var className = '';
        var click_handler = this.onClick;
        var text = [
            <i class="fas fa-1x fa-folder-open" style={{marginRight: '15px'}}></i>,
            this.props.data['name']
        ];

        var delete_file = null;

        if (this.props.data.type == 'file'){
            type = 'link';
            click_handler = this.view;
            var text = [
                <i class="fas fa-1x fa-file-alt" style={{marginRight: '15px'}}></i>,
                this.props.data['name']
            ];

            if (this.props.can_delete){
                var icon = <i class="fa fa-trash" aria-hidden="true"></i>;
                delete_file = <Button onClick={this.delete_file} type={'outline-danger'} text={icon} style={{padding: '0px 5px'}} />
            }
        }
        else if (this.props.data.type == 'back'){
            type = 'outline-warning';
            var text = [
                <i class="fas fa-1x fa-long-arrow-alt-left" style={{marginRight: '15px'}}></i>,
                this.props.data['name']
            ];
        }

        var content = <div>
            <Button onClick={click_handler} className={className} type={type} text={text} style={{fontSize: '20px', margin: '5px', marginRight:'25px'}} />
            {delete_file}
        </div>;

        return (
            <div>
                {content}
            </div>
        );
    }
}

class DropboxExplorer extends React.Component {
    static component_name = 'DropboxExplorer';

    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'folder'} name={'folder'} default={''} />,
                <TextInput label={'override_name'} name={'override_name'} default={''} />,
                <Select label={'can_delete'} name={'can_delete'} boolean={true} defaultoption={false} />,
            ],
            can_have_children: true,
        };

        this.state = {
            loaded: false,
            has_access: true,
            folder: {name:'', id:''},
            starting_folder: {name:'', id:''},
            parent_folders: [],
            entries: [],
            pending_navigation: false,
            show_extra_folder: false
        };

        this.redirect_to_dropbox = this.redirect_to_dropbox.bind(this);
        this.request_oauth_token = this.request_oauth_token.bind(this);
        this.check_user_access = this.check_user_access.bind(this);
        this.update_dropbox_list = this.update_dropbox_list.bind(this);
        this.get_dropbox_list = this.get_dropbox_list.bind(this);
        this.save_list = this.save_list.bind(this);
        this.refreshData = this.refreshData.bind(this);
        this.delete_file = this.delete_file.bind(this);
    }

    componentDidMount(){
        //var user = global_state.user;
        //ajaxWrapper("GET", '/api/dropbox/DropboxAccess/?user=' + user.id, {}, this.check_user_access);

        //ajaxWrapper("GET", '/dropbox/check_access/', {}, this.check_user_access);

        this.update_folder_from_props();

        window.cmState.subscribe(this, "{new_files}");
    }

    componentDidUpdate (prevProps, prevState, snapshot){
        if (this.props.folder != prevProps.folder){
            this.update_folder_from_props();
        }
    }

    update_folder_from_props() {
        var folder = {id: this.props.folder, name: this.props.folder, path: this.props.folder};
        this.update_dropbox_list(folder);
        this.setState({
            starting_folder: folder,
            folder: folder,
            parent_folders: []
        });
    }

    refreshData(){
        this.update_dropbox_list(this.state.folder);
    }

    check_user_access(value){
        if (value.access){
            this.update_dropbox_list(this.state.folder);
        }
    }

    request_oauth_token(){
        ajaxWrapper("GET", '/dropbox/dropbox_oauth/', {}, this.redirect_to_dropbox);
    }

    redirect_to_dropbox(value){
        console.log(value);
        window.location = value['url'];
    }

    update_dropbox_list(folder){
        if (folder.id == null){
            var parent_folders = this.state.parent_folders;
            var folder = parent_folders.pop();
            this.setState({
                loaded: true,
                has_access: true,
                folder: folder,
                parent_folders: parent_folders,
                pending_navigation: true
            }, this.get_dropbox_list);
        }
        else {
            // Going down folder tree
            var parent_folders = this.state.parent_folders;
            if (this.state.folder.id != '' && folder.path.toLocaleLowerCase() != this.state.starting_folder.path.toLocaleLowerCase()){
                parent_folders.push(this.state.folder);
            }

            this.setState({
                loaded: true,
                has_access: true,
                folder: folder,
                parent_folders: parent_folders,
                pending_navigation: true
            }, this.get_dropbox_list);
        }
    }

    get_dropbox_list(){
        ajaxWrapper("GET", '/dropbox/search/?folder=' + this.state.folder['id'], {}, this.save_list);
    }

    save_list(value){
        console.log(value);
        var entries = [];
        for (var i in value['entries']){
            var entry = value['entries'][i];
            entry['path'] = value['folder']['path'] + '/' + entry['name'];
            entries.push(entry);
        }

        this.setState({
            entries: entries,
            folder: value['folder'],
            pending_navigation: false
        });
    }

    delete_file(name){
        var full_path = this.state.folder.path + '/' + name;
        ajaxWrapper("GET", '/dropbox/delete_file/?path=' + full_path, {}, this.refreshData);
    }

    render() {
        var path_style = {
            padding: '3px 10px',
            marginBottom: '10px',
            display: 'inline-block',
            color: 'grey',
            background: '#eee',
            borderRadius: '3px'
        };

        var content = [
            <Paragraph text={'No Dropbox Access Detected!'} />,
            <Button onClick={this.request_oauth_token} type={'primary'} text={"Request Access"} />
        ];
        var header = null;
        var path_display = null;

        var display_name = this.state.folder.name;
        if (this.props.override_name){
            display_name = this.props.override_name;
        }

        if (this.state.has_access && this.state.entries){
            header = <Header size={3} text={display_name} />;
            if (this.state.folder.path && !(this.props.override_name)){
                var path_text = this.state.folder.path.replace('/I. Resources/2020 RESOURCES/','');
                path_display = <span style={path_style} >{path_text}</span>;
            }

            content = [
                path_display,
                header
            ];

            if (this.state.folder.name == 'Home Directory'){
                header = <Alert type='info'>
                    <Header size={3} text={'You have succesfully authenticated with Dropbox.'} />
                    <p>Navigate to a specific folder to view files and folders.</p>
                </Alert>
            }

            var entry_list = [];

            if (this.state.folder.name == "Booklets- Notes on various texts Yr 7-12" && !(this.state.show_extra_folder)){
                var text = [
                    <i class="fas fa-1x fa-folder-open" style={{marginRight: '15px'}}></i>,
                    this.state.folder['name']
                ];
                var entry = <Button onClick={() => this.setState({show_extra_folder: true})} type={'outline-info'} text={text} style={{fontSize: '20px', margin: '5px', marginRight:'25px'}} />;

                content.push(entry);
            }
            else {
                if (this.state.parent_folders.length > 0){
                    var entry = <DropboxEntry delete_file={this.delete_file} redirect={this.update_dropbox_list} data={{name:'Back', id: null, type: 'back'}} text={'Back'} />;
                    entry_list.push(entry);
                }

                for (var i in this.state.entries){
                    var entry = <DropboxEntry can_delete={this.props.can_delete} delete_file={this.delete_file} redirect={this.update_dropbox_list} data={this.state.entries[i]} />;
                    entry_list.push(entry);
                }

                content.push(...entry_list);
            }
        }

        var loaded = this.state.loaded;
        if (this.state.pending_navigation){
            loaded = false;
        }

        return (
            <div className={''} style={{}}><Container>
                <Break/><Break/>
                <Wrapper min_height={true} content={content} loaded={loaded} />
                <Break/><Break/>
            </Container></div>
        );
    }
}


export default DropboxExplorer;
