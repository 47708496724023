import React, { Component } from 'react';
import {Wrapper, Container, Header, Div, Paragraph, FormWithChildren, DateTimePicker, TextInput, Select, TextArea, UserSearchInput, SignatureInput, ImageInput, Accordion} from 'library';

function get_form_elements(){
    var elements = [
		<Accordion index={7717} header={<div className={"question-card"} name={"7717_0"} excel_reference={1} style={{fontSize: '24px'}}  >
				<Paragraph text={"Quality Control Inspection"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"7718_0,7721_0,7722_0"} sub_total={""} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"7718_0"} label={"Select Position Below if QC Form is Completed by a Supervisor or Manager"} options={[{"text": "Supervisor", "value": "Supervisor"}, {"text": "Area Supervisor", "value": "Area Supervisor"}, {"text": "Dir/Mgr of Operations", "value": "Dir/Mgr of Operations"}, {"text": "Vice President", "value": "Vice President"}]} radio={true} excel_reference={2}  />
			<TextInput className={"question-card"} name={"7721_0"} label={"Area"} excel_reference={4} required={true}  />
			<TextInput className={"question-card"} name={"7722_0"} label={"Completed by:"} excel_reference={5} required={true}  />
			<div className={"question-card"} name={"7724_0"} excel_reference={6}  >
				<Paragraph text={"5 \u2013 Perfect (exceeds contract requirements/no issues)   "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"4 \u2013 Good (meets contract requirements)   "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"3 \u2013 Fair (a couple {1 or 2} issues, Work Order required)  "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"2 \u2013 Poor (multiple {3 to 5} issues, Work Order required)   "} style={{margin:"0px", fontSize:"inherit"}} />
				<Paragraph text={"1 \u2013 Bad (numerous {more than 5} issues, Work Order required)   "} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
		</Accordion>,
		<Accordion index={7725} header={<div className={"question-card"} name={"7725_0"} excel_reference={7} anchor={true} anchor_text={"MAIN ENTRANCE / LOBBY / ELEVAT"} style={{fontSize: '24px'}}  >
				<Paragraph text={"MAIN ENTRANCE / LOBBY / ELEVATORS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"7726_0,7727_0,7728_0,7729_0,7730_0,7731_0,7732_0,7733_0,7734_0,7735_0,7736_0,7737_0,7739_0,7740_0"} sub_total={"7740_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"7726_0"} label={"Door Glass / Side Glass - Main entrance"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={8}  />
			<Select className={"question-card"} name={"7727_0"} label={"Walk Off Mats / Corners & Edges"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={9}  />
			<Select className={"question-card"} name={"7728_0"} label={"Floors - Sweep, Mop, Buff & Vacuum"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={10}  />
			<Select className={"question-card"} name={"7729_0"} label={"Carpets Vacuumed  / Spot Clean Stains"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={11}  />
			<Select className={"question-card"} name={"7730_0"} label={"Main Elevator Cabs - Interior &amp; Exterior Directional Plates, Tracks"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={12}  />
			<Select className={"question-card"} name={"7731_0"} label={"Escalators & Landings"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={13}  />
			<Select className={"question-card"} name={"7732_0"} label={"Ice Machine"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={14}  />
			<Select className={"question-card"} name={"7733_0"} label={"High Low Dusting / A/C Vents"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={15}  />
			<Select className={"question-card"} name={"7734_0"} label={"Trash Containers / Ash Urns"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={16}  />
			<Select className={"question-card"} name={"7735_0"} label={"Lobby Furniture & Fixtures"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={17}  />
			<Select className={"question-card"} name={"7736_0"} label={"Building Exterior (police area)"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={18}  />
			<TextInput className={"question-card"} name={"7737_0"} label={"Comments"} excel_reference={19}  />
			<div className={"question-card"} name={"7738_0"} excel_reference={20}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"7756_0"} excel_reference={21}  />
			<Select className={"question-card"} name={"7739_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={22}  />
			<TextInput className={"question-card"} name={"7740_0"} label={"Total Rating - Maximum Score 55"} excel_reference={23} excel={'+Q8+Q9+Q10+Q11+Q12+Q13+Q14+Q15+Q16+Q17+Q18'}  />
		</Accordion>,
		<Accordion index={7741} header={<div className={"question-card"} name={"7741_0"} excel_reference={24} anchor={true} anchor_text={"COMMON AREAS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"COMMON AREAS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"7742_0,7743_0,7744_0,7745_0,7746_0,7747_0,7748_0,7749_0,7750_0"} sub_total={"7755_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"7742_0"} label={"Drinking Fountains         "} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={25}  />
			<Select className={"question-card"} name={"7743_0"} label={"Stairway"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={26}  />
			<Select className={"question-card"} name={"7744_0"} label={"Elevators, Cabs, Tracks, Panels"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={27}  />
			<Select className={"question-card"} name={"7745_0"} label={"Telephones"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={28}  />
			<Select className={"question-card"} name={"7746_0"} label={"Interior Glass"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={29}  />
			<Select className={"question-card"} name={"7747_0"} label={"High Low Dusting / A/C Vents"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={30}  />
			<Select className={"question-card"} name={"7748_0"} label={"Floors - Sweep, Mop, Buff & Vacuum"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={31}  />
			<Select className={"question-card"} name={"7749_0"} label={"Carpets Vacuumed  / Spot Clean Stains"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={32}  />
			<Select className={"question-card"} name={"7750_0"} label={"Wall Spotting / Corners & Edges"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={33}  />
			<TextInput className={"question-card"} name={"7751_0"} label={"COMMENTS"} excel_reference={34} fail_comment={true}  />
			<div className={"question-card"} name={"7752_0"} excel_reference={35}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"7753_0"} excel_reference={36}  />
			<Select className={"question-card"} name={"7754_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={37}  />
			<TextInput className={"question-card"} name={"7755_0"} label={"Total Rating - Maximum Score 45"} excel_reference={38} excel={'+Q25+Q26+Q27+Q28+Q29+Q30+Q31+Q32+Q33'}  />
		</Accordion>,
		<Accordion index={7757} header={<div className={"question-card"} name={"7757_0"} excel_reference={39} anchor={true} anchor_text={"OFFICES / CONFERENCE ROOMS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"OFFICES / CONFERENCE ROOMS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"7758_0,7759_0,7760_0,7761_0,7762_0,7763_0,7764_0,7765_0,7766_0,7767_0,7768_0"} sub_total={"7773_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"7758_0"} label={"Trash Removed & Liner Replaced"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={40}  />
			<Select className={"question-card"} name={"7759_0"} label={"Carpets Vacuumed  / Spot Clean Stains"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={41}  />
			<Select className={"question-card"} name={"7760_0"} label={"Floors - Sweep, Mop, Buff & Vacuum"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={42}  />
			<Select className={"question-card"} name={"7761_0"} label={"Vacuum / Sweep Corners, Edges, & Under Furniture"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={43}  />
			<Select className={"question-card"} name={"7762_0"} label={"Interior Glass"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={44}  />
			<Select className={"question-card"} name={"7763_0"} label={"Telephones"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={45}  />
			<Select className={"question-card"} name={"7764_0"} label={"High Low Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={46}  />
			<Select className={"question-card"} name={"7765_0"} label={"Blinds / Window Sills Dusted"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={47}  />
			<Select className={"question-card"} name={"7766_0"} label={"Furniture, File Cabinets, Counter Tops, Etc."} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={48}  />
			<Select className={"question-card"} name={"7767_0"} label={"Wall Spotting (Finger Prints, Smudges, Etc.)"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={49}  />
			<Select className={"question-card"} name={"7768_0"} label={"A/C Vents & Return Air Vents"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={50}  />
			<TextInput className={"question-card"} name={"7769_0"} label={"COMMENTS"} excel_reference={51} fail_comment={true}  />
			<div className={"question-card"} name={"7770_0"} excel_reference={52}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"7771_0"} excel_reference={53}  />
			<Select className={"question-card"} name={"7772_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={54}  />
			<TextInput className={"question-card"} name={"7773_0"} label={"Total Rating - Maximum Score 55"} excel_reference={55} excel={'+Q40+Q41+Q42+Q43+Q44+Q45+Q46+Q47+Q48+Q49+Q50'}  />
		</Accordion>,
		<Accordion index={7774} header={<div className={"question-card"} name={"7774_0"} excel_reference={56} anchor={true} anchor_text={"REST ROOMS / LOCKER ROOMS"} style={{fontSize: '24px'}}  >
				<Paragraph text={"REST ROOMS / LOCKER ROOMS"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"7775_0,7776_0,7777_0,7778_0,7779_0,7780_0,7782_0,7783_0,7784_0,7785_0"} sub_total={"7790_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"7775_0"} label={"Trash Removed & Liner Replaced"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={57}  />
			<Select className={"question-card"} name={"7776_0"} label={"Clean & Polish Stainless Steel Fixtures"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={58}  />
			<Select className={"question-card"} name={"7777_0"} label={"Replenish Tissue, Towels, & Soap Dispensers"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={59}  />
			<Select className={"question-card"} name={"7778_0"} label={"Mirrors & Frames"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={60}  />
			<Select className={"question-card"} name={"7779_0"} label={"High & Low Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={61}  />
			<Select className={"question-card"} name={"7780_0"} label={"Sinks, Faucets, & Counter Tops"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={62}  />
			<Select className={"question-card"} name={"7782_0"} label={"Floors Swept, Mopped"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={63}  />
			<Select className={"question-card"} name={"7783_0"} label={"Toilets / Urinals - Bowls, Seats, Rim, Plumbing Fixtures"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={64}  />
			<Select className={"question-card"} name={"7784_0"} label={"Partition Walls, Doors, Etc."} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={65}  />
			<Select className={"question-card"} name={"7785_0"} label={"A/C Vents & Return Air Vents"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={66}  />
			<TextInput className={"question-card"} name={"7786_0"} label={"COMMENTS"} excel_reference={67} fail_comment={true}  />
			<div className={"question-card"} name={"7787_0"} excel_reference={68}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"7788_0"} excel_reference={69}  />
			<Select className={"question-card"} name={"7789_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={70}  />
			<TextInput className={"question-card"} name={"7790_0"} label={"Total Rating - Maximum Score 50"} excel_reference={71} excel={'+Q57+Q58+Q59+Q60+Q61+Q62+Q63+Q64+Q65+Q66'}  />
		</Accordion>,
		<Accordion index={7791} header={<div className={"question-card"} name={"7791_0"} excel_reference={72} anchor={true} anchor_text={" BREAK ROOMS / CAFETERIA"} style={{fontSize: '24px'}}  >
				<Paragraph text={" BREAK ROOMS / CAFETERIA"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"7792_0,7793_0,7794_0,7795_0,7796_0,7797_0,7798_0"} sub_total={"7803_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"7792_0"} label={"Floors Swept, Mopped, Vacuum & Buff"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={73}  />
			<Select className={"question-card"} name={"7793_0"} label={"High & Low Dusting"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={74}  />
			<Select className={"question-card"} name={"7794_0"} label={"Tables / Chairs"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={75}  />
			<Select className={"question-card"} name={"7795_0"} label={"A/C Vents & Return Air Vents"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={76}  />
			<Select className={"question-card"} name={"7796_0"} label={"Trash  removed & liners replaced"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={77}  />
			<Select className={"question-card"} name={"7797_0"} label={"Counter Tops, Sinks, & Back Splash Areas"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={78}  />
			<Select className={"question-card"} name={"7798_0"} label={"Wall Spotting (Finger Prints, Smudges)"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={79}  />
			<TextInput className={"question-card"} name={"7799_0"} label={"COMMENTS"} excel_reference={80} fail_comment={true}  />
			<div className={"question-card"} name={"7800_0"} excel_reference={81}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"7801_0"} excel_reference={82}  />
			<Select className={"question-card"} name={"7802_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={83}  />
			<TextInput className={"question-card"} name={"7803_0"} label={"Total Rating - Maximum Score 35"} excel_reference={84} excel={'+Q73+Q74+Q75+Q76+Q77+Q78+Q79'}  />
		</Accordion>,
		<Accordion index={7804} header={<div className={"question-card"} name={"7804_0"} excel_reference={85} anchor={true} anchor_text={"JANITOR CLOSET / STORAGE AREA"} style={{fontSize: '24px'}}  >
				<Paragraph text={"JANITOR CLOSET / STORAGE AREA"} style={{margin:"0px", fontSize:"inherit", marginRight: "200px"}} />
			</div>} names={"7805_0,7806_0,7807_0,7810_0,7811_0,8931_0"} sub_total={"7811_0"} className={"col-12"} style={{padding:"0px"}} >
			<Select className={"question-card"} name={"7805_0"} label={"Mop Sink & Floors"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={86}  />
			<Select className={"question-card"} name={"7806_0"} label={"Supplies/Equipment (Stored neat & orderly)"} options={[{"text": "5", "value": "5"}, {"text": "4", "value": "4"}, {"text": "3", "value": "3"}, {"text": "2", "value": "2"}, {"text": "1", "value": "1"}]} radio={true} excel_reference={87}  />
			<TextInput className={"question-card"} name={"7807_0"} label={"Comments"} excel_reference={88}  />
			<div className={"question-card"} name={"7808_0"} excel_reference={89}  >
				<Paragraph text={"Take a photo"} style={{margin:"0px", fontSize:"inherit"}} />
			</div>
			<ImageInput className={"question-card"} name={"7809_0"} excel_reference={90}  />
			<Select className={"question-card"} name={"7810_0"} label={"Create Work Order"} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={91}  />
			<TextInput className={"question-card"} name={"7811_0"} label={"Total Rating - Maximum Score 10"} excel_reference={92} excel={'+Q86+Q87'}  />
			<Select className={"question-card"} name={"8931_0"} label={"DID YOU REQUEST ANY WORK ORDERS? "} options={[{"text": "Yes", "value": "Yes"}, {"text": "No", "value": "No"}]} radio={true} excel_reference={94} required={true}  />
		</Accordion>,
		<TextInput className={"question-card"} name={"7812_0"} label={"Grand Total"} excel_reference={93} anchor={true} anchor_text={"Grand Total"} excel={'+Q23+Q38+Q55+Q71+Q84+Q92'}  />,
		<TextInput className={"question-card"} name={"7813_0"} label={"Percentage"} excel_reference={95} excel={'(+Q93/250)*100'}  />,
    ];

    return elements;
}

export default get_form_elements;
